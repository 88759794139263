export enum OrgSectionIndex {
  GeneralInfo = 0,
  Description = 1,
  Expertise = 2,
  Stakeholders = 3,
  Privacy = 4,
  Partner = 5,
  Testify = 6,
  ChallengeTimeline = 7,
  Ventures = 8,
  Location = 9,
  OrganizationSpecificInformation = 10,
  ChallengeSettings = 11
}

export enum OrganizationType {
  All,
  Organization,
  Challenge
}
