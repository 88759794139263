import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import {
  Component,
  HostListener,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms';
// app
import { OrganizationEditService } from '@components/organizations/services/organization-edit.service';
import { ChallengeHttpService } from '@core/http/challenge-http.service';
import {
  ORGANIZATION_CUSTOM_ATTRIBUTE_ORDER_OF_PROPERTY_NAMES,
  OrganizationHttpService,
} from '@core/http/organization-http.service';
import { ReportingPopupService } from '@core/services/reporting-popup.service';
import { maxPageSize } from '@shared/constants/common';
import { MULTISTRING_DEFAULT_PLACEHOLDER } from '@shared/constants/multi-string.const';
import {
  OrgSectionIndex,
  OrganizationType,
} from '@shared/enums/organization.enum';
import { SectionId } from '@shared/enums/section-id.enum';
import { TokenTypes } from '@shared/enums/token-type';
import { UrlParam } from '@shared/enums/url-param.enum';
import { WINDOW } from '@shared/helpers/window.token';
import {
  AttributeDescription,
  MetaInformation,
} from '@shared/interfaces/attribute-description.interface';
import { LeadCompany } from '@shared/interfaces/lead-company.interface';
import {
  FieldOptionPipe,
  getMetaAttribute,
} from '@shared/pipes/field-option.pipe';
import { ScrollingHandlerService } from '@shared/services/scrolling-handler.service';
import { ArrayUtils } from '@shared/utils/array-utils';
import { isBackForwardNavigation } from '@shared/utils/common.utils';
import { BaseEntityEditComponent } from '@src/app/components/base/base-detail/base-entity-edit/base-entity-edit.component';
import { DeactivateProfileDialogComponent as DeactivateDialogComponent } from '@src/app/components/dialogs/deactivate-profile-dialog/deactivate-profile-dialog.component';
import { InitatorCardsComponent } from '@src/app/components/peoples/components/people-cards/initator-cards/initator-cards.component';
import { StakeholderCardsComponent } from '@src/app/components/peoples/components/people-cards/stakeholder-cards/stakeholder-cards.component';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { NotificationService } from '@src/app/core/notification.service';
import { CentralConfigService } from '@src/app/core/services/central-config.service';
import { SessionService } from '@src/app/core/session.service';
import { FormFieldCustomComponent } from '@src/app/shared/components/form-custom-attributes/form-field-custom/form-field-custom.component';
import { FormSectionComponent } from '@src/app/shared/components/form-section/form-section.component';
import { PartnerOrgSetting } from '@src/app/shared/components/molecules/organization-setting-dialog/organization-setting-dialog.component';
import { StreamSettingsComponent } from '@src/app/shared/components/molecules/stream-settings/stream-settings.component';
import { ReportingChartComponent } from '@src/app/shared/components/reporting-chart/reporting-chart.component';
import { SharedVenturesComponent } from '@src/app/shared/components/shared-ventures/shared-ventures.component';
import { transformToTagModaByKey } from '@src/app/shared/components/tag-field/tag-field.component';
import { TemplateName } from '@src/app/shared/constants/visibility-config.const';
import { DeactiveShowType } from '@src/app/shared/enums/deactive-show-type.enum';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { GlobalEventBus } from '@src/app/shared/enums/event-bus.enum';
import { SessionKeyEnum } from '@src/app/shared/enums/session.key.enum';
import { StorageEnum } from '@src/app/shared/enums/storage.enum';
import { VentureSubmissionStatus } from '@src/app/shared/enums/venture.enum';
import { copyToClipboard } from '@src/app/shared/functions/text-copy';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { ChallengeInterface } from '@src/app/shared/interfaces/challenge.interface';
import { ImageInterface } from '@src/app/shared/interfaces/file.interface';
import { MetadataRecord } from '@src/app/shared/interfaces/metadata-table.interface';
import {
  OrgDropdownOption,
  OrganizationFilter,
  OrganizationInterface,
} from '@src/app/shared/interfaces/organization.interface';
import { ApiGetResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { TenantInterface } from '@src/app/shared/interfaces/tenant.interface';
import { TimelineItem } from '@src/app/shared/interfaces/timeline-item.interface';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { VentureSubmissionPayloadInterface } from '@src/app/shared/interfaces/venture.interface';
import { MediaBreakpoint } from '@src/app/shared/models/ui.model';
import { PartnerOrganizationSettingService } from '@src/app/shared/services/partner-organization-setting.service';
import { UserConfigManagementService } from '@src/app/shared/services/user-config-management.service';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { environment } from '@src/environments/environment';
import { isMobile } from 'detect-touch-device';
import { CookieService } from 'ngx-cookie-service';
import { EventBusService } from 'ngx-eventbus';
import { Observable, Subject, of, zip } from 'rxjs';
import { concatMap, filter, finalize, skip } from 'rxjs/operators';

/* const */
export const ORG_REQUIRED_KEYS = [
  'Logo',
  'OrgName',
  'Image',
  'IsTestifiedAuthorization',
];

@Component({
  selector: 'app-organization-edit',
  templateUrl: './organization-edit.component.html',
  providers: [
    OrganizationEditService,
    ScrollingHandlerService,
    FieldOptionPipe,
  ],
})
export class OrganizationEditComponent
  extends BaseEntityEditComponent<OrganizationInterface>
  implements OnChanges, OnInit
{
  @Input() uiTemplateConfig: TemplateName = TemplateName.Default;

  @ViewChild(SharedVenturesComponent)
  sharedVenturesComp: SharedVenturesComponent;

  @ViewChild('orgVentures') orgVentures: FormSectionComponent;

  @ViewChild('initiatorsCardsComponent')
  set initiatorsCardsComponent(value: InitatorCardsComponent) {
    if (value) {
      this.initiatorsCards = value;
      this.restorePosition();
    }
  }
  initiatorsCards: InitatorCardsComponent;

  @ViewChild('reportingChart') reportingChart!: ReportingChartComponent;
  @ViewChild('stakeholderCardsComponent')
  stakeholderCardsComponent: StakeholderCardsComponent;

  @ViewChild('imageControlInCreateMode')
  imageControlInCreateMode: FormFieldCustomComponent;

  @ViewChild('streamSettings') streamSettings: StreamSettingsComponent;

  ventureBaseUrl = environment.jipUrl.venture;
  offerringPlaceholder = MULTISTRING_DEFAULT_PLACEHOLDER;
  EntityName = EntityName;

  stakeholders: UserInterface[] = [];
  orgUrlDisplayText: string;
  showPendingPeopleModal = false;
  showPeopleModal = false;
  showLikesModal = false;

  isShownAsChallenge = false;
  submitVentureToOrganizationUrl = '';
  isSubmitVentureToOrganizationUrlCopied = false;

  orgAsPartners: LeadCompany[] = [];
  orgAsChallenges: LeadCompany[] = [];

  challengEntityDescription: MetaInformation;
  challengeAttributes: AttributeDescription[];
  challengeTimelineCurrentValues: TimelineItem[] = [];
  challengeTimelineValidValues: TimelineItem[] = [];

  orgEntityDescription: MetaInformation;
  orgAttributes: AttributeDescription[];
  entityMetaInfo: AttributeDescription[][];

  orgSectionIndex = OrgSectionIndex;
  organizationType = OrganizationType;
  isVentureLoading = false;
  deactiveShowTypeEnum = DeactiveShowType;
  ventureLength = 0;

  challengeCreateFromOrgId: number = null;

  filteringKeyword: string;

  isTouchDevice: boolean = isMobile;
  orgOptions: OrgDropdownOption[] = [];
  totalPartnerOrgs: number = null;
  defaultPageSize = 9;
  selectedOrgId = this.entity?.id;
  isViewMoreOrg = false;
  isPartnerOrgLoading = false;
  showMoreOrgButton = false;

  totalChallenges: number = null;
  isViewMoreChallenge = false;
  isChallengeLoading = false;
  showMoreChallengeButton = false;

  cancelSection$ = new Subject();
  isExportLoading: boolean;
  isUpdateStakholders = false;
  reportName = 'number-of-project-ideas';
  innerWidth: number;
  MediaBreakpoint = MediaBreakpoint;
  defaultBanner: ImageInterface;
  defaultLogo: ImageInterface;

  orgCustomAttributes: AttributeDescription[];
  ORGANIZATION_CUSTOM_ATTRIBUTE_ORDER_OF_PROPERTY_NAMES =
    ORGANIZATION_CUSTOM_ATTRIBUTE_ORDER_OF_PROPERTY_NAMES;
  customOrganizationMetaData: MetadataRecord = {
    attributeDescriptions: [],
    attributeValues: [],
  };

  isPartnerOrgGridView = true;
  isPartnerOrgTableExpanded = false;
  challengeMessageRightSelectedIndex = 0;
  isUrlCopied: boolean = false;

  readonly TokenTypes = TokenTypes;

  readonly SectionId = SectionId;

  //#region SERVICES
  readonly scrollingService: ScrollingHandlerService;
  private notificationService: NotificationService;
  private reportingPopupService: ReportingPopupService;
  private userConfig: UserConfigManagementService;
  //#endregion End Services

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    const { innerWidth } = event?.target;
    this.innerWidth = innerWidth;
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    public orgHttpService: OrganizationHttpService,
    public challengeHttpService: ChallengeHttpService,
    protected authenService: AuthenService,
    protected sessionService: SessionService,
    protected injector: Injector,
    private partnerOrgSettingService: PartnerOrganizationSettingService,
    private cookieService: CookieService,
    public centralConfig: CentralConfigService,
    private fieldOptionPipe: FieldOptionPipe,
    private eventBus: EventBusService
  ) {
    super(
      window.location.pathname.includes(environment.jipUrl.organizations)
        ? orgHttpService
        : challengeHttpService,
      authenService,
      sessionService,
      injector
    );

    this.notificationService =
      this.injector.get<NotificationService>(NotificationService);
    this.reportingPopupService = this.injector.get<ReportingPopupService>(
      ReportingPopupService
    );
    this.scrollingService = this.injector.get(ScrollingHandlerService);
    this.userConfig = this.injector.get(UserConfigManagementService);

    this.entityName = EntityName.Organization;
    this.portalName = this.cookieService.get(SessionKeyEnum.TENANT_NAME);
    this.portalUrl = this.cookieService.get(SessionKeyEnum.TENANT_DOMAIN);

    this.innerWidth = window.innerWidth;

    this.eventBus.addEventListener({
      name: GlobalEventBus.GlobalFilterEvent,
      callback: () => {
        this.orgHttpService.attributesObsever$ = null;
        this.generateForm();
      },
    });
  }

  get allowToJoin(): boolean {
    return this.form?.get('IsAllowToJoin')
      ? this.form?.get('IsAllowToJoin').value
      : getMetaAttribute(this.getEntityMetaInfo(), 'IsAllowToJoin')
          ?.defaultValue;
  }

  get logoValue() {
    return this.form?.get('Logo')?.value;
  }

  get imageValue() {
    return this.form?.get('Image')?.value;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.partnerOrgSettingService.orgSettingData$
      .pipe(untilDestroyed(this))
      .subscribe((setting: string) => {
        this.toastService.showSuccess('UI.Toast.SavedSuccessfully');
        this.afterSettingSuccess(setting);
      });

    this.sessionService.tenant$
      .pipe(filter(Boolean))
      .subscribe((tenant: TenantInterface) => {
        this.updateTenantDefaultImage(tenant);
      });
  }
  afterSettingSuccess(setting: string): void {
    switch (setting) {
      case PartnerOrgSetting.ProjectIdeas:
        this.sharedVenturesComp?.refreshVentures();
        break;
      case PartnerOrgSetting.People:
        this.loadOrgOptions();
        break;
      default:
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.isCreating && this.isCreating) {
      this.sessionService.apiReady$
        .pipe(untilDestroyed(this))
        .subscribe((ready) => {
          if (ready) {
            const isChallenge = window.location.pathname.includes(
              environment.jipUrl.challenges
            );
            if (isChallenge) {
              this.handleOrgParam();
            }
          }
        });
    }
  }

  listenFilterChanged(): void {
    if (this.uiTemplateConfig === TemplateName.Default) return;

    this.filterStoredService
      .getCurrentFilterToken()
      .pipe(
        skip(1), // Skip the first request since it is already handled by the Baseclass
        filter(() => !!this.entity?.id),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.handleLoading(true);
        this.getLatestEntity(this.entity.id);
      });
  }

  checkSubmitionPhase(): void {
    if (
      StringUtils.getParamFromUrl(UrlParam.PreviousAction) === 'create-venture'
    ) {
      StringUtils.removeParamFromUrl(UrlParam.PreviousAction);

      const service = this.injector.get(OrganizationEditService);

      service
        .showReasonWhyCannotSubmitVentureToCall(
          this.entity as unknown as ChallengeInterface,
          'UI.VenturePhase.SubmissionAlreadyEnded'
        )
        .pipe(filter(Boolean), untilDestroyed(this))
        .subscribe(() =>
          service.openSendMessageToAdmins(
            this.entity as unknown as ChallengeInterface
          )
        );
    }
  }

  handleOrgParam(): void {
    this.challengeCreateFromOrgId = null;
    const id = Number(StringUtils.getParamFromUrl(UrlParam.OrganizationId));

    if (id && !isNaN(id)) {
      this.orgHttpService
        .read(id)
        .pipe(untilDestroyed(this))
        .subscribe((res: OrganizationInterface) => {
          if (res && res.id && !res.showAsChallenge) {
            this.orgAsPartners = [
              {
                ...res,
                name: res.orgName,
                url: res.orgUrl,
              },
            ];
            this.challengeCreateFromOrgId = id;
          }
        });
    }
  }

  onSubmit(): void {
    if (this.challengeTimelineCtrl) {
      this.challengeTimelineCtrl.setValue(this.challengeTimelineCurrentValues);
    }

    this.switchHttpService();
    super.onSubmit();
  }

  customInit(): void {
    this.ventureDemands = this.baseHttpService.getDemands();

    if (window.location?.pathname?.includes(environment.jipUrl.challenges)) {
      this.entityRoot = environment.jipUrl.challenges;
    } else {
      this.entityRoot = environment.jipUrl.organizations;
    }

    this.formSections = [
      {
        fieldsName: ['Logo', 'OrgName', 'OrgUrl', 'EnclosingOrgName'],
      },
      {
        fieldsName: [
          'Headline',
          'SubHeadline',
          'OrganizationSize',
          'Description',
          'Attachments',
        ],
      },
      {
        fieldsName: ['Expertises', 'KeyOfferings'],
      },
      {
        fieldsName: ['Stakeholders'],
      },
      {
        fieldsName: ['SendMessageRights', 'PostStream'],
      },
      {
        fieldsName: ['PartnerOrganizations'],
      },
      {
        fieldsName: ['IsTestifiedAuthorization'],
      },
      {
        fieldsName: ['ChallengeTimeline'],
      },
      {
        fieldsName: ['Ventures'],
      },
      {
        fieldsName: ['Location'],
      },
      {
        fieldsName: [
          'ResidentOf',
          'InnovationFocus',
          'Competency',
          'CompanyType',
          'CountryOfOrigin',
        ],
      },
      {
        fieldsName: ['IsAllowToJoin', 'SendMessageRights', 'PostStream'],
      },
    ];

    this.listenFilterChanged();
  }

  generateForm(): void {
    let params = new HttpParams();
    const activeInnovationSpace = this.centralConfig.innovationSpaceId;
    this.getOrgId().then((globalOrgId) => {
      const orgId = activeInnovationSpace || globalOrgId;
      if (orgId) {
        params = params.set(UrlParam.FilterTokenId, orgId.toString());
      }
      if (this.id) {
        params = params.set('Id', this.id);
      }

      zip(
        this.orgHttpService.getAttributeDescription(params),
        this.challengeHttpService.getAttributeDescription()
      )
        .pipe(untilDestroyed(this))
        .subscribe(
          ([orgMeta, challengeMeta]: [MetaInformation, MetaInformation]) => {
            if (!orgMeta || !challengeMeta) {
              return;
            }
            this.orgEntityDescription = orgMeta;
            this.orgAttributes =
              orgMeta.entityDescription?.attributeDescriptions;

            this.challengEntityDescription = challengeMeta;
            this.challengeAttributes =
              challengeMeta.entityDescription?.attributeDescriptions;

            const metaInfo = { ...orgMeta };
            Object.assign(metaInfo, challengeMeta);

            if (this.isCreating) {
              this.turnOnChallengeMode();
              this.switchHttpService();
            }

            this.orgCustomAttributes = this.getOrgCustomMetaData(
              this.orgAttributes
            );
            this.customOrganizationMetaData = {
              attributeDescriptions: this.orgCustomAttributes,
              attributeValues: [],
            };

            const isChallenge = window.location.pathname.includes(
              environment.jipUrl.challenges
            );

            if (isChallenge) {
              const filteredAttributeDescription =
                metaInfo.entityDescription.attributeDescriptions.filter(
                  (attr) =>
                    !this.ORGANIZATION_CUSTOM_ATTRIBUTE_ORDER_OF_PROPERTY_NAMES.includes(
                      attr.propertyName
                    )
                );

              metaInfo.entityDescription.attributeDescriptions =
                filteredAttributeDescription;
              this.createFormUsingMetaInfo(metaInfo);
            } else {
              this.createFormUsingMetaInfo({ ...orgMeta });
            }
          }
        );
    });
  }

  setInitators(items = []): void {
    this.initiators = [...items];
  }

  setStakeholders(items = []): void {
    this.stakeholders = [...items];
  }

  saveSection(index: number): Observable<boolean> {
    if (this.challengeTimelineCtrl) {
      this.challengeTimelineCtrl.setValue(this.challengeTimelineCurrentValues);
      this.setChallengeTimelineValidValues(this.challengeTimelineCurrentValues);
    }
    if (index === OrgSectionIndex.Partner) {
      this.removeEditingSectionIndex(OrgSectionIndex.Ventures);

      this.isVentureLoading = true;
    }

    if (index === OrgSectionIndex.Stakeholders) {
      this.isUpdateStakholders = true;
    }

    return super.saveSection(index).pipe(
      finalize(async () => {
        if (index === OrgSectionIndex.ChallengeTimeline) {
          // save challenge timeline
          this.updateFieldValue('ChallengeTimeline');
        }
        if (index === OrgSectionIndex.Partner) {
          this.isVentureLoading = false;
          this.isViewMoreOrg = false;
          this.isViewMoreChallenge = false;
        }

        if (index === OrgSectionIndex.GeneralInfo) {
          await this.updateInnovationSpaceList();
        }
      })
    );
  }

  saveSubmissionStatuses(index: number): Observable<boolean> {
    this.cancelSection$.next(true);

    if (index !== OrgSectionIndex.Ventures || !this.sharedVenturesComp) {
      return of(false);
    }

    const ventureSubmissionPayload: VentureSubmissionPayloadInterface =
      this.getVentureSubmissionPayload();

    const apiPath = '/change-venture-submisions';

    return new Observable((observer) => {
      this.baseHttpService
        .postByEntity(this.entity?.id, ventureSubmissionPayload, apiPath)
        .pipe(
          untilDestroyed(this),
          finalize(() => observer.complete())
        )
        .subscribe({
          next: () => {
            this.toastService.showSuccess('UI.Toast.UpdatedSuccessfully');
            this.removeEditingSectionIndex(index);

            observer.next(true);
          },
          error: () => {
            observer.next(false);
          },
        });
    });
  }

  updateStakeholder(items = []): void {
    const updatedstakeHolders = items ? items : [];
    this.stakeholders = [...updatedstakeHolders];

    if (!this.isCreating) {
      this.saveSection(OrgSectionIndex.Stakeholders)
        .pipe(untilDestroyed(this))
        .subscribe();
    } else {
      FormUtils.maskFormAsTouched(this.form);
    }
  }

  get logo(): UntypedFormControl {
    return this.form.get('Logo') as UntypedFormControl;
  }

  preview(file: ImageInterface): void {
    this.logo.setValue(file);
  }

  changeOrgPrivacy(index: number): Observable<boolean> {
    let apiPath = '';

    if (this.isPrivateOrg()) {
      apiPath = `/unpublish?showAsChallenge=${this.isShownAsChallenge}`;
    } else {
      apiPath = `/publish?showAsChallenge=${this.isShownAsChallenge}`;
    }

    return new Observable((observer) => {
      this.baseHttpService
        .postByEntity(this.entity?.id, null, apiPath)
        .pipe(
          untilDestroyed(this),
          concatMap(() => this.saveSection(index))
        )
        .subscribe({
          next: () => {
            this.removeEditingSectionIndex(index);
            this.handleApiCommonProcess(observer, true);
            this.switchHttpService();
          },
          error: () => {
            this.handleApiCommonProcess(observer, false);
          },
        });
    });
  }

  isPrivateOrg(): boolean {
    return this.form?.value?.IsPrivate;
  }

  cancelSection(index: number): void {
    this.cancelSection$.next(true);

    super.cancelSection(index);

    // temp fix
    this.updateFormValue();

    if (this.challengeTimelineCtrl) {
      const values = this.challengeTimelineValidValues.map((value) => ({
        ...value,
      }));

      this.challengeTimelineCtrl.setValue(values);
    }
    if (index === OrgSectionIndex.Partner) {
      this.isViewMoreOrg = false;
    }
  }

  getFieldOptions(key, entity?): AttributeDescription {
    const options = super.getFieldOptions(key, entity);
    return (
      options ||
      FormUtils.getFieldOptions(
        this.challengEntityDescription?.entityDescription.attributeDescriptions,
        key
      )
    );
  }

  updateFormValue(): void {
    super.updateFormValue();

    this.mapCustomTags('Expertises');
    this.mapCustomTags('KeyOfferings');

    const entityId = this.entity?.id;

    if (this.entity) {
      this.isShownAsChallenge = this.entity.showAsChallenge;
      this.submitVentureToOrganizationUrl =
        location.origin +
        this.generateCreateEntityUrl(EntityName.Venture, entityId);
      this.updateChallengesAndPartnersOrg(this.entity);

      this.switchHttpService();
    }

    if (entityId) {
      this.loadStakeholders();
      if (!this.isShownAsChallenge) {
        this.loadOrgOptions();
        this.loadInitiators(entityId);
      } else if (Array.isArray(this.entity?.initiators)) {
        this.initiators = [...this.entity?.initiators];
      }
    }

    this.orgUrlDisplayText = this.entity?.displayText;
  }

  switchHttpService(): void {
    const entityAttributes = this.entity?.attributeDescriptions;

    if (this.isShownAsChallenge) {
      this.baseHttpService = this.challengeHttpService;
      this.entityMetaInfo = [entityAttributes, this.challengeAttributes];
      this.entityRoot = environment.jipUrl.challenges;
    } else {
      this.baseHttpService = this.orgHttpService;
      this.entityMetaInfo = [entityAttributes, this.orgAttributes];
      this.entityRoot = environment.jipUrl.organizations;
    }
  }

  updateChallengesAndPartnersOrg(entity: OrganizationInterface): void {
    if (Array.isArray(entity?.partnerOrganizations)) {
      this.orgAsChallenges = entity.partnerOrganizations.filter((item) => {
        return item.showAsChallenge;
      });
      this.orgAsPartners = entity.partnerOrganizations.filter((item) => {
        return !item.showAsChallenge;
      });
    }
  }

  updateOnlyChallenges(values: LeadCompany[]): void {
    this.orgAsChallenges = values;
    this.updateOrganisationPayload(
      ArrayUtils.concact(this.orgAsChallenges, this.orgAsPartners)
    );
  }

  updateOnlyPartners(values: LeadCompany[]): void {
    this.orgAsPartners = values;
    this.updateOrganisationPayload(
      ArrayUtils.concact(this.orgAsChallenges, this.orgAsPartners)
    );
  }

  updateOrganisationPayload(values: LeadCompany[]): void {
    this.form.patchValue({
      PartnerOrganizations: values,
    });
  }

  getPartnerOrganizations(controlName): object {
    const items = this.form.getRawValue()[controlName] as any[];
    return {
      PartnerOrganizations: items?.map((item) => {
        return {
          id: item.id,
          showAsChallenge: item.showAsChallenge,
          showVentures: item.showVentures,
        };
      }),
    };
  }

  mapCustomTags(controlName): void {
    const items = this.getFormFieldValueFromEntity(controlName);
    let controlValue = [];
    if (items) {
      controlValue = transformToTagModaByKey(
        items,
        !this.editable,
        'description',
        'description'
      );
      this.form.patchValue({ [controlName]: controlValue });
    }
  }

  getDto(): OrganizationInterface {
    const result: OrganizationInterface = super.getDto();
    if (this.form && this.form.getRawValue()) {
      Object.assign(result, this.getLogoAndImage());
      Object.assign(result, this.getShowAsChallenge());
      Object.assign(result, this.getDtoField('Stakeholders'));
      Object.assign(result, this.getExpertiseAndKeyOfferings());
      Object.assign(result, this.getIsTestifiedAuthorization());
      Object.assign(result, this.getStreamSetting());
    }

    return result;
  }

  getStreamSetting() {
    const streamValue = this.streamSettings.getStreamFormValue();

    return {
      postStream: streamValue,
    };
  }

  getShowAsChallenge(): any {
    return {
      showAsChallenge: this.isShownAsChallenge,
    };
  }

  getDtoField(name, force = false): object {
    switch (name) {
      case 'Stakeholders':
        return { stakeholders: this.getStakeholdersDto() };
      case 'PartnerOrganizations':
        return this.getPartnerOrganizations(name);
      case 'PostStream':
        return this.getStreamSetting();
      default:
        return super.getDtoField(name, force);
    }
  }

  addCustomVariableDto(dto: any): any {
    if (
      this.isShownAsChallenge &&
      this.challengeCreateFromOrgId !== null &&
      dto
    ) {
      dto.challengeCreateFromOrgId = this.challengeCreateFromOrgId;
    }
    return dto;
  }

  formatField(dto, key): void {
    if (key === 'attachments') {
      const items = dto[key] || [];
      dto[key] = items.map((item) => item?.id);
    } else {
      super.formatField(dto, key);
    }
  }

  getStakeholdersDto(): any {
    return this.stakeholders?.map((item) => {
      return item.id;
    });
  }

  getLogo(): any {
    const logo = this.form?.value.Logo;
    return {
      logoId: logo?.id || 0,
      displayText: this.orgUrlDisplayText?.trim(),
    };
  }

  getLogoAndImage(): { imageId; logoId } {
    const logo = this.form?.value.Logo;
    const image = this.form?.value.Image;
    return {
      logoId: logo?.id || 0,
      imageId: image?.id || 0,
    };
  }

  getExpertiseAndKeyOfferings(): any {
    if (!this.form) {
      return {};
    }

    const expertises = this.form.value.Expertises || [];
    const keyOfferings = this.form.value.KeyOfferings || [];
    return {
      expertises: expertises?.map((tag: any) => {
        return { description: tag.value };
      }),
      keyOfferings: keyOfferings?.map((tag: any) => {
        return { description: tag.value };
      }),
    };
  }

  getIsTestifiedAuthorization(): boolean {
    return this.form?.value.IsTestifiedAuthorization;
  }

  getRequiredKeys(): string[] {
    return ORG_REQUIRED_KEYS;
  }

  getSectionInfo(index: number): any {
    const section = this.formSections[index];
    if (section) {
      const result = super.getSectionInfo(index);
      if (section.fieldsName.includes('Logo')) {
        Object.assign(result, this.getLogo());
        // use logoId attribute instead of logo
        delete result.logo;
      }

      if (section.fieldsName.includes('Expertises')) {
        Object.assign(result, this.getExpertiseAndKeyOfferings());
      }

      return result;
    }

    return {};
  }

  loadStakeholders(id = this.entity.id): void {
    const params: { pageSize: number; IsFilterAll?: boolean } = {
      pageSize: maxPageSize,
    };

    //blank option
    if (id === 0) {
      id = this.entity.id;
      params.IsFilterAll = true;
    }
    this.baseHttpService
      .paginateByEntity(environment.jipUrl.stakeholders, id, params)
      .pipe(untilDestroyed(this))
      .subscribe((res: any) => {
        this.stakeholders = res?.items;
        this.isTeam = ArrayUtils.hasItem(this.stakeholders, this.profile, 'id');
        this.entity.canInvite = this.canInvite();
        this.updateExtraActionPermission();
      });
  }

  onPendingRequestChange(): void {
    this.loadEntity();
  }

  openExportPopup(): void {
    this.reportingPopupService.open(this.entity);
  }

  submitVentureToOrg(): void {
    if (
      !this.sessionService.isLogin ||
      !this.isShownAsChallenge ||
      !this.entity?.id
    ) {
      return;
    }
    if (this.entity.isPrivate) {
      this.baseHttpService
        .checkSubmitVenture(this.entity.id)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: () => {
            FormUtils.navigateTo(
              this.generateCreateEntityUrl(EntityName.Venture, this.entity.id)
            );
          },
          error: (errorRes: HttpErrorResponse) => {
            this.handleErrorResponse(errorRes);
          },
        });
    } else {
      FormUtils.navigateTo(
        this.generateCreateEntityUrl(EntityName.Venture, this.entity.id)
      );
    }
  }

  checkCanCopyUrlSubmitVentureToOrg(): void {
    if (
      !this.sessionService.isLogin ||
      !this.isShownAsChallenge ||
      !this.entity?.id
    ) {
      return;
    }
    if (this.entity.isPrivate) {
      this.baseHttpService
        .checkSubmitVenture(this.entity.id)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: () => {
            this.copyUrlSubmitVentureToOrg();
          },
          error: (errorRes: HttpErrorResponse) => {
            this.handleErrorResponse(errorRes);
          },
        });
    } else {
      this.copyUrlSubmitVentureToOrg();
    }
  }

  copyUrlSubmitVentureToOrg(): void {
    copyToClipboard(this.submitVentureToOrganizationUrl, () => {
      this.isSubmitVentureToOrganizationUrlCopied = true;

      setTimeout(() => {
        this.isSubmitVentureToOrganizationUrlCopied = false;
      }, 700);
    });
  }

  createChallengeFromThisOrg(entityId: number): void {
    FormUtils.navigateTo(
      this.generateCreateEntityUrl(EntityName.Challenge, entityId)
    );
  }

  generateCreateEntityUrl(entityName: string, entityId: number): string {
    if (!entityName) {
      return '';
    }
    const queryParams = `${UrlParam.OrganizationId}=${entityId}`;
    const entityUrl = StringUtils.getEntityUrlByEntityName(entityName);
    return entityUrl
      ? this.sessionService.appendLanguagePath(
          `${entityUrl}/create?${queryParams}`
        )
      : '';
  }

  get canShowAttachments(): boolean {
    if (this.isCreating) {
      return true;
    }

    if (this.isOwner) {
      return true;
    }

    if (
      this.entity &&
      this.entity.attachments &&
      this.entity.attachments.length > 0
    ) {
      return true;
    }

    return false;
  }

  protected getCustomvalidation(): ValidatorFn | ValidatorFn[] {
    return (formControls: UntypedFormGroup): { [key: string]: any } | null => {
      return FormUtils.hasTrueValueControl(formControls, 'hasTrueValue', [
        'IsTestifiedAuthorization',
      ]);
    };
  }

  get ignoreSearchPeopleIds(): number[] {
    const initiatorIds = this.entity?.initiators?.map((i) => i.id) || [];
    const stakeholderIds = this.entity?.stakeholders?.map((s) => s.id) || [];

    return [...initiatorIds, ...stakeholderIds];
  }

  get challengeTimelineCtrl(): AbstractControl {
    return this.form?.get('ChallengeTimeline');
  }

  get challengeTimelineValues(): TimelineItem[] {
    if (!Array.isArray(this.challengeTimelineCtrl.value)) {
      return [];
    }

    return [...this.challengeTimelineCtrl.value];
  }

  onTimelineValueChange(values: TimelineItem[]): void {
    this.challengeTimelineCurrentValues = values;

    const isInvalid = values.some((value) => !value.isValid);
    this.setChallengeTimelineErrors(isInvalid);
  }

  protected afterFormChangedStatus(): void {
    this.challengeTimelineCurrentValues = this.entity?.challengeTimeline;
    this.setChallengeTimelineValidValues(this.entity?.challengeTimeline || []);
  }

  getVentureCount(ventureCount: number): void {
    this.ventureLength = ventureCount;
  }

  /* Private */
  private getVentureSubmissionPayload(): VentureSubmissionPayloadInterface {
    const submissionStatuses = this.sharedVenturesComp.items
      .filter((item) => item.currentSubmission)
      .map((item) => ({
        ventureId: item.id,
        currentStatus:
          item.currentSubmission.lastSubmissionStatus?.codeId ||
          VentureSubmissionStatus.Submitted,
        newStatus: item.currentSubmission.submissionStatus?.codeId,
      }));

    return {
      ventureSubmissions: submissionStatuses,
    };
  }

  private setChallengeTimelineErrors(isInvalid: boolean): void {
    if (isInvalid) {
      this.challengeTimelineCtrl.setErrors({ invalid: isInvalid });
    } else {
      this.challengeTimelineCtrl.setErrors(null);
    }
  }

  private setChallengeTimelineValidValues(values: TimelineItem[]): void {
    this.challengeTimelineValidValues = values?.map((item) => ({ ...item }));
  }

  private turnOnChallengeMode(): void {
    this.isShownAsChallenge = window.location.pathname.includes(
      environment.jipUrl.challenges
    );
    this.sessionService.tenant$
      .pipe(filter(Boolean))
      .subscribe((tenant: TenantInterface) => {
        this.updateTenantDefaultImage(tenant);
      });
  }

  private handleErrorResponse(error: HttpErrorResponse): void {
    if (error.error?.title) {
      this.toastService.showError(error.error.title);
    }
  }

  getFilterTokenUrl(): string {
    const entityPath = this.isShownAsChallenge
      ? environment.jipUrl.challenges
      : environment.jipUrl.organizations;
    const appendedPath = this.sessionService.appendLanguagePath(entityPath);

    return `${this.window.origin}${appendedPath}/?${UrlParam.FilterTokenId}=${this.entity.id}`;
  }

  addOrgToFilterToken(entityId: number): void {
    this.filterStoredService.setFilterTokenFromOutSide(entityId);
    this.notificationService.show(
      'UI.FilterToken.Successful',
      'UI.FilterToken.AddSuccessful',
      'OK'
    );
  }

  getEntityMetaInfo(): any {
    return this.isShownAsChallenge
      ? [this.challengeAttributes]
      : [this.orgAttributes];
  }

  toggleVentureFilter(event: Event): void {
    event.stopPropagation();
    this.isVentureFilterActive = !this.isVentureFilterActive;
  }

  canSubmitVentureToCall(
    entity: OrganizationInterface | ChallengeInterface
  ): boolean {
    const call = entity as ChallengeInterface;
    return call && this.isSubmissionPhase(call);
    // 14851 Temporary remove check related to challenge
    // && (!this.sessionService.isLogin || call.canSubmitVentureToChallenge);
  }

  getReasonWhyCannotSubmitVentureToCall(
    entity: OrganizationInterface | ChallengeInterface
  ): string {
    const call = entity as ChallengeInterface;
    if (!this.isSubmissionPhase(call)) {
      return 'UI.Challenge.DuringSubmissionPhase';
    }
    // 14851 Temporary remove check related to challenge
    // if (this.sessionService.isLogin && !call.canSubmitVentureToChallenge) {
    //   return 'UI.Challenge.RelatedToCall';
    // }
  }

  isSubmissionPhase(
    entity: OrganizationInterface | ChallengeInterface
  ): boolean {
    const call = entity as ChallengeInterface;

    return call.challengeTimeline?.[0]?.isCurrent;
  }

  protected handleSyncEntityStateCount(): void {
    super.handleSyncEntityStateCount();
    this.syncEntityJoinStates();
  }

  protected handleAfterGetEntityDetail(entity: OrganizationInterface): void {
    this.isShownAsChallenge = entity.showAsChallenge;
    this.switchHttpService();

    this.entity = entity;
    if (this.entityName) {
      const entityNameCounting = this.entity.showAsChallenge
        ? EntityName.Challenge
        : EntityName.Organization;
      this.runEntityStateCount(
        entityNameCounting,
        [this.entity],
        this.baseHttpService
      );
    }
    this.handleCustomMetadata(
      entity?.attributeDescriptions,
      entity?.attributeValues
    );
    this.changeFormStatusSubject.next();

    this.checkSubmitionPhase();
    if (!this.entity.showAsChallenge && !this.isUpdateStakholders) {
      this.loadOrgOptions();
      this.isUpdateStakholders = false;
    }

    if (this.isShownAsChallenge) {
      this.changeMessageRightSelectedIndex(this.entity.sendMessageRights);
    }
  }

  onSearchPeople(event: string): void {
    this.filteringKeyword = event;
  }

  onPeopleAdded(): void {
    this.filteringKeyword = '';
  }

  onAdvancedSettingChange(result: boolean): void {
    if (!this.form) return;

    const allowToJoinControl = this.form.get('IsAllowToJoin');

    allowToJoinControl?.setValue(result);
  }

  changeOrgPrivacyImmediately(): void {
    this.changeOrgPrivacy(this.orgSectionIndex.Privacy)
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  isMyOrganisation(initiators: UserInterface[]): boolean {
    return initiators?.some(
      (initiator: UserInterface) =>
        initiator.id === this.sessionService.currentUser?.id
    );
  }

  refreshTableView(isVentureGridView: boolean): void {
    if (isVentureGridView) return;

    this.sharedVenturesComp?.refreshVentures();
  }

  saveSectionPosition(item: number): void {
    this.scrollingService.isRestorationComplete = true;
    this.scrollingService.saveItem(
      EntityName.Organization,
      true,
      item.toString()
    );
  }

  restorePosition(): void {
    if (isBackForwardNavigation(this.window)) {
      this.scrollingService.restorePosition(
        EntityName.Organization,
        OrgSectionIndex.Stakeholders,
        120,
        true
      );
    }

    this.scrollingService.removeItem(EntityName.Organization);
  }

  loadOrgOptions(): void {
    const apiPath = '/partner-org-community';

    this.baseHttpService
      .getByEntity(this.entity.id, apiPath)
      .pipe(untilDestroyed(this))
      .subscribe((res: OrgDropdownOption[]) => {
        this.orgOptions = res;
      });
  }

  loadInitiators(id: number = this.entity.id): void {
    const apiPath = '/initiators';

    this.baseHttpService
      .paginateByEntity(apiPath, id, {
        pageSize: maxPageSize,
      })
      .pipe(untilDestroyed(this))
      .subscribe((res: ApiGetResponse<UserInterface>) => {
        this.initiators = res?.items;
        this.isOwner = this.isInitiator();
        this.entity.canInvite = this.canInvite();
        if (
          this.hasEditPermision(this.profile, {
            ...this.entity,
            initiators: this.initiators,
          })
        ) {
          this.editable = true;
          this.form.enable();
        } else {
          this.editable = false;
          this.form.disable();
        }
        this.updateExtraActionPermission();
      });
  }

  onOrgChange(result: number): void {
    this.selectedOrgId = result;
    this.loadStakeholders(result);
  }

  loadPartnersOrg(
    pageSize: number,
    showAsChallenge: boolean
  ): Observable<ApiGetResponse<OrganizationInterface>> {
    const apiUrl = `partner-org`;

    return this.baseHttpService
      .paginateX(
        {
          OrgId: this.entity.id,
          PageSize: pageSize,
          ShowAsChallenge: showAsChallenge,
        },
        apiUrl
      )
      .pipe(untilDestroyed(this));
  }

  private updateExtraActionPermission(): void {
    if (this.isShownAsChallenge) return;
    const enableExtraAction =
      this.isOwner &&
      (this.selectedOrgId === this.entity.id || this.orgOptions.length === 1);

    this.stakeholderCardsComponent.enableExtraAction = enableExtraAction;
    this.stakeholderCardsComponent.editable =
      this.editable && enableExtraAction;

    if (this.initiatorsCards) {
      this.initiatorsCards.enableExtraAction = enableExtraAction;
    }
  }
  onExportLoading(res: boolean): void {
    this.isExportLoading = res;
  }

  isInitiator(): boolean {
    if (this.entity?.showAsChallenge) {
      return super.isInitiator(this.profile, this.entity);
    } else {
      return super.isInitiator(this.profile, {
        ...this.entity,
        initiators: this.initiators,
      });
    }
  }

  async updateInnovationSpaceList(): Promise<void> {
    const innovationSpaces = await this.userConfig.get(
      StorageEnum.orgFilterToken
    );

    const cachedInnovationSpaces = JSON.parse(
      localStorage.getItem(StorageEnum.orgFilterToken)
    );

    const updatedInnovationSpaces = this.findAndUpdateOrgName(innovationSpaces);

    const updatedCachedInnovationSpaces = this.findAndUpdateOrgName(
      cachedInnovationSpaces
    );

    await this.userConfig.set(
      StorageEnum.orgFilterToken,
      updatedInnovationSpaces
    );

    localStorage.setItem(
      StorageEnum.orgFilterToken,
      JSON.stringify(updatedCachedInnovationSpaces)
    );
  }

  findAndUpdateOrgName(orgFilters: OrganizationFilter[]): OrganizationFilter[] {
    const orgFilterIndex = orgFilters.findIndex(
      (item) => item.id === this.entity.id
    );

    if (orgFilterIndex > 0) {
      const orgFilter = orgFilters[orgFilterIndex];

      orgFilter.name = this.entity.orgName;
    }

    return orgFilters;
  }

  protected afterGenerateFormControl(): void {
    super.afterGenerateFormControl();

    this.updateFormDefaultImage();
  }

  resetImage(type: 'Image' | 'Logo'): void {
    this.form.patchValue({
      [type]: type === 'Image' ? this.defaultBanner : this.defaultLogo,
    });
  }

  updateTenantDefaultImage(tenant: TenantInterface): void {
    this.defaultBanner = this.isShownAsChallenge
      ? tenant.defaultChallengeBanner
      : tenant.defaultOrganizationBanner;
    this.defaultLogo = this.isShownAsChallenge
      ? tenant.defaultChallengeLogo
      : tenant.defaultOrganizationLogo;

    this.updateFormDefaultImage();
  }

  private updateFormDefaultImage() {
    if (this.form) {
      this.form.patchValue({
        Logo: this.defaultLogo.url && this.isCreating ? this.defaultLogo : null,
        Image:
          this.defaultBanner.url && this.isCreating ? this.defaultBanner : null,
      });
    }
  }

  getOrgCustomMetaData(
    attributeDescriptions: AttributeDescription[]
  ): AttributeDescription[] {
    return attributeDescriptions.filter((attr) => {
      return this.ORGANIZATION_CUSTOM_ATTRIBUTE_ORDER_OF_PROPERTY_NAMES.includes(
        attr.propertyName
      );
    });
  }

  shouldShowOrgCustomAttributes(): boolean {
    if (this.isCreating) {
      return (
        !this.isShownAsChallenge &&
        !!this.fieldOptionPipe.transform(this.entityMetaInfo, 'ResidentOf')
      );
    } else {
      return !this.isShownAsChallenge && this.form?.get('ResidentOf')?.value;
    }
  }

  loadCommunity() {
    this.loadInitiators(this.entity.id);
    this.loadStakeholders(this.entity.id);
  }

  openDeactivateOrgPopup(isChallenge: boolean): void {
    const modalRef = this.modalService.open(DeactivateDialogComponent, {
      centered: true,
      backdrop: 'static',
      size: 'md',
      modalDialogClass: 'deactivate-modal',
    });
    let deactivateOrgObs;

    if (isChallenge) {
      deactivateOrgObs = this.challengeHttpService.deactivateChallenge(
        this.entity.id
      );
    } else {
      deactivateOrgObs = this.orgHttpService.deactivateOrg(this.entity.id);
    }

    const successCallback = () => {
      FormUtils.navigateTo(
        isChallenge
          ? environment.jipUrl.challenges
          : environment.jipUrl.organizations
      );
    };

    modalRef.componentInstance.heading =
      'UI.DeactivateOrganization.confirmationMessage';
    modalRef.componentInstance.message =
      'UI.DeactivateOrganization.deactivateSadMessage';
    modalRef.componentInstance.subMessage =
      'UI.DeactivateOrganization.deactivateWarning';
    modalRef.componentInstance.confirmLabel =
      'UI.DeactivateProfile.sorryButton';
    modalRef.componentInstance.cancelLabel = 'UI.DeactivateProfile.stayButton';
    modalRef.componentInstance.deactivateObs$ = deactivateOrgObs;
    modalRef.componentInstance.successCallback = successCallback;
  }

  onMessageRightChange(value) {
    this.form.patchValue({
      SendMessageRights: value,
    });
  }

  changeMessageRightSelectedIndex(value): void {
    const selectionChoices =
      this.getFieldOptions('SendMessageRights').choice.selections;
    if (!value || !selectionChoices) {
      return;
    }

    selectionChoices.forEach((item, index) => {
      if (typeof value === 'string') {
        if (
          value === item.order ||
          value === item.codeId ||
          value === item.value
        ) {
          this.challengeMessageRightSelectedIndex = index;
        }
      } else if (typeof value === 'object') {
        if (value.codeId === item.codeId) {
          this.challengeMessageRightSelectedIndex = index;
        }
      }
    });
  }

  copyOrgUrl(): void {
    const url = this.getFilterTokenUrl();
    copyToClipboard(url, () => {
      this.isUrlCopied = true;

      setTimeout(() => {
        this.isUrlCopied = false;
      }, 700);
    });
  }

  createEventFromThisOrg(entityId: number): void {
    FormUtils.navigateTo(
      this.generateCreateEntityUrl(EntityName.Event, entityId)
    );
  }
}
