<div class="modal-body modal-body-confirmation">
  <div class="d-flex justify-content-center px-2 pt-2">
    <i class="far fa-frown deactivate-profile-icon"></i>
  </div>
  <div class="text-center mt-2">
    <h4 class="font-weight-bold font-size-xl" translate>
      {{ heading }}
    </h4>
    <p class="mb-0 font-size-sm" translate>{{ message }}</p>
    <p class="mb-0 font-size-sm" translate>{{ subMessage }}</p>
  </div>
</div>
<div class="modal-footer pt-0">
  <div class="d-flex flex-column w-100">
    <div class="d-flex flex-row justify-content-center">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="confirm()"
        [disabled]="isDeactivating"
      >
        <i *ngIf="isDeactivating" class="fas fa-spinner fa-spin ml-2"></i>
        {{ confirmLabel | translate | uppercase }}
      </button>
      <button
        type="button"
        class="btn btn-primary ml-4"
        (click)="cancel()"
      >
        {{ cancelLabel | translate | uppercase }}
      </button>
    </div>
  </div>
</div>
