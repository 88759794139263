<div class="organizer__container">
  <!-- Edit mode -->
  <form
    [formGroup]="orgForm"
    [class.disabled]="disabledSection"
    *ngIf="editing"
  >
    <div
      class="form-group d-flex flex-wrap justify-content-between align-items-center"
      *ngIf="labelSectionName"
    >
      <div
        translate
        class="widget__label col-sm-12 col-md-3 px-0"
        [class]="labelClass"
      >
        {{ labelSectionName }}
      </div>
    </div>

    <div class="form-group search">
      <app-organisation-search
        [enableCustomOnDropdown]="true"
        class="lead-org-container"
        [resultContainer]="'lead-org-container'"
        [ignoreCompany]="leadCompany"
        [userId]="userId"
        [searchMode]="searchMode"
        [excludedOrganisationIds]="otherOrganisationIds"
        (selectOrganisation)="onSelect($event)"
      ></app-organisation-search>
    </div>

    <div class="form-group position-relative my-3" *ngIf="fillOrg">
      <div class="row align-items-center justify-content-between">
        <div class="col-6 col-lg-8">
          <app-file-upload
            [multiLocale]="false"
            [disabled]="isLinkedFromParent && !isExternalOrg"
            (srcUrlChange)="preview($event)"
          >
            <div
              *ngIf="!imgURL"
              class="card flex-shrink-0 upload-file__container justify-content-center align-items-center"
              [ngClass]="{
                'alert alert-danger': !isLinkedFromParent && logoId.invalid
              }"
            >
              <i
                class="fas fa-camera fa-lg"
                [ngClass]="{
                  'upload-file__icon': isLinkedFromParent || logoId.valid
                }"
              ></i>
            </div>

            <div
              *ngIf="imgURL"
              class="card flex-shrink-0 upload-file__container border-0"
            >
              <img [src]="imgURL" appDefaultPlaceholder appDynamicImgUrl />
            </div>
          </app-file-upload>
        </div>

        <div class="col mb-0">
          <div class="row align-items-center justify-content-end">
            <div
              class="organizer__pending px-2 col col-md-auto mb-0"
              [ngClass]="isPending === true ? 'visible' : 'invisible'"
            >
              <i class="fas fa-spinner"></i>
              <span translate>Confirmation Pending</span>
            </div>
            <button
              *ngIf="formHasValue"
              type="button"
              class="person-organisations__delete-btn btn btn-link"
              (click)="onRemove()"
            >
              <i class="fas fa-trash fa-lg"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="fillOrg">
      <div class="form-group" [class]="childClass">
        <div class="widget__label" translate>
          <label for="name">{{ 'UI.Organization.Name' | translate }}</label>
        </div>

        <div class="position-relative">
          <input
            type="text"
            class="form-control"
            id="name"
            formControlName="orgName"
          />
          <ng-container *ngIf="orgName?.pending">
            <div class="input-spinner-container">
              <i class="fas fa-spinner fa-spin"></i>
            </div>
          </ng-container>
        </div>

        <div
          *ngIf="orgName.errors && orgName.errors.required && orgName.touched"
          class="alert alert-danger"
          translate
        >
          Field is required.
        </div>

        <div
          class="alert alert-danger"
          *ngIf="orgName.hasError('nameDuplicated')"
        >
          {{ orgName.getError('nameDuplicated').message }}
        </div>
      </div>

      <div class="form-group" [class]="childClass">
        <div class="widget__label" translate>
          <label for="url">{{ 'UI.OrganizationURL' | translate }}</label>
        </div>

        <input
          type="text"
          class="form-control"
          id="url"
          formControlName="orgURL"
          [pattern]="urlAttributeDescription?.regularExpression"
        />
        <div
          *ngIf="
            orgForm.controls.orgURL.invalid &&
            (orgForm.controls.orgURL.dirty || orgForm.controls.orgURL.touched)
          "
          class="alert alert-danger"
          translate
        >
          UI.Common.Url-Hint
        </div>
      </div>

      <ng-content></ng-content>
    </div>
  </form>

  <!-- View mode -->
  <ng-container *ngIf="!editing">
    <section
      *ngIf="isActiveOrg(); else deactiveOrg"
      class="w-100 form-group organizer__container--view d-flex flex-column"
      (click)="goToEntityPage()"
      [ngClass]="{
        'pointer-cursor': !isExternalOrg
      }"
    >
      <div
        *ngIf="advancedSetting"
        class="setting-btn w-100 d-flex justify-content-end align-items-end"
      >
        <button
          class="btn p-0"
          appDebounceClick
          (debounceClick)="openSettingModal()"
        >
          <i class="fas fa-cog"></i>
        </button>
      </div>
      <!-- Section label e.g. 'Organiser' -->
      <div
        *ngIf="labelSectionName"
        translate
        class="widget__label col-sm-12 col-md-3 px-0"
        [class]="labelClass"
      >
        {{ labelSectionName }}
      </div>

      <!-- Submission status -->
      <div
        *ngIf="leadCompany?.submissionStatus"
        class="d-flex align-items-center mb-3"
      >
        <label class="flag flag--submitted">
          <span>{{ 'Venture State' | translate }}:&nbsp;</span>

          <span>{{
            leadCompany?.submissionStatus?.value ||
              VentureSubmissionStatus.Submitted | translate
          }}</span>
        </label>
      </div>

      <!-- Organisation Image -->
      <div
        *ngIf="!isPending || isOwner"
        class="form-group position-relative d-flex align-items-center"
      >
        <div
          *ngIf="imgURL"
          class="card flex-shrink-0 upload-file__container upload-file__container--readonly border-0"
          [ngbTooltip]="orgForm.get('orgName').value"
          [ngClass]="{
            'cursor-not-allowed': isExternalOrg
          }"
          [container]="'body'"
        >
          <img
            [src]="imgURL"
            appDefaultPlaceholder
            appDynamicImgUrl
            class="w-100"
          />
        </div>
        <ng-container
          *ngIf="
            !editing &&
            showRequireHint &&
            leadCompanyAttribute.required &&
            !leadCompany &&
            !orgForm.get('orgName').value
          "
        >
          <div>
            {{ 'UI.RequireOrganizerHint.ForBetterProfile' | translate }}
            <a
              class="btn btn-link form-section__btn clear-all-btn font-size-base text-underline route-active m-0 border-0 align-baseline btn-lowercase"
              (click)="changeFormStatus.emit($event)"
              translate
              >UI.RequireOrganizerHint.ClickHere</a
            >
            {{ 'UI.RequireOrganizerHint.ToAdd' | translate }}
          </div>
        </ng-container>
        <div
          class="ml-3 d-flex flex-column flex-md-row align-items-md-center justify-content-between"
          [ngClass]="{
            'cursor-not-allowed': isExternalOrg,
            'flex-grow-1': !isExternalOrg
          }"
        >
          <div
            class="w-100"
            [ngbTooltip]="'UI.Organization.ExternalOrgTooltip' | translate"
            [disableTooltip]="!isExternalOrg"
          >
            <div class="d-flex justify-content-between">
              <!-- Organisation name -->
              <h6 class="truncate-line-1 organizer__name flex-grow-1">
                {{ orgForm.get('orgName').value }}
              </h6>

              <button
                *ngIf="showSubmitButton"
                appDebounceClick
                (click)="onSubmitClicked(submitInput)"
                class="btn btn-primary form-section__btn ml-3 flex-shrink-0"
                [disabled]="submitInput.checked"
              >
                <input type="checkbox" #submitInput hidden />
                <i
                  class="fas fa-spinner fa-spin m-0"
                  *ngIf="submitInput.checked"
                ></i>
                {{ 'UI.Common.Submit' | translate }}
              </button>
            </div>
            <!-- Organisation URL -->
            <div class="d-flex align-items-center" [hidden]="!orgUrl">
              <a
                class="organizer__link truncate-line-1 mr-2 underline"
                [value]="orgUrl"
                appUrlPrefix
                target="_blank"
                (click)="$event.stopPropagation()"
              >
                {{ orgUrl }}
              </a>

              <ng-container *ngIf="orgUrl">
                <app-copy-text
                  *ngIf="!isExternalOrg; else navigateIcon"
                  [textToCopy]="orgUrl"
                ></app-copy-text>
                <ng-template #navigateIcon>
                  <a
                    class="d-flex"
                    [value]="orgUrl"
                    appUrlPrefix
                    target="_blank"
                    (click)="$event.stopPropagation()"
                  >
                    <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </ng-template>
              </ng-container>
            </div>
          </div>

          <!-- Confimation Pending label -->
          <div
            *ngIf="isPending === true"
            class="organizer__pending px-2 mt-2 mt-md-0"
          >
            <i class="fas fa-spinner"></i>
            <span translate>Confirmation Pending</span>
          </div>
        </div>
      </div>
    </section>
    <ng-template #deactiveOrg>
      <div class="w-100 mb-2">
        <div
          *ngIf="labelSectionName"
          translate
          class="widget__label col-sm-12 col-md-3 px-0"
          [class]="labelClass"
        >
          {{ labelSectionName }}
        </div>
        <div class="d-flex flex-row align-items-center">
          <div
            class="card upload-file__container upload-file__container--readonly text-white mr-3"
          >
            <app-deactive-entity
              [entityName]="EntityName.Organization"
              [iconClass]="deactivatedOrgIcon"
            ></app-deactive-entity>
          </div>
          <div class="deactive-label">
            {{ deactivatedOrgLabel | translate }}
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>
