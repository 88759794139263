<div class="news-messages__wrapper widget__card--background">
  <ng-container *ngIf="news">
    <app-message-header-wrapper
      [people]="news.from"
      [isSystemNews]="news.isSystemNews"
      [messageDate]="news.dateTime"
      [showPeopleAsJipLogo]="news.action === MESSAGE_ACTION.VentureAwarded"
      [isPostOwner]="news | call : isPostOwner : currentPersonId"
      [isEdited]="news.action === MESSAGE_ACTION.Post && news.isEdited"
      (edited)="openEditingPostDialog(news)"
      (deleted)="onDeletePost(news)"
    >
      <div
        class="news-messages__owner-name"
        [ngClass]="{
          'd-none':
            news.action === MESSAGE_ACTION.VentureSubmittedOrganization ||
            news.action === MESSAGE_ACTION.VentureAwarded
        }"
      >
        <ng-container *ngIf="news.from; else replaceText">
          <a
            *ngIf="
              news.action !== MESSAGE_ACTION.VentureSubmittedOrganization &&
              news.action !== MESSAGE_ACTION.VentureAwarded
            "
            appUnsavedFormCheck
            class="underline d-inline-block jip-upper-first-letter btn-link-primary"
            [href]="peopleURL + '/' + news.from.id"
          >
            {{ getSender() }} </a
          ><app-bot-flag
            *ngIf="
              news.from?.isBot &&
              news.action !== MESSAGE_ACTION.VentureSubmittedOrganization &&
              news.action !== MESSAGE_ACTION.VentureAwarded
            "
            [parentClass]="'ml-1 d-inline-block'"
          ></app-bot-flag
          >&nbsp;</ng-container
        >
        <ng-template #replaceText>
          <ng-container *ngIf="!news.isSystemNews; else portalPart">
            <span class="deactive-label">{{
              'UI.UnsubscribedUser' | translate
            }}</span
            >&nbsp;</ng-container
          >
          <ng-template #portalPart>
            <a [href]="portalUrl">{{ portalName }}</a
            >&nbsp;</ng-template
          >
        </ng-template>
      </div>
      <app-card-action
        [id]="news.content?.instanceId"
        [content]="news.content"
        [actionType]="news.action"
        [entityName]="news.content?.entityName"
        [from]="news.from"
        [currentPersonId]="currentPersonId"
      ></app-card-action>
    </app-message-header-wrapper>

    <div class="news-messages__body">
      <div *ngIf="news.message" class="news-messages__description">
        <app-rich-text-toggle
          [text]="news.message"
          [controlName]="'group-message' + news.id"
          [minNumOfRows]="2"
          [image]="
            news.action === MESSAGE_ACTION.Post ? news.content?.image : ''
          "
          *ngIf="
            !(news.action === MESSAGE_ACTION.Post && news.content?.image);
            else postWithImage
          "
        ></app-rich-text-toggle>
      </div>
      <div [ngSwitch]="news.content?.entityName">
        <div *ngSwitchCase="ENTITY_NAME.Event">
          <app-event-card [data]="news.content"></app-event-card>
        </div>
        <div *ngSwitchCase="ENTITY_NAME.Venture">
          <app-venture-phase-card
            *ngIf="news.action === MESSAGE_ACTION.Updated; else ventureOthers"
            [data]="news.content"
            [uiTemplate]="news.uiTemplate"
          ></app-venture-phase-card>
          <ng-template #ventureOthers>
            <app-venture-card
              [data]="news.content"
              [uiTemplate]="news.content.uiTemplate"
            ></app-venture-card>
          </ng-template>
        </div>
        <div *ngSwitchCase="ENTITY_NAME.VentureAwarded">
          <app-venture-card
            [data]="news.content"
            [uiTemplate]="news.content.uiTemplate"
          ></app-venture-card>
        </div>
        <div *ngSwitchCase="ENTITY_NAME.Person">
          <app-person-card [data]="news.content"></app-person-card>
        </div>
        <div *ngSwitchCase="ENTITY_NAME.Organization">
          <app-organization-card
            [isLabelNameShown]="false"
            [data]="news.content"
          ></app-organization-card>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="venture__footer">
  <ul
    class="list-inline d-flex justify-content-start venture__actions news-item-footer"
  >
    <app-like-entity
      [entity]="news"
      (triggerToggleLike)="toggleLike(news)"
      (triggerShowLikingsData)="showLikes(news)"
    ></app-like-entity>

    <app-send-message-btn
      *ngIf="
        news.action !== MESSAGE_ACTION.VentureAwarded &&
        currentPersonId !== news.from?.id
      "
      [receiver]="news.from"
    >
    </app-send-message-btn>

    <app-comment-entity
      [entity]="news"
      [entityName]="ENTITY_NAME.News"
      (afterCommentModalClosed)="afterCommentModalClosed.emit(news)"
    ></app-comment-entity>
  </ul>
</div>
<ng-template #postWithImage>
  <div class="col" style="box-shadow: none">
    <div class="row" style="box-shadow: none">
      <div class="col-12 col-md-6 col-lg-5 p-0">
        <div
          *ngIf="news.action === MESSAGE_ACTION.Post"
          appHoverDisplay
          [dataHoverDirective]="
            news.action === MESSAGE_ACTION.Post ? news.content?.image : ''
          "
          class="card-item__image-wrapper mh-0"
          style="max-height: 0"
        >
          <img
            [src]="
              news.action === MESSAGE_ACTION.Post ? news.content?.image : ''
            "
            appDefaultPlaceholder
          />
        </div>
      </div>

      <div
        class="col-12 col-md-6 col-lg-7 link-unstyled pt-0 pb-0 pl-0 pl-md-4 widget__news-message--background"
        appUnsavedFormCheck
      >
        <app-rich-text-toggle
          [text]="news.message"
          [controlName]="'group-message' + news.id"
          [hideSeeMore]="false"
          [minNumOfRows]="12"
        ></app-rich-text-toggle>
      </div>
    </div>
  </div>
</ng-template>
