import { Component, Input } from '@angular/core';
import { ToastService } from '@core/toast.service';
import {
  NgbActiveModal,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbTimeStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { TimeUtils } from '@shared/utils/time-utils';
import { ExportHttpServiceService } from '@src/app/core/http/export-http-service.service';
import { CentralConfigService } from '@src/app/core/services/central-config.service';
import { GlobalFilterStoredService } from '@src/app/core/services/global-filter-stored.service';
import { CustomDateParser2Formatter } from '@src/app/shared/components/boostrap-datepicker/boostrap-datepicker.component';
import { TreeNode } from '@src/app/shared/components/tree-view-check-box/tree-view-check-box.component';
import { Observable } from 'rxjs';
import { EntityExportComponent } from '../../entity-section/entity-export/entity-export.component';

@Component({
  selector: 'app-form-summary-export',
  templateUrl: './form-summary-export.component.html',
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParser2Formatter },
  ],
})
export class FormSummaryExportComponent extends EntityExportComponent {
  @Input() textHeader = '';

  date = {
    fromDate: '',
    toDate: '',
  };

  minToDate: NgbDateStruct;
  maxFromDate: NgbDateStruct;

  exportStartTime: NgbTimeStruct = {
    hour: 0,
    minute: 0,
    second: 0,
  };

  exportEndTime: NgbTimeStruct = {
    hour: 23,
    minute: 59,
    second: 59,
  };

  disableCheckboxOptions: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    protected toastService: ToastService,
    protected exportService: ExportHttpServiceService,
    private filterStoredService: GlobalFilterStoredService,
    private centralConfig: CentralConfigService
  ) {
    super(activeModal, toastService);
  }

  setOrgId(data: TreeNode[], orgId: number, showAsChallenge?: boolean): void {
    this.entityId = orgId;
    this.checkboxItems = data;

    if (this.entityId) {
      this.checkboxOptionsItems = [
        {
          text: showAsChallenge
            ? 'UI.Export.IncludePartnerOrganizationsOfChallenge'
            : 'UI.Export.IncludePartnerOrganizations',
          value: 'includePartnerOrganizations',
          checked: true,
        },
      ];

      if (!showAsChallenge) {
        this.checkboxOptionsItems.unshift({
          text: 'UI.Export.IncludeChallenges',
          value: 'includeSubChallenges',
          checked: true,
        });
      }

      this.textHeader = `UI.Export.Popup-header.Organization`;
    } else {
      this.textHeader = `UI.Export.Popup-header.Admin`;
    }
  }

  async handleExport(force = false): Promise<void> {
    let api$: Observable<any>;
    const params = await this.getFormParams();
    params.force = force;

    if (this.entityId) {
      api$ = this.exportService.getReportForOrgOwner(this.entityId, params);
    } else {
      api$ = this.exportService.getReportForAdmin(params);
    }
    this.handleExportResponse(api$);
  }

  async getFormParams(): Promise<any> {
    let params = {
      ...this.getParamsExportValue(this.checkboxItems, { ...this.date }),
    };

    if (this.entityId && this.checkboxOptionsItems.length > 0) {
      const checkboxOptionsItems = this.disableCheckboxOptions
        ? this.checkboxOptionsItems.map((item) => ({
            ...item,
            checked: false,
          }))
        : this.checkboxOptionsItems;

      params = {
        ...this.getParamsExportValue(checkboxOptionsItems, { ...params }),
      };
    }

    const filterCriteria = await this.filterStoredService.getFilterCriteria();
    const globalOrgId =
      this.centralConfig.innovationSpaceId ??
      filterCriteria?.organizationIds?.[0];
    if (globalOrgId) {
      params = { ...params, organizationId: globalOrgId };
    }

    return params;
  }

  setFromDate(date): void {
    this.date.fromDate = date;
    this.minToDate = TimeUtils.getBoostrapDate(date);
  }

  setToDate(date): void {
    this.date.toDate = date;
    this.maxFromDate = TimeUtils.getBoostrapDate(date);
  }

  onCheckboxItemsChange(): void {
    const shouldHideOptions = this.shouldHideOptions();
    this.disableCheckboxOptions =
      this.checkboxItems.every((c) => !c.checked) || shouldHideOptions;
  }

  shouldHideOptions(): boolean {
    const checkBoxValues = this.checkboxItems.reduce((acc, node) => {
      acc[node.value] = node.checked;
      return acc;
    }, {} as { [key: string]: boolean });

    const onlyExportChallenges =
      checkBoxValues['exportChallenges'] &&
      !checkBoxValues['exportVentures'] &&
      !checkBoxValues['exportEvents'] &&
      !checkBoxValues['exportPeople'];
    const onlyExportOrganizations =
      checkBoxValues['exportOrganizations'] &&
      !checkBoxValues['exportVentures'] &&
      !checkBoxValues['exportEvents'] &&
      !checkBoxValues['exportPeople'];
    const exportChallengesAndOrganizations =
      checkBoxValues['exportChallenges'] &&
      checkBoxValues['exportOrganizations'] &&
      !checkBoxValues['exportVentures'] &&
      !checkBoxValues['exportEvents'] &&
      !checkBoxValues['exportPeople'];

    return (
      (onlyExportChallenges && !checkBoxValues['exportOrganizations']) ||
      (onlyExportOrganizations && !checkBoxValues['exportChallenges']) ||
      exportChallengesAndOrganizations
    );
  }

  shouldDisableExportButton(): boolean {
    return this.checkboxItems.every((item) => !item.checked);
  }
}
