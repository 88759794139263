import { Injectable } from '@angular/core';
import { EventViewMode } from '@src/app/components/master-layout/page-header/page-header.component';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class PageHeaderService {
  private eventViewMode$ = new BehaviorSubject<EventViewMode>(EventViewMode.TileView);
  constructor() {}

  getEventViewMode(): Observable<EventViewMode> {
    return this.eventViewMode$.asObservable();
  }
  
  setEventViewMode(value: EventViewMode): void {
    this.eventViewMode$.next(value);
  }
}
