import { Component, Input, OnInit } from '@angular/core';
import { ToastsContainerComponent as ToastsComp } from '@components/toasts-container/toasts-container.component';
import { CentralConfigService } from '@core/services/central-config.service';
import { SessionService } from '@core/session.service';
import { LoadingIndicatorComponent as LoadingComp } from '@shared/components/loading-indicator/loading-indicator.component';
import { TemplateName } from '@shared/constants/visibility-config.const';
import { AppendComponentToBodyService } from '@shared/services/append-component-to-body.service';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { StorageEnum } from '@src/app/shared/enums/storage.enum';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-bootstrap',
  template: ``,
  styles: ['{ display: none; }'],
})
export class BootstrapComponent implements OnInit {
  @Input() set domain(value: string) {
    if (!value) return;
    this.sessionService.setDomain(value);
  }

  @Input() set isId(value: string) {
    if (!value) return;
    this.setInnovationSpace(value);
  }

  @Input() set uiTemplate(value: TemplateName) {
    this.centralConfigService.uiTemplateConfig$.next(value);
  }

  @Input() set apiUrl(value: string) {
    if (!value) return;
    this.centralConfigService.apiUrl$.next(value);
  }

  @Input() set newsRedirectPath(value: string) {
    if (!value) return;
    this.centralConfigService.newsRedirectPath = value;
  }

  constructor(
    public readonly sessionService: SessionService,
    private readonly centralConfigService: CentralConfigService,
    private readonly appendService: AppendComponentToBodyService,
    private authService: AuthenService
  ) {}

  ngOnInit(): void {
    this.registerToastComp();
    this.registerLoadingIndicatorComp();
    this.redirectToNewsPage();
    this.handleTokenExpire();
  }

  setInnovationSpace(id: string): void {
    this.centralConfigService.innovationSpaceId = Number(id);
    this.centralConfigService.read(id).subscribe();
  }

  registerToastComp(): void {
    const isRendered = document.getElementsByTagName('app-toasts');

    if (isRendered?.length) return;

    this.appendService.append(ToastsComp);
  }

  registerLoadingIndicatorComp(): void {
    const isRendered = document.getElementsByTagName('app-loading-indicator');

    if (isRendered?.length) return;

    this.appendService.append(LoadingComp);
  }

  handleTokenExpire(): void {
    const tokenExpireTime = this.sessionService.getCookie(
      StorageEnum.expiredTokenTime
    );
    if (tokenExpireTime && this.sessionService.isTokenExpired(true)) {
      console.log({ tokenExpireTime });
      if (
        !location.href.includes(environment.jipUrl.login) ||
        !location.href.includes(environment.jipUrl.processToken)
      ) {
        this.sessionService.setLoginCallBackpage(location.href);
      }
      this.authService.resetSession();
      this.sessionService.setCookie(StorageEnum.isTokenExpired, true);
    }
  }

  redirectToNewsPage(): void {
    const tabNewsParam = StringUtils.getParamFromUrl(UrlParam.TabNews);
    const isOnNewsPage = window.location.href.includes(
      this.centralConfigService.newsRedirectPath
    );

    if (!tabNewsParam || isOnNewsPage) return;

    const url = `${this.centralConfigService.newsRedirectPath}/?${UrlParam.TabNews}=message`;
    FormUtils.navigateToWithLanguagePath(this.sessionService, url);
  }
}
