<a
  appUnsavedFormCheck
  [href]="peopleDetailUrl"
  (click)="onPeopleCardClicked(person)"
  class="link-unstyled"
>
  <ng-container
    *ngIf="!editable && customUserRoles.length > 0 && person.role?.codeId"
  >
    <div class="pt-3 profile__flag">
      <label class="flag flag-role pointer-cursor truncate-line-1">
        <span> {{ 'UI.Profile.Role' | translate }}: </span>
        <b> {{ person.role.value }} </b>
      </label>
    </div>
  </ng-container>

  <div class="profile__info py-3">
    <div
      *ngIf="!hideProfileImage"
      class="profile__photo mr-3 position-relative"
    >
      <img
        class="people__avatar widget__avatar-image"
        [src]="person?.image"
        appDefaultAvatar
        alt=""
        appDynamicImgUrl
      />
      <i
        class="fas fa-user-lock"
        *ngIf="person?.isPrivate && !isCurrentUser"
        [ngbTooltip]="'UI.People.PrivatePersonTooltip' | translate"
        placement="right"
      ></i>
    </div>

    <div class="d-flex flex-column overflow-hidden">
      <div>
        <div class="profile__name d-flex align-items-center">
          <div>
            {{ person?.firstName | concatName : person?.lastName }}
          </div>
          <app-bot-flag
            *ngIf="person?.isBot"
            [parentClass]="'ml-1'"
          ></app-bot-flag>
        </div>
        <div
          *ngIf="person?.jobTitle"
          appTooltip
          class="profile__position truncate-line-1"
        >
          <div>
            {{ person?.jobTitle }}
          </div>
        </div>
        <div *ngIf="person?.company" class="profile__company">
          <div>
            {{ getCompanyName(person) }}
          </div>
        </div>
        <div *ngIf="person.isUnregisteredUser">
          {{ person?.email }}
        </div>
        <div>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</a>
