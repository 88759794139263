import { Injectable } from '@angular/core';
import { NewMessageDialogComponent } from '@components/news-messages/components/new-message-dialog/new-message-dialog.component';
import { NewPostDialogComponent } from '@components/news-messages/components/new-post-dialog/new-post-dialog.component';
import { SessionService } from '@core/session.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EntityName } from '@shared/enums/entity-name.enum';
import { GlobalFilterCriteria } from '@shared/interfaces/filters/global-filter.interface';
import { News } from '@shared/interfaces/news.interface';
import { DeletePostDialogComponent } from '@src/app/components/news-messages/components/delete-post-dialog/delete-post-dialog.component';
import { ChatMessage } from '@src/app/shared/interfaces/chat-message.interface';
import { Subject } from 'rxjs';

const modalOptions: NgbModalOptions = {
  centered: true,
  backdrop: 'static',
  size: 'lg',
  scrollable: false,
  windowClass: 'message-modal-custom',
};

@Injectable({
  providedIn: 'root',
})
export class EntityCreationModalService {
  newsMessageCreated$ = new Subject<EntityName>();
  postEdited$ = new Subject<number>();
  postDeleted$ = new Subject<void>();
  threadMessageDeleted$ = new Subject<number>();

  constructor(
    private readonly sessionService: SessionService,
    private readonly modalService: NgbModal
  ) {}

  createPost(orgId: number, globalFilterCriteria: GlobalFilterCriteria): void {
    if (!this.sessionService.isLogin) {
      return;
    }

    const modalRef = this.modalService.open(
      NewPostDialogComponent,
      modalOptions
    );

    modalRef.componentInstance.globalOrgId = orgId;
    modalRef.componentInstance.globalFilterCriteria = globalFilterCriteria;
    modalRef.result.then((isSent: boolean) => {
      if (!isSent) return;
      this.newsMessageCreated$.next(EntityName.Post);
    });
  }

  modifyPost(post: News, globalFilterCriteria: GlobalFilterCriteria): void {
    if (!this.sessionService.isLogin) {
      return;
    }

    const modalRef = this.modalService.open(
      NewPostDialogComponent,
      modalOptions
    );

    modalRef.componentInstance.globalFilterCriteria = globalFilterCriteria;
    modalRef.componentInstance.postData = post;
    modalRef.result.then((isSent: boolean) => {
      if (!isSent) return;
      this.postEdited$.next(post.id);
    });
  }

  createMessage(): void {
    if (!this.sessionService.isLogin) {
      return;
    }

    const isEnableCommunication =
      this.sessionService.tenant.enableCommunication;

    const modalRef = this.modalService.open(NewMessageDialogComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      scrollable: false,
      windowClass: `message-modal-custom ${
        !isEnableCommunication && 'service-not-available-dialog'
      }`,
    });

    modalRef.result.then((isSent: boolean) => {
      if (!isSent) return;
      this.newsMessageCreated$.next(EntityName.Message);
    });
  }

  deletePost(post: News): void {
    if (!this.sessionService.isLogin) {
      return;
    }

    const modalRef = this.modalService.open(DeletePostDialogComponent, {
      centered: true,
      backdrop: 'static',
    });

    modalRef.componentInstance.message = 'UI.DeactivatePost.Confirmation';
    modalRef.componentInstance.confirmLabel = 'Confirm';
    modalRef.componentInstance.post = post;
    modalRef.componentInstance.entityName = EntityName.News;

    modalRef.result.then((isConfirmed) => {
      if (!isConfirmed) return;
      this.postDeleted$.next();
    });
  }

  deleteThreadMessage(message: ChatMessage) {
    if (!this.sessionService.isLogin) {
      return;
    }

    const modalRef = this.modalService.open(DeletePostDialogComponent, {
      centered: true,
      backdrop: 'static',
    });

    modalRef.componentInstance.message = 'UI.DeleteThreadMessage.Confirmation';
    modalRef.componentInstance.confirmLabel = 'Confirm';
    modalRef.componentInstance.post = message;
    modalRef.componentInstance.entityName = EntityName.Message;

    modalRef.result.then((isConfirmed) => {
      if (!isConfirmed) return;
      this.threadMessageDeleted$.next(message.id);
    });
  }
}
