import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenService } from '@core/authen/authen.service';
import { SessionService } from '@core/session.service';
import { NavInterface } from '@shared/interfaces/nav.interface';
import { UserInterface } from '@shared/interfaces/user.interface';
import { ReportingPopupService } from '@src/app/core/services/reporting-popup.service';
import { StorageEnum } from '@src/app/shared/enums/storage.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-nav-profile',
  templateUrl: './nav-profile.component.html',
})
export class NavProfileComponent implements OnInit, OnDestroy {
  profileUrl = environment.jipUrl.profile;
  changePasswordUrl = environment.jipUrl.changePassword;
  changeEmailUrl = environment.jipUrl.changeEmail;
  adminSettingsUrl = environment.jipUrl.adminSettings;

  profile: UserInterface;

  navItem: NavInterface = {
    name: 'Login',
    jipIcon: 'navLogin',
    route: environment.jipUrl.profile,
  };
  tenantUrl: string;

  constructor(
    public authenService: AuthenService,
    private reportingPopupService: ReportingPopupService,
    public sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.authenService.getProfile().subscribe((userProfile) => {
      this.profile = userProfile;
    });

    this.sessionService.apiReady$
      .pipe(untilDestroyed(this))
      .subscribe((ready) => {
        if (ready) {
          this.tenantUrl = this.sessionService.getTenantUrl();
        }
      });
  }

  logout(event): void {
    event.preventDefault();
    this.authenService.logout();
  }

  goToProfile(): void {
    if (this.sessionService.isLogin) {
      this.goTo(this.profileUrl);
    }
  }

  goTo(url): void {
    FormUtils.navigateTo(this.sessionService.appendLanguagePath(url));
  }

  openExportPopup(): void {
    this.reportingPopupService.openAdminReport();
  }

  storePreviousUrl(): void {
    localStorage.setItem(StorageEnum.previousUrl, window.location.href);
  }

  goToLoginPage(): void {
    this.goTo(environment.jipUrl.login);
  }

  getCallBackLoginPage(): string {
    const isClientLandingPage = window.location.href.includes(
      environment.jipUrl.clientLandingPage
    );
    const isTermOfUsePage = window.location.href.includes(
      environment.jipUrl.termsOfUse
    );

    const currentLocale = this.sessionService
      .getCurrentLanguage()
      .locale.toLowerCase();

    const isHomePage =
      window.location.pathname ===
      `/${currentLocale}${environment.jipUrl.home}`;

    if (isClientLandingPage || isTermOfUsePage || isHomePage) {
      return environment.jipUrl.news;
    }
  }

  ngOnDestroy(): void {
    /**/
  }
}
