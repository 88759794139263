import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AuthenService } from '@core/authen/authen.service';
import { CentralConfigService } from '@core/services/central-config.service';
import { SessionService } from '@core/session.service';
import { environment } from '@env/environment';
import { ToBoolean } from '@shared/decorators/to-boolean';
import { ToNumber } from '@shared/decorators/to-number';
import { LogoDisplayOption } from '@shared/models/logo-type.model';
import { BaseComponentWithServiceComponent } from '@src/app/components/base/base-list/base-component-with-service/base-component-with-service.component';
import { EventHttpService } from '@src/app/core/http/event-http.service';
import { CredentialDisplayMode } from '@src/app/shared/enums/credential-display-mode.enum';
import { StorageEnum } from '@src/app/shared/enums/storage.enum';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { TenantInterface } from '@src/app/shared/interfaces/tenant.interface';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent
  extends BaseComponentWithServiceComponent
  implements OnInit, OnChanges
{
  @Input() domain: string;

  @Input() email: string;

  @Input() globalOrgId: string; // Central config

  @Input() eventId: string;

  @Input() logoDisplayOption: LogoDisplayOption = 'jip';

  @Input() termOfUseUrl = environment.termOfUseUrl;

  @Input() portalName = environment.portalName;

  @Input() portalUrl = environment.portalUrl;

  @Input() @ToBoolean() auto_assign_to_org_enabled = true;

  @Input() @ToNumber() assignToOrgId: number;

  @Input() @ToBoolean() applyPendingToAutoAssignment: boolean;

  @HostListener('document:visibilitychange', ['$event'])
  handleVisibilityChange(): void {
    this.handleLoginInit();
  }

  credentialDisplayMode: CredentialDisplayMode = CredentialDisplayMode.Login;
  isShowBackLogin = true;
  isSentConfirmationEmail = false;
  isPasswordResetEmailSent = false;
  isAccountNotActive = false;

  invitationTokens = '';
  isAuthenSessionExpired = false;
  actionParamKey = UrlParam.Action;

  constructor(
    public authenService: AuthenService,
    public sessionService: SessionService,
    public centralConfig: CentralConfigService,
    private eventHttpSerivce: EventHttpService
  ) {
    super(sessionService);
  }

  ngOnInit(): void {
    this.handleLoginInit();
    this.sessionService.tenant$
      .pipe(untilDestroyed(this))
      .subscribe((tenant: TenantInterface) => {
        if (tenant && tenant.termsOfServiceUrl) {
          this.termOfUseUrl = tenant.termsOfServiceUrl;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.termOfUseUrl) {
      if (changes.termOfUseUrl.currentValue && !this.termOfUseUrl) {
        this.termOfUseUrl = changes.termOfUseUrl.currentValue;
      }
    }
  }

  handleLoginInit() {
    const token = StringUtils.getParamFromUrl(UrlParam.Token);

    if (token) {
      this.invitationTokens = token;
      this.toggleRegistrationForm();
    } else {
      if (this.sessionService.isLogin) {
        this.goBack();
      }
    }

    this.isAuthenSessionExpired = Boolean(
      this.sessionService.getCookie(StorageEnum.isTokenExpired)
    );

    this.checkAndUpdateCredentialDisplayMode();
  }

  toggleLoginForm(event?: Event): void {
    event?.preventDefault();
    this.isAccountNotActive = false;
    this.isSentConfirmationEmail = false;

    this.updateActionParamOnUrl(CredentialDisplayMode.Login);
  }

  toggleRegistrationForm(event?: Event): void {
    event?.preventDefault();
    this.isAccountNotActive = false;
    this.isSentConfirmationEmail = false;

    this.updateActionParamOnUrl(CredentialDisplayMode.Register);
  }

  toggleForgotPasswordForm(event?: Event): void {
    event?.preventDefault();
    this.updateActionParamOnUrl(CredentialDisplayMode.ForgotPassword);
  }

  onDisplayedAccountNotActive(): void {
    this.isAccountNotActive = true;
  }

  onSentResetPasswordEmail(): void {
    this.isPasswordResetEmailSent = true;
  }

  setEventDetailPageCallback(): void {
    if (!this.eventId) return;

    const eventDetail = this.sessionService.appendLanguagePath(
      `${environment.jipUrl.event}/${this.eventId}?${UrlParam.RegisterEvent}=true`
    );

    this.sessionService.setLoginCallBackpage(eventDetail);
  }

  goBack(): void {
    const url = this.sessionService.getLoginCallBackpage() || location.origin;
    if (url.includes(environment.jipUrl.login)) {
      FormUtils.navigateTo(this.sessionService.appendLanguagePath('/'));
    } else {
      FormUtils.navigateTo(url);
    }
  }

  onConfirmationEmailSent(): void {
    this.isSentConfirmationEmail = true;
  }

  private checkAndUpdateCredentialDisplayMode(): void {
    const action = StringUtils.getParamFromUrl(this.actionParamKey);

    if (!action) {
      this.credentialDisplayMode = CredentialDisplayMode.Login;
      return;
    }

    const credentialKey = Object.keys(CredentialDisplayMode).find(
      (k) => CredentialDisplayMode[k] === action
    );

    if (credentialKey) {
      this.credentialDisplayMode = CredentialDisplayMode[credentialKey];
    }
  }

  private updateActionParamOnUrl(credentialMode: string): void {
    if (credentialMode === CredentialDisplayMode.Login) {
      StringUtils.removeParamFromUrl(this.actionParamKey);
    } else {
      StringUtils.setQueryParamOnUrl(this.actionParamKey, credentialMode);
    }
    this.checkAndUpdateCredentialDisplayMode();
  }

  ngOnDestroy(): void {
    /** */
  }
}
