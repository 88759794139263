import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationInterface } from '@shared/interfaces/organization.interface';
import { FormSummaryExportComponent } from '@src/app/components/form/form-summary-export/form-summary-export.component';
import { TreeNode } from '@src/app/shared/components/tree-view-check-box/tree-view-check-box.component';

@Injectable({
  providedIn: 'root',
})
export class ReportingPopupService {
  constructor(
    protected modalService: NgbModal,
    protected translateService: TranslateService
  ) {}

  open(org: OrganizationInterface): void {
    const list = this.getCheckBoxItems(org);
    this.openModal(list, org.id, org.showAsChallenge);
  }

  openAdminReport(): void {
    const list = this.getCheckBoxItemsForAdmin();
    this.openModal(list);
  }

  openModal(data = [], orgId = null, showAsChallenge = false): void {
    const modalRef = this.modalService.open(FormSummaryExportComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      scrollable: false,
    });
    const component = modalRef.componentInstance as FormSummaryExportComponent;
    component.setOrgId(data, orgId, showAsChallenge);

    modalRef.result.catch((error) => {
      console.log(error);
    });
  }

  getCheckBoxItems(org?: OrganizationInterface): TreeNode[] {
    let listCheckBox: TreeNode[] = [
      {
        text: this.translateService.instant('Ventures'),
        value: 'exportVentures',
        checked: true,
      },
      {
        text: this.translateService.instant('Events'),
        value: 'exportEvents',
        checked: true,
      },
      {
        text: this.translateService.instant('Organizations'),
        value: 'exportOrganizations',
        checked: true,
      },
      {
        text: this.translateService.instant('Calls & Challenges'),
        value: 'exportChallenges',
        checked: true,
      },
      {
        text: this.translateService.instant('People'),
        value: 'exportPeople',
        checked: true,
      },
    ];

    if (org && org.showAsChallenge) {
      listCheckBox = listCheckBox.filter(
        (item) => item.value !== 'exportChallenges'
      );
    }

    return listCheckBox;
  }

  getCheckBoxItemsForAdmin(): TreeNode[] {
    return this.getCheckBoxItems().filter(
      (item) => item.value !== 'exportPeople'
    );
  }
}
