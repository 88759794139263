import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { CustomFormService } from '@src/app/core/form/custom-form.service';
import { EventHttpService } from '@src/app/core/http/event-http.service';
import { SessionService } from '@src/app/core/session.service';
import { copyToClipboard } from '@src/app/shared/functions/text-copy';
import { AttributeDescription } from '@src/app/shared/interfaces/attribute-description.interface';
import { EventInterface } from '@src/app/shared/interfaces/event.interface';
import { StringReplacementPipe } from '@src/app/shared/pipes/string-replacement.pipe';

export interface PaymentRelatedValue {
  display: string;
  propertyName: string;
  sampleData: string;
  order: number;
  type: number;
}
@Component({
  selector: 'app-event-payment',
  templateUrl: './event-payment.component.html',
  styles: ``,
})
export class EventPaymentComponent implements OnChanges {
  @Input() attributeDescription: AttributeDescription;
  @Input() event: EventInterface;
  @Input() form: FormGroup;
  @Input() isCreating: boolean;
  @Input() httpService: EventHttpService;
  @Input() id: number;
  @Input() editting: boolean;
  @Input() isAdmin: boolean;
  @Input() entity: EventInterface;
  @Input() userId;
  @Input() paymentApiKey = '';

  @Output() paymentFormValue = new EventEmitter();

  paymentUrl = '';

  paymentParams = [];
  isAdvancedSettingOpen = false;
  paymentForm: UntypedFormGroup;
  isEditParameterOn = false;
  isLoadingPaymentInfo = false;
  hasPaymentToken = false;

  excludedParams = ['PaymentLink', 'PaymentApiKey'];
  isPaymentKeyLoading = false;
  isPaymentApiKeyCopied = false;
  paymentNotExist: boolean = false;

  constructor(
    private customFormService: CustomFormService,
    private fb: FormBuilder,
    private stringReplacement: StringReplacementPipe,
    private sessionService: SessionService
  ) {}

  get paymentLink() {
    return this.paymentForm.get('PaymentLink') as FormControl;
  }

  get paymentKey() {
    return this.paymentForm.get('PaymentApiKey') as FormControl;
  }

  get eventName() {
    return this.form.get('Title')?.value;
  }

  get eventPrice() {
    return this.form.get('Price')?.value;
  }

  get eventCurrency() {
    return this.form.get('PriceUnit')?.value;
  }

  get paymentLinkAttributeDesciption(): AttributeDescription {
    return this.attributeDescription.relatedEntityAttributeDescriptions.find(
      (attr) => attr.propertyName === 'PaymentLink'
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.attributeDescription) {
      this.generatePaymentForm();
    }
    this.paymentLink?.valueChanges.subscribe(() => {
      this.updatePaymentUrl();
    });

    if (changes.paymentApiKey) {
      this.paymentKey.setValue(changes.paymentApiKey.currentValue);
    }
  }

  isExcluded(propertyName) {
    return this.excludedParams.includes(propertyName);
  }

  getRelatedEntityValue(
    attributeValues: PaymentRelatedValue[],
    key: string
  ): PaymentRelatedValue {
    let paymentValue;

    if (attributeValues) {
      paymentValue = attributeValues?.find((attr) => attr.propertyName === key);
    }

    return paymentValue ? paymentValue : null;
  }

  hasParams(key: string): boolean {
    return this.paymentParams.find((p) => p.name === key);
  }

  onPaymentParamCheck(
    checked: boolean,
    attribute: AttributeDescription,
    relatedValue: PaymentRelatedValue
  ) {
    const { propertyName, displayName } = attribute;
    const alias = this.paymentForm.get(propertyName)?.value;
    if (checked) {
      const sampleData = this.getSampleData(relatedValue.sampleData);
      this.addPaymentParam(
        relatedValue.propertyName,
        displayName,
        alias ? alias : displayName,
        sampleData
      );
    } else {
      this.removePaymentParam(displayName, propertyName);
    }

    this.updatePaymentUrl();
  }

  checkAllParamByDefault(attributeDescriptions: AttributeDescription[]): void {
    const filteredAttributes = attributeDescriptions.filter(
      (attr) => !['PaymentLink', 'PaymentApiKey'].includes(attr.propertyName)
    );

    filteredAttributes.forEach((attr) => {
      const relatedValue = this.getRelatedEntityValue(
        this.attributeDescription.relatedEntityDefaultValues,
        attr.propertyName
      );
      this.onPaymentParamCheck(true, attr, relatedValue);
    });
  }

  getSampleData(sampleData: string, formValue = {}) {
    return this.stringReplacement.transform(sampleData, {
      '{eventName}': this.eventName ? this.eventName : '{eventName}',
      '{userId}': this.userId,
      '{eventId}': this.id ? this.id.toString() : '{eventId}',
      '{eventPrice}': this.eventPrice ? this.eventPrice : '',
      '{currency}': this.eventCurrency ? this.eventCurrency.value : '',
      '{gender}':
        this.sessionService.currentUser?.gender === 'Male'
          ? 'Mr'
          : this.sessionService.currentUser?.gender === 'Female'
          ? 'Ms'
          : '',
      '{firstName}': this.sessionService.currentUser?.firstName,
      '{lastName}': this.sessionService.currentUser?.lastName,
      '{email}': this.sessionService.currentUser?.email,
      '{phone}': this.sessionService.currentUser?.phone,
    });
  }

  onPaymentParamAliasChange(e: any, name: string) {
    const param = this.paymentParams.find((p) => p.name === name);

    if (param) {
      param.alias = e.target.value;
    }

    this.updatePaymentUrl();
  }

  onAdvanceSettingClick() {
    if (!this.isCreating) {
      if (!this.isAdvancedSettingOpen) {
        this.loadPaymentSetting();
      }

      if (this.entity.leadCompany) {
        this.paymentKey.setValue(this.paymentApiKey);
      }
    } else {
      this.toggleSetting();
    }
  }

  toggleSetting(): void {
    this.isAdvancedSettingOpen = !this.isAdvancedSettingOpen;
  }

  toggleEditParameter(): void {
    this.isEditParameterOn = !this.isEditParameterOn;
  }

  getPaymentDto() {
    const paymentLink = {
      propertyName: 'PaymentLink',
      value: this.paymentForm.get('PaymentLink')?.value,
    };
    const paymentKey = {
      propertyName: 'PaymentApiKey',
      value: this.paymentForm.get('PaymentApiKey')?.value,
    };
    const dto = this.paymentParams.map(({ propertyName, name, alias }) => ({
      propertyName: propertyName,
      value: name,
      alias: alias !== name ? alias : null,
    }));

    return this.paymentLink.value
      ? {
          payment: this.paymentForm.valid
            ? [paymentLink, paymentKey, ...dto]
            : [],
        }
      : {};
  }

  onTestLink() {
    window.open(this.paymentUrl, '_blank');
  }

  generatePaymentForm() {
    let controls = {};
    this.attributeDescription =
      this.customFormService.getFieldOptions('Payment');
    this.attributeDescription.relatedEntityAttributeDescriptions.forEach(
      (attr) => {
        controls[attr.propertyName] = new FormControl('');
      }
    );
    this.paymentForm = this.fb.group(controls);

    if (this.isCreating) {
      this.checkAllParamByDefault(
        this.attributeDescription.relatedEntityAttributeDescriptions
      );
    }
  }

  updateFormControlValue(item: any) {
    let controlValue;
    const { relatedEntityAttributeDescriptions, relatedEntityDefaultValues } =
      this.attributeDescription;
    const attribute = relatedEntityAttributeDescriptions.find(
      (attr) => attr.propertyName === item.propertyName
    );
    const attributeDefaultValue = this.getRelatedEntityValue(
      relatedEntityDefaultValues,
      item.propertyName
    );
    const { propertyName, value } = item;

    if (propertyName === 'PaymentLink') {
      controlValue = value;
      this.hasPaymentToken = true;
    } else if (propertyName === 'PaymentApiKey') {
      controlValue = value;
    } else {
      if (attribute.displayName === item.alias) {
        controlValue = '';
      } else {
        controlValue = item.alias;
      }

      const paramObject = {
        propertyName: item.propertyName,
        name: attribute.displayName,
        alias:
          item.alias === attribute.displayName
            ? attribute.displayName
            : item.alias,
        value: this.getSampleData(attributeDefaultValue.sampleData),
      };

      const paramIdx = this.paymentParams.findIndex(
        (p) => p.propertyName === paramObject.propertyName
      );

      if (paramIdx > 0) {
        this.paymentParams[paramIdx] = paramObject;
      } else {
        this.paymentParams.push(paramObject);
      }
    }

    this.paymentForm.patchValue({
      [item.propertyName]: controlValue,
    });
  }

  updatePaymentUrl() {
    let paymentLink = this.paymentForm.get('PaymentLink')?.value;

    if (!paymentLink) {
      paymentLink = window.location.origin;
    }
    const urlObj = new URL(paymentLink);
    const searchParams = new URLSearchParams(urlObj.search);

    this.paymentParams.forEach((p) => {
      if (p.alias) {
        searchParams.set(p.alias, p.value);
      } else {
        searchParams.set(p.name, p.value);
      }
    });

    urlObj.search = searchParams.toString();
    this.paymentUrl = urlObj.toString();
  }

  addPaymentParam(
    propertyName: string,
    displayName: string,
    alias: string,
    sampleData: string
  ): void {
    const paramObject = {
      propertyName,
      name: displayName,
      alias,
      value: sampleData,
    };

    this.paymentParams.push(paramObject);
  }

  removePaymentParam(displayName: string, propertyName: string): void {
    this.paymentParams = this.paymentParams.filter(
      (p) => p.name !== displayName
    );
  }

  loadPaymentSetting() {
    this.isLoadingPaymentInfo = true;
    this.httpService.getPaymentInfo(this.id).subscribe((res: any) => {
      this.isLoadingPaymentInfo = false;
      if (!res) {
        this.toggleSetting();
        this.checkAllParamByDefault(
          this.attributeDescription.relatedEntityAttributeDescriptions
        );
        return;
      }
      this.isAdvancedSettingOpen = !this.isAdvancedSettingOpen;
      res.payment.forEach((item) => {
        this.updateFormControlValue(item);
      });

      this.updatePaymentUrl();
    });
  }

  paymentKeyRequiredValidator(): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const paymentLinkControl = formGroup.get('PaymentLink');
      const paymentKeyControl = formGroup.get('PaymentKey');

      if (paymentLinkControl?.value && !paymentKeyControl?.value) {
        return { paymentKeyRequired: true };
      }

      return null;
    };
  }

  copyPaymentApiKey(): void {
    const paymentApiKey = this.paymentForm.get('PaymentApiKey')?.value;
    copyToClipboard(paymentApiKey, () => {
      this.isPaymentApiKeyCopied = true;

      setTimeout(() => {
        this.isPaymentApiKeyCopied = false;
      }, 700);
    });
  }
}
