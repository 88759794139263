import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChallengeHttpService } from '@src/app/core/http/challenge-http.service';
import { OrganizationHttpService } from '@src/app/core/http/organization-http.service';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { LeadCompany } from '@src/app/shared/interfaces/lead-company.interface';
import { OrganizationSettingInterface } from '@src/app/shared/interfaces/organization-setting.interface';
import { PartnerOrganizationSettingService } from '@src/app/shared/services/partner-organization-setting.service';

export enum PartnerOrgSetting {
  ProjectIdeas = 'project-ideas',
  People = 'people',
  CustomAttributes = 'custom-attributes',
  Events = 'events'
}
@Component({
  selector: 'app-organization-setting-dialog',
  templateUrl: './organization-setting-dialog.component.html',
})
export class OrganizationSettingDialogComponent implements OnInit, OnDestroy {
  @Input() organization: LeadCompany = {};
  @Input() leadOrg: LeadCompany;
  setting: OrganizationSettingInterface;
  SettingEnum = PartnerOrgSetting;
  isSettingLoading = false;

  constructor(
    public activeModal: NgbActiveModal,
    private partnerOrgSettingService: PartnerOrganizationSettingService,
    private orgHttpService: OrganizationHttpService,
    private challengeHttpService: ChallengeHttpService
  ) {}

  ngOnInit(): void {
    this.setting = {
      partnerOrgId: this.organization.id,
      showMember: this.organization.showMember,
      showProjectIdeas: this.organization.showVentures,
      showCustomAttribute: this.organization.showCustomAttribute,
    };
  }

  onSettingChange(result: boolean, setting: string) {
    switch (setting) {
      case this.SettingEnum.ProjectIdeas:
        this.setting = { ...this.setting, showProjectIdeas: result };
        delete this.setting.showCustomAttribute;
        break;
      case this.SettingEnum.People:
        this.setting = { ...this.setting, showMember: result };
        delete this.setting.showCustomAttribute;
        break;
      case this.SettingEnum.Events:
        this.setting = { ...this.setting, showEvent: result };
        delete this.setting.showCustomAttribute;
        break;
      case this.SettingEnum.CustomAttributes:
        this.setting = {
          partnerOrgId: this.organization.id,
          showCustomAttribute: result,
        };
        break;
      default:
        break;
    }

    this.updateSetting(this.setting)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.partnerOrgSettingService.orgSetting$.next(setting);
      });
  }

  updateSetting(setting: OrganizationSettingInterface) {
    const apiUrl = `/partner-org-settings`;

    if (this.leadOrg.showAsChallenge) {
      return this.challengeHttpService.postByEntity(
        this.leadOrg.id,
        setting,
        apiUrl
      );
    }
    return this.orgHttpService.postByEntity(this.leadOrg.id, setting, apiUrl);
  }

  ngOnDestroy(): void {
    /** */
  }
}
