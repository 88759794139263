import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { ChallengeHttpService } from '@core/http/challenge-http.service';
import { OrganizationHttpService } from '@core/http/organization-http.service';
import { CentralConfigService } from '@core/services/central-config.service';
import { FactoryService } from '@core/services/factory.service';
import { GlobalFilterStoredService } from '@core/services/global-filter-stored.service';
import { SessionService } from '@core/session.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ToBoolean } from '@shared/decorators/to-boolean';
import { EntityName } from '@shared/enums/entity-name.enum';
import { HttpStatusCode } from '@shared/enums/httpstatuscode.enum';
import { StorageEnum } from '@shared/enums/storage.enum';
import { WINDOW } from '@shared/helpers/window.token';
import { GlobalFilterCriteria } from '@shared/interfaces/filters/global-filter.interface';
import { Entity } from '@shared/models/entity.model';
import { UserConfigManagementService } from '@shared/services/user-config-management.service';
import { SearchChannelsService } from '@src/app/components/master-layout/navs/components/thread-filter/services/search-channels.service';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { CommunitySelectorStoredService } from '@src/app/core/services/community-selector-stored.service';
import { GlobalEventBus } from '@src/app/shared/enums/event-bus.enum';
import {
  IconType,
  InternalIcon,
} from '@src/app/shared/enums/internal-icon.enum';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import {
  OrganizationFilter,
  OrganizationInterface,
} from '@src/app/shared/interfaces/organization.interface';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { environment } from '@src/environments/environment';
import { isMobile } from 'detect-touch-device';
import { EventBusService } from 'ngx-eventbus';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';

@Component({
  selector: 'app-global-filter',
  templateUrl: './global-filter.component.html',
})
export class GlobalFilterComponent implements OnInit, OnDestroy {
  @Input() isMobileFilterMode = false;

  @Output() filterPanelClosed = new EventEmitter();

  @Input() @ToBoolean() standalone_widget_mode = false;

  @Input() @ToBoolean() hide_innovation_space = false;

  @Input() showSearchBar: boolean;

  @Input() showActiveInnovationSpaceImg: boolean;

  @Input() isShowSearch = false;

  dropdown: NgbDropdown;

  @ViewChildren(NgbDropdown)
  dropdowns: QueryList<NgbDropdown>;
  //#region HANDLE FILTER PANE
  dropDownPlacement = 'bottom-left';

  globalPaneEvent: any;

  isLogin$: Observable<boolean>;

  isMyInvolvement = false;
  isMyOrgs = false;
  organizationId: number = null;

  orgFilterList: OrganizationFilter[] = [];
  orgUrl = environment.jipUrl.organizations;

  searchKeyword = '';

  threadId: number = null;
  threadEntity = '';

  InternalIcon = InternalIcon;
  IconType = IconType;

  isTouchDevice: boolean = isMobile;
  dropDownOffsetX = '';

  centralConfig: CentralConfigService;
  protected filterStoredService: GlobalFilterStoredService;

  allInnovation =
    !this.organizationId && !this.isMyOrgs && !this.isMyInvolvement;

  popularOrgs: (OrganizationFilter & OrganizationInterface)[] = [];

  orgFilterList$ = new BehaviorSubject<OrganizationFilter[]>([]);

  get hasFilters(): boolean {
    return this.isMyInvolvement || this.isMyOrgs || !!this.organizationId;
  }

  get selectedOrg(): OrganizationFilter {
    if (this.organizationId) {
      const org = this.orgFilterList?.find((x) => x.id === this.organizationId);
      if (org) {
        this.orgUrl = org.isChallenge
          ? environment.jipUrl.challenges
          : environment.jipUrl.organizations;
      }
      return org;
    }
  }

  get tenantLogo(): string {
    return this.sessionService.getTenantLogo();
  }

  get windowOrigin(): string {
    return window.origin;
  }

  get noFilterApplied(): boolean {
    return !this.isMyInvolvement && !this.isMyOrgs && !this.organizationId;
  }

  @ViewChild('dropdownRef', { static: false }) set content(
    content: ElementRef
  ) {
    if (content?.nativeElement) {
      const ele = document.getElementById('filterCollapse')?.parentElement;

      const dropDownOffsetX =
        ele?.getBoundingClientRect().right - window.innerWidth / 2;

      this.dropDownOffsetX = `translateX(-${Math.round(dropDownOffsetX)}px)`;
    }
  }

  orgService: BaseHttpService<Entity> = null;

  constructor(
    @Inject(WINDOW) protected window: Window,
    public sessionService: SessionService,
    protected eventBus: EventBusService,
    protected authService: AuthenService,
    protected factoryService: FactoryService,
    protected userConfig: UserConfigManagementService,
    protected readonly renderer: Renderer2,
    protected readonly injector: Injector,
    protected searchChannel: SearchChannelsService,
    protected orgHttpService: OrganizationHttpService,
    protected communitySelectorStored: CommunitySelectorStoredService
  ) {
    this.filterStoredService = this.injector.get<GlobalFilterStoredService>(
      GlobalFilterStoredService
    );

    this.centralConfig = this.injector.get(CentralConfigService);

    this.initOrgService();
    this.sessionService.isHeaderVisible = true;
  }

  async ngOnInit(): Promise<void> {
    this.listenFilterPaneEvent();

    this.isLogin$ = this.authService.isLogin();

    const persistedFilters = await this.filterStoredService.getFilterCriteria();
    const isInitSearchAll = this.isInitSearchAll();
    const shouldKeepSearchKeyword =
      location.pathname === persistedFilters.filteringPath || isInitSearchAll;

    this.isMyInvolvement = persistedFilters.isMyInvolvement;
    this.isMyOrgs = persistedFilters.isMyOrgs;
    this.threadId = persistedFilters.threadId;
    this.threadEntity = persistedFilters.threadEntity;

    if (this.centralConfig.innovationSpaceId) {
      this.organizationId = this.centralConfig.innovationSpaceId;

      this.filterStoredService.appendFilterTokenOnUrl([this.organizationId]);
    } else if (persistedFilters.organizationIds) {
      this.organizationId = persistedFilters.organizationIds[0];
    }

    // If reload the same page => keep display search keyword in search box
    this.searchKeyword = shouldKeepSearchKeyword
      ? persistedFilters.keyword ?? ''
      : this.searchKeyword;

    isInitSearchAll && (await this.saveFilterCriteria());

    await this.handleFilterTokenFromUrl();

    this.listenAddOrgToTokenEvent();

    this.filterStoredService.setCurrentFilterToken(this.organizationId);
    this.handleListenToTopicFilterChange();
    this.communitySelectorStored.setSelectedOrg(this.selectedOrg);

    if (shouldKeepSearchKeyword) {
      this.communitySelectorStored.setKeyword(
        persistedFilters.keyword ?? ''
      );
    }
  }

  isInitSearchAll(): boolean {
    const { searchResults } = environment.jipUrl;
    const isNewSearch = !!localStorage.getItem(StorageEnum.isNewSearch);

    localStorage.removeItem(StorageEnum.isNewSearch);

    return location.pathname.includes(searchResults) && isNewSearch;
  }

  async filterMyEnvolvement(): Promise<void> {
    if (this.isMyInvolvement) {
      this.isMyOrgs = false;
      this.organizationId = null;
    }

    const filterCriteria = await this.saveFilterCriteria();
    this.afterFilterSelection(filterCriteria);
  }

  async filterMyOrgs(): Promise<void> {
    if (this.isMyOrgs) {
      this.isMyInvolvement = false;
      this.organizationId = null;
    }

    const filterCriteria = await this.saveFilterCriteria();
    this.afterFilterSelection(filterCriteria);
  }

  async filterOrganization(
    orgId: number,
    isNewFilterToken = false,
    threadId?: number
  ): Promise<void> {
    if (orgId) {
      if (!isNewFilterToken) {
        this.updateOrgFilterTokenToLatest(orgId);
      }

      if (
        this.organizationId === orgId &&
        !this.centralConfig.innovationSpaceId
      ) {
        this.organizationId = null;
        StringUtils.removeParamFromUrl(UrlParam.SubOrganizationId);
      } else {
        this.organizationId = orgId;
      }
      this.isMyInvolvement = false;
      this.isMyOrgs = false;
    }

    this.threadId = null;
    this.threadEntity = '';

    const filterCriteria = await this.saveFilterCriteria();
    this.afterFilterSelection(filterCriteria);
    this.communitySelectorStored.setSelectedOrg(this.selectedOrg);
  }

  async search(): Promise<void> {
    const persistedFilters = await this.filterStoredService.getFilterCriteria();
    // don't search if keyword is same as before
    if (
      persistedFilters.keyword === this.searchKeyword &&
      location.pathname === persistedFilters.filteringPath &&
      location.pathname.includes(environment.jipUrl.searchResults)
    ) {
      return;
    }

    const filterCriteria = await this.saveFilterCriteria();
    filterCriteria.keyword = this.searchKeyword;

    this.triggerGlobalFilterEvent(filterCriteria, true);
  }

  resetSearch(): void {
    this.searchKeyword = '';
    this.search();
  }

  //#endregion End Handle Filter Pane

  closeFilters(): void {
    this.isMobileFilterMode = false;
    this.filterPanelClosed.emit(false);
  }

  //#region HANDLE FILTER TOKEN
  async handleFilterTokenFromUrl(): Promise<void> {
    const currentOrgFilter = await this.userConfig.get(
      StorageEnum.orgFilterToken
    );

    if (currentOrgFilter) {
      this.orgFilterList = currentOrgFilter;
      this.orgFilterList$.next(this.orgFilterList);
    }

    const organizationId = StringUtils.getParamFromUrl(UrlParam.FilterTokenId);

    const activeOrg = this.orgFilterList.find(
      (org) => org.id === +organizationId
    );

    if (
      activeOrg &&
      !this.sessionService.isLogin &&
      activeOrg.tenantId !== +this.sessionService.getTenantId() &&
      !this.centralConfig.innovationSpaceId
    ) {
      this.organizationId = null;
      StringUtils.removeParamFromUrl(UrlParam.FilterTokenId);
      return this.filterMyEnvolvement();
    }

    if (
      (organizationId || this.organizationId) &&
      (this.sessionService.isOnListPage || this.sessionService.isOnHomePage)
    ) {
      await this.handleSubOrgFromUrl();
    } else {
      StringUtils.removeParamFromUrl(UrlParam.SubOrganizationId);
    }

    if (organizationId) {
      const orgId = Number(organizationId);
      if (isNaN(orgId)) {
        return;
      }

      await this.updateFilterTokenStatus(orgId);
    } else if (this.organizationId) {
      this.filterStoredService.appendFilterTokenOnUrl([this.organizationId]);
    }
  }

  handleFilterTokenFromOutside(): Observable<number> {
    return this.filterStoredService
      .getFilterTokenAddedFromOutside()
      .pipe(filter(Boolean), untilDestroyed(this));
  }

  getFilterTokenUrl(orgId: number): string {
    return (
      window.origin +
      this.sessionService.appendLanguagePath(
        `${environment.jipUrl.organizations}/${orgId}`
      )
    );
  }

  addNewOrgFilterToken(newOrgId: number): void {
    const challengeService = this.injector.get(ChallengeHttpService);
    const orgService = this.injector.get(OrganizationHttpService);

    // Note: The backend has separate APIs for 'challenge' and 'organization'. Due to the
    // indistinguishable nature of entities (organization or challenge) before receiving the response,
    // a retry mechanism is implemented for both APIs.
    const handleReadError = (err: any) => {
      if (err.status === HttpStatusCode.NotFound) {
        return challengeService.read(newOrgId);
      }
      return throwError(() => new Error(err));
    };

    this.sessionService.apiReady$
      .pipe(untilDestroyed(this))
      .subscribe((apiReady) => {
        if (apiReady) {
          orgService
            .read(newOrgId)
            .pipe(catchError(handleReadError), untilDestroyed(this))
            .subscribe({
              next: async (org: OrganizationInterface) => {
                if (org) {
                  const orgObj: OrganizationFilter = {
                    id: org.id,
                    name: org.orgName,
                    imgUrl: org.logo?.url || org.logo,
                    isChallenge: org.showAsChallenge,
                    tenantId: +this.sessionService.getTenantId(),
                  };

                  this.orgFilterList.push(orgObj);
                  this.orgFilterList$.next(this.orgFilterList);

                  await this.filterOrganization(orgObj.id, true);
                  await this.setFilterTokenStorage();
                }
              },
              error: (err) => {
                console.log(err);
              },
            });
        }
      });
  }

  updateOrgFilterTokenToLatest(orgId: number): void {
    this.sessionService.apiReady$
      .pipe(untilDestroyed(this))
      .subscribe((apiReady) => {
        if (apiReady) {
          this.orgService
            .read(orgId)
            .pipe(untilDestroyed(this))
            .subscribe({
              next: async (org: OrganizationInterface) => {
                if (org) {
                  const orgObj: OrganizationFilter = {
                    id: org.id,
                    name: org.orgName,
                    imgUrl: org.logo?.url || org.logo,
                    isChallenge: org.showAsChallenge,
                    tenantId: +this.sessionService.getTenantId(),
                  };
                  const updateOrgIndex = this.orgFilterList.findIndex(
                    (obj) => obj.id === orgId
                  );
                  if (updateOrgIndex !== null && updateOrgIndex !== undefined) {
                    this.orgFilterList[updateOrgIndex] = orgObj;
                    this.orgFilterList$.next(this.orgFilterList);
                    await this.setFilterTokenStorage();
                  }
                }
              },
              error: (err) => {
                console.log(err);
              },
            });
        }
      });
  }

  async setFilterTokenStorage(): Promise<void> {
    await this.userConfig.set(StorageEnum.orgFilterToken, this.orgFilterList);
  }

  onClickItem(id: string): void {
    document.getElementById(id).click();
    this.dropdown?.close();
  }

  ngOnDestroy(): void {
    this.eventBus.removeEventListener(this.globalPaneEvent);
  }

  positioningDropdown(event: boolean): void {
    if (!event || this.dropDownPlacement !== 'bottom') return;

    setTimeout(() => {
      const ele = document.getElementsByClassName('dropdown-menu show')[0];

      this.renderer.setStyle(ele, 'transform', this.dropDownOffsetX);
      this.renderer.setStyle(ele, '-webkit-transition', '0.1s ease-in-out');
      this.renderer.setStyle(ele, '-moz-transition', '0.1s ease-in-out');
      this.renderer.setStyle(ele, '-o-transition', '0.1s ease-in-out');
    });
  }

  checkDropDown(dropdown: any): void {
    this.dropdown = this.dropdowns.find(
      (x) => (x as any)._elementRef.nativeElement === dropdown
    );
  }

  selectAll(organizationId: number, event: Event): void {
    if (this.organizationId) {
      this.filterOrganization(organizationId);
    } else if (this.isMyOrgs || this.isMyInvolvement) {
      this.isMyOrgs = false;
      this.isMyInvolvement = false;

      this.filterMyEnvolvement();
    } else {
      event.preventDefault();
    }
  }

  async removeItem(orgFilter: OrganizationFilter): Promise<void> {
    if (this.organizationId === orgFilter.id) {
      await this.filterOrganization(orgFilter.id);
    }

    this.orgFilterList = this.orgFilterList.filter(
      (org) => org.id !== orgFilter.id
    );
    this.orgFilterList$.next(this.orgFilterList);

    this.setFilterTokenStorage().then();
  }

  private afterFilterSelection(filterCriteria: GlobalFilterCriteria): void {
    this.triggerGlobalFilterSelectionEvent(filterCriteria);
    this.triggerGlobalFilterEvent(filterCriteria);
  }

  private listenFilterPaneEvent(): void {
    this.globalPaneEvent = this.eventBus.addEventListener({
      name: GlobalEventBus.FilterPaneEvent,
      callback: async ({ filterPaneOnly, isFromFilterBtn }) => {
        const filterCriteria = await this.saveFilterCriteria();
        const subOrgFromUrlId = StringUtils.getParamFromUrl(
          UrlParam.SubOrganizationId
        );
        if (subOrgFromUrlId) {
          filterCriteria.threadId = +subOrgFromUrlId;
        }
        filterCriteria.keyword = this.searchKeyword;
        filterCriteria.fromFilterPane = true;
        this.triggerGlobalFilterEvent(
          filterCriteria,
          false,
          filterPaneOnly,
          isFromFilterBtn
        );
      },
    });
  }

  private async updateFilterTokenStatus(orgId: number): Promise<void> {
    if (orgId > 0) {
      if (
        (this.orgFilterList.length > 0 &&
          this.orgFilterList.map((x) => x.id).includes(orgId)) ||
        (this.popularOrgs.length > 0 &&
          this.popularOrgs.map((x) => x.id).includes(orgId))
      ) {
        if (this.organizationId !== orgId) {
          await this.filterOrganization(orgId);
        }
      } else {
        this.addNewOrgFilterToken(orgId);
      }
    }
  }

  private async saveFilterCriteria(): Promise<GlobalFilterCriteria> {
    return this.filterStoredService.saveFilterCriteria(
      this.searchKeyword,
      this.isMyInvolvement,
      this.isMyOrgs,
      location.pathname,
      this.organizationId,
      this.threadId,
      this.threadEntity
    );
  }

  private triggerGlobalFilterEvent(
    filterCriteria: GlobalFilterCriteria,
    isManualSearch = false,
    filterPaneOnly = false,
    isFromFilterBtn = false
  ): void {
    // TODO: Should remove EventBusService
    this.filterStoredService.setCurrentFilterToken(
      filterCriteria.organizationIds[0]
    );

    const entityName = this.getEntitySearchTab();
    const searchResultsPath = environment.jipUrl.searchResults;

    if (!window.location.href.includes(searchResultsPath) && isManualSearch) {
      localStorage.setItem(StorageEnum.isNewSearch, 'true');

      setTimeout(() => {
        window.location.href = this.sessionService.appendLanguagePath(
          searchResultsPath + `/?${UrlParam.GlobalSearchTab}=${entityName}`
        );
      });

      return;
    }

    this.eventBus.triggerEvent(GlobalEventBus.GlobalFilterEvent, {
      filterCriteria,
      filterPaneOnly,
      isFromFilterBtn,
    });
  }

  private listenAddOrgToTokenEvent(): void {
    this.handleFilterTokenFromOutside()
      .pipe(untilDestroyed(this))
      .subscribe(async (orgId: number) => {
        await this.updateFilterTokenStatus(orgId);

        const handledOrgIds = this.organizationId ? [this.organizationId] : [];

        this.filterStoredService.appendFilterTokenOnUrl(handledOrgIds);
      });
  }

  //#endregion End Handle Filter Token
  private triggerGlobalFilterSelectionEvent(
    filterCriteria: GlobalFilterCriteria
  ): void {
    this.eventBus.triggerEvent(
      GlobalEventBus.GlobalFilterSelectionEvent,
      filterCriteria
    );
  }

  private initOrgService(): void {
    const isOrganization = this.window.location.pathname.includes(
      environment.jipUrl.organizations
    );
    const entityName = isOrganization
      ? EntityName.Organization
      : EntityName.Challenge;

    this.orgService = this.factoryService.createServiceByEntityName(entityName);
  }

  private handleListenToTopicFilterChange() {
    this.filterStoredService
      .getTopicFilter()
      .pipe(untilDestroyed(this))
      .subscribe((res: { threadId: number; threadEntity: string }) => {
        this.threadId = res.threadId;
        this.threadEntity = res.threadEntity;
      });
  }

  private getEntitySearchTab(): string {
    const isOnHomePage = this.sessionService.isOnHomePage;
    const entityName = isOnHomePage
      ? EntityName.News
      : StringUtils.getEntityNameByUrl(window.location.href);
    const currentSearchEntity = StringUtils.getParamFromUrl(
      UrlParam.GlobalSearchTab
    );

    return currentSearchEntity || entityName;
  }

  async handleSubOrgFromUrl(): Promise<void> {
    const subOrgFromUrlId = StringUtils.getParamFromUrl(
      UrlParam.SubOrganizationId
    );

    if (!subOrgFromUrlId && !this.sessionService.hasTopicFilter) {
      await this.searchChannel.resetChannel();
    }

    const currentSubOrg = await this.userConfig.get(
      StorageEnum.activeFilterChannel
    );

    if (subOrgFromUrlId || currentSubOrg) {
      const orgId = +subOrgFromUrlId || currentSubOrg.id;

      if (currentSubOrg && currentSubOrg.entityName === EntityName.Event)
        return;

      this.orgHttpService
        .read(orgId)
        .pipe(untilDestroyed(this))
        .subscribe((org: OrganizationInterface) => {
          const activeOrg = {
            displayName: org.orgName,
            entityLogo: 'organisation',
            entityName: EntityName.Organization,
            id: org.id,
            image: org.logo,
          };

          this.searchChannel.onSelectChannel(activeOrg);

          if (this.sessionService.hasTopicFilter) {
            this.searchChannel.activeFilter$.next(activeOrg);
          }
        });
    }
  }

  shouldShowFilterToken(token: OrganizationFilter): boolean {
    if (this.sessionService.isLogin) return true;

    return token.tenantId === +this.sessionService.getTenantId();
  }

  navigateToActiveInnovationSpaceDetail(id = null) {
    if (id) {
      FormUtils.navigateToWithLanguagePath(
        this.sessionService,
        this.orgUrl + '/' + id
      );
    } else {
      FormUtils.navigateToWithLanguagePath(this.sessionService, '/');
    }
  }

  toggleSearch() {
    this.isShowSearch = !this.isShowSearch;
  }
}
