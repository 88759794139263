<div>
  <div
    *ngIf="showSearchButton && items?.length > 0"
    class="people-cards__search"
  >
    <div class="input-group flex-nowrap">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1"
          ><i class="fas fa-search"></i
        ></span>
      </div>
      <input
        class="people-cards__search-btn"
        [placeholder]="'Search' | translate"
        [ngModel]="searchName"
        (ngModelChange)="setDisplayItems($event)"
      />
    </div>
  </div>

  <div
    *ngIf="allowAddPeople !== undefined ? allowAddPeople : editable"
    class="people-cards__search"
  >
    <div class="input-group flex-nowrap">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1"
          ><i
            class="fas"
            [ngClass]="peopleSearching ? 'fa-spinner fa-spin' : 'fa-user'"
          ></i
        ></span>
      </div>

      <input
        class="people-cards__search-btn flex-grow-1 flex-shrink-1"
        [placeholder]="'UI.Label.AddTeamMember' | translate"
        [(ngModel)]="selectedPeople"
        [ngbTypeahead]="peopleSearch"
        [inputFormatter]="peopleInputFormatter"
        (selectItem)="onSelect($event, searchName)"
        [resultTemplate]="customItemTemplate"
        container="body"
        #searchName
      />

      <div>
        <button
          *ngIf="showAddButton"
          type="button"
          (click)="addPeople()"
          class="btn btn-primary widget__btn-create ml-1 ml-md-4"
        >
          <i class="fas fa-plus fa-lg"></i>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="peopleSearchFailed" class="invalid-feedback d-block">
    Sorry, suggestions could not be loaded.
  </div>

  @if(displayItems){
    @if(!useOrgAdminTemplate) {
      <app-people-card-list
        (extraActionClicked)="extraActionClicked.emit($event)"
        (peopleRemoved)="removeItemClicked.emit($event)"
        (peopleRoleChanged)="peopleRoleChanged.emit($event)"
        (peopleCardClicked)="onPeopleCardClicked()"
        [allowRemovePeople]="allowRemovePeople"
        [customUserRoles]="customUserRoles"
        [displayItems]="pagingDisplayItems"
        [editable]="editable"
        [emptyMessage]="emptyMessage"
        [enableExtraAction]="enableExtraAction"
        [enableInteractionBar]="true"
        [extraActions]="extraActions"
        [globalOrgId]="globalOrgId"
        [cardType]="cardType"
        [hideProfileImage]="hideProfileImage"
        [showInteractionBar]="showInteractionBar"
      ></app-people-card-list>
    } @else {
      <app-people-card-list-org-admin
        (extraActionClicked)="extraActionClicked.emit($event)"
        (peopleRemoved)="removeItemClicked.emit($event)"
        (peopleRoleChanged)="peopleRoleChanged.emit($event)"
        (peopleCardClicked)="onPeopleCardClicked()"
        [allowRemovePeople]="allowRemovePeople"
        [customUserRoles]="customUserRoles"
        [displayItems]="pagingDisplayItems"
        [editable]="editable"
        [emptyMessage]="emptyMessage"
        [enableExtraAction]="enableExtraAction"
        [enableInteractionBar]="true"
        [extraActions]="extraActions"
        [globalOrgId]="globalOrgId"
        [cardType]="cardType"
      ></app-people-card-list-org-admin>
    }
  }

  <app-bootstrap-pagination
    *ngIf="enablePagination"
    [page]="page"
    [pageSize]="pageSize"
    [collectionSize]="collectionSize"
    (changePageSize)="pageSize = $event; onPaging()"
    (changePage)="page = $event; onPaging()"
  ></app-bootstrap-pagination>
</div>

<ng-template #customItemTemplate let-r="result" let-t="term" let-index="index">
  <div appRequireLogin class="d-flex align-items-center">
    <div class="avatar-small mr-3">
      <div class="avatar-wrapper">
        <img
          class="people__avatar widget__avatar-image"
          [src]="r.image"
          appDefaultAvatar
          appDynamicImgUrl
          alt=""
        />
      </div>
    </div>

    <ngb-highlight
      result="{{ r.firstName }} {{ r.lastName }} ({{ r.email }})"
      [term]="t"
    ></ngb-highlight>
  </div>
</ng-template>