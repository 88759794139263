<ng-container [ngSwitch]="styleMode">
  <ng-container *ngSwitchCase="styleModeEnum.Detail">
    <button
      class="btn btn-timestamp form-section__btn m-0 d-flex align-items-center"
      (click)="$event.stopPropagation(); openCreateTimestamp()"
    >
      <img
        appDefaultPlaceholder
        appDynamicImgUrl
        [src]="timestampIcon"
        class="icon-img mr-2"
      />
      <span translate>UI.Ventures.Timestamp</span>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="styleModeEnum.Table">
    <a
      class="link-unstyled w-100 p-2"
      (click)="$event.stopPropagation(); openCreateTimestamp()"
    >
      <img
        appDefaultPlaceholder
        appDynamicImgUrl
        [src]="timestampIcon"
        class="btn-timestamp-icon-table"
      />
      <span class="venture__actions-label font-weight-bold" translate>
        UI.Ventures.Timestamp
      </span>
    </a>
  </ng-container>
</ng-container>
