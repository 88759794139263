// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const defaultEnvironment = {
  apiVersion: 1,
  apiUrl: 'https://jip-dev-api.bbv-demo.ch/api/',
  production: false,
  demo: false,
  test: false,
  dev: false,
  integration: false,
  // defaultLanguage: 'en_GB',
  inMemoryApi: false,
  wordpressJipImages: '/wp-content/themes/jip/assets/images/',
  // supportedLanguages: [
  //   { locale: 'de_CH', code: 'de', name: 'Deutsch' },
  //   { locale: 'en_GB', code: 'en', name: 'English' },
  // ],
  loginFormLimit: {
    usernameMinLength: 6,
    passwordMinLength: 8,
  },
  googleMapApiKey: 'AIzaSyAWkLdrWs6HYA6wUKpNjz88V1igWXeLy-0',
  google_reCAPTCHA_siteKey: '6LdU-fcmAAAAAEtTTvYzocT9yVSx43_nxEz3tjRi',
  jipUrl: {
    login: '/jip-login',
    venture: '/ventures',
    event: '/events',
    profile: '/my-profile',
    people: '/people',
    organizations: '/organizations',
    challenges: '/challenges',
    comments: '/comments',
    stakeholders: '/stakeholders',
    notifications: '/news-and-messages',
    processToken: '/process-token',
    changePassword: '/change-password',
    changeEmail: '/change-email',
    sharing: '/sharing',
    deleted: '/deleted',
    adminSettings: '/admin-settings',
    searchResults: '/search-results',
    eventRegister: '/event-registration',
    eventRegisterForm: '/event-registration-form',
    news: '/news',
    messages: '/messages',
    home: '/',
    kpi: '/kpi',
    termsOfUse: '/terms-of-use',
    clientLandingPage: '/community-solutions'
  },
  maxUploadSize: 20 * 1024 * 1024, // MB
  termOfUseUrl: 'https://home.jointcreate.com/terms-of-use',
  portalName: 'jointcreate.com',
  portalUrl: 'https://www.jointcreate.com',
  linkedinConfig: {
    clientId: '78gcysj07rkflu',
    pathName: 'https://www.linkedin.com/oauth/v2/authorization',
  },
};
