<div *ngIf="showLabel" class="mb-2 pb-1 widget__label" translate>
  {{ attributeDescription.displayName }}
</div>
<p>
  With this function, we provide your organization 10 Stream channels (with a
  default Stream channel) where you can create posts with a specification
  subject. You can rename and set any Streams channel you want to show.
</p>
<form [formGroup]="streamForm">
  <div class="row mt-3">
    <div class="text-dark font-weight-bold col-3" translate>Stream Number</div>
    <div class="text-dark font-weight-bold col-6" translate>Stream Name</div>
  </div>

  <ng-container
    *ngFor="
      let item of attributeDescription.relatedEntityDefaultValues;
      index as i
    "
  >
    <div class="row mt-2" [class.d-none]="!isViewMoreStreams && i > 0">
      <div class="col-3 d-flex align-items-center">
        {{ item.streamNumber }}
      </div>
      <div [formGroupName]="item.streamNumber" class="col-9 col-md-6">
        <input
          *ngIf="editable; else viewRef"
          formControlName="streamName"
          class="form-control"
          [placeholder]="item?.streamName"
        />

        <ng-template #viewRef>
          <div>
            {{ entity ? getStreamValue(item)?.name : item.streamName }}
          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>

  <div class="mt-3">
    <button
      class="btn btn-link text-left p-0 font-size-sm"
      (click)="toggleViewMoreStream()"
    >
      View {{ isViewMoreStreams ? 'less' : 'more' }} streams
    </button>
  </div>
</form>
