import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbInputDatepicker,
  NgbTimeStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { TimeUtils } from '@shared/utils/time-utils';
import { DateFormat } from '../../enums/date.enum';

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';
  time: NgbTimeStruct;

  readonly ngbNullDate = new NgbDate(new Date(0).getFullYear(), 1, 1);

  DateFormat = DateFormat;

  constructor(protected datePipe: DatePipe) {
    super();
  }

  setTime(time: NgbTimeStruct): void {
    this.time = time;
  }

  customFormat(time: any): any {
    return this.datePipe.transform(time, DateFormat.FullDateTimeCommon);
  }

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = new Date(value);
      return {
        day: date.getDate(),
        month: date.getMonth() + 0,
        year: date.getFullYear(),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | any | null): string {
    const hour = this.time ? this.time.hour : 0;
    const minute = this.time ? this.time.minute : 0;
    const second = this.time ? this.time.second : 0;

    if (date && !this.ngbNullDate.equals(date)) {
      const time = new Date(
        date.year,
        date.month - 1,
        date.day,
        hour,
        minute,
        second
      );

      return this.customFormat(time);
    }
    return '';
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParser2Formatter extends CustomDateParserFormatter {
  constructor(protected datePipe: DatePipe) {
    super(datePipe);
  }

  customFormat(time): any {
    return this.datePipe.transform(time, 'mediumDate');
  }
}

@Component({
  selector: 'app-boostrap-datepicker',
  templateUrl: './boostrap-datepicker.component.html',
})
export class BoostrapDatepickerComponent implements OnInit, OnChanges {
  @Input() placeholder = '';
  @Input() currentTime = '';
  @Input() editable: boolean;
  @Input() isOptional: boolean;

  @Input() hideTimeOfDate = false;
  @Input() pastTime = false;
  @Input() futureTime = false;
  @Input() shouldUseDefaultDate = false;

  @Input() minTime: NgbDateStruct;
  @Input() maxTime: NgbDateStruct;

  @Input() inputId = 'boostrap-datepicker-input-id';
  @Input() placement: PlacementArray = 'left auto';
  @Input() defaultDateTime: string;

  @Output() dateTimeChange = new EventEmitter<string>();

  date: NgbDateStruct = null;
  @Input() time: NgbTimeStruct;

  currentValue: { date: NgbDateStruct; time: NgbTimeStruct };
  timeIsDifferent = false;

  @ViewChild('datePicker') datePicker: NgbInputDatepicker;

  constructor(
    private calendar: NgbCalendar,
    private dateParser: NgbDateParserFormatter
  ) {
    this.setDefaultTime();
  }

  ngOnInit(): void {
    if (this.isNullDate(this.date)) {
      this.date = null;
    }
    this.emitValue(false);
    this.limitCalendar();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pastTime && changes.futureTime) {
      this.limitCalendar();
    }

    if (
      changes.currentTime &&
      changes.currentTime.previousValue !== changes.currentTime.currentValue
    ) {
      if (this.currentTime != null) {
        const date = new Date(this.currentTime);
        this.date = TimeUtils.getBoostrapDate(date);
        this.time = TimeUtils.getBoostrapTime(date);
      } else {
        this.date = null;
        this.setDefaultTime();
      }
      this.currentValue = { date: this.date, time: this.time };
      // @ts-ignore
      this.dateParser.setTime(this.time);
    }

    if (
      changes.defaultDateTime &&
      changes.defaultDateTime.previousValue !==
        changes.defaultDateTime.currentValue &&
      changes.defaultDateTime.currentValue
    ) {
      const date = new Date(this.defaultDateTime);
      this.date = TimeUtils.getBoostrapDate(date);
      this.time = TimeUtils.getBoostrapTime(date);
    }
  }

  limitCalendar(): void {
    const today = this.calendar.getToday();

    if (this.pastTime) {
      this.minTime = this.minTime || this.calendar.getPrev(today, 'y', 100);
      this.maxTime = this.maxTime || this.calendar.getPrev(today);
      this.updateDefaultDate(this.maxTime);
    } else if (this.futureTime) {
      this.minTime = this.minTime || this.calendar.getNext(today);
      this.maxTime = this.maxTime || this.calendar.getNext(today, 'y', 100);
      this.updateDefaultDate(this.minTime);
    } else {
      this.minTime = this.minTime || this.calendar.getPrev(today, 'y', 100);
      this.maxTime = this.maxTime || this.calendar.getNext(today, 'y', 100);
      this.updateDefaultDate(today);
    }
    this.emitValue(false);
  }

  updateDefaultDate(date: NgbDateStruct): void {
    if (this.shouldUseDefaultDate) {
      this.date = this.date || date;
    }
  }

  confirm(): void {
    this.timeIsDifferent = false;

    this.emitValue();

    if (this.datePicker) {
      this.datePicker.close();
    }
  }

  cancel(): void {
    this.timeIsDifferent = false;

    if (this.currentValue) {
      this.date = this.currentValue.date;
      this.time = this.currentValue.time;
    }

    this.updateDateTime();

    if (this.datePicker) {
      this.datePicker.close();
    }
  }

  clear(): void {
    this.date = null;
    this.setDefaultTime();

    this.confirm();
  }

  isTimeChanged(): boolean {
    return (
      !this.currentValue ||
      this.currentValue.date?.year !== this.date.year ||
      this.currentValue.date?.month !== this.date.month ||
      this.currentValue.date?.day !== this.date.day ||
      this.currentValue.time?.hour !== this.time.hour ||
      this.currentValue.time?.minute !== this.time.minute ||
      this.currentValue.time?.second !== this.time.second
    );
  }

  emitValue(willTriggerFormStatusChanged = true): void {
    if (!this.shouldUseDefaultDate && !willTriggerFormStatusChanged) {
      return;
    }

    let formatDate: string;

    try {
      formatDate = new Date(
        this.date.year,
        this.date.month - 1,
        this.date.day,
        this.time.hour,
        this.time.minute,
        this.time.second
      ).toISOString();

      this.currentValue = { date: this.date, time: this.time };
    } catch (error) {
      formatDate = null;
      this.currentValue = null;
    }

    this.dateTimeChange.emit(formatDate);
  }

  updateDateTime(): void {
    this.date = { ...this.date };
    // @ts-ignore
    this.dateParser.setTime(this.time);
    this.timeIsDifferent = this.isTimeChanged();
    if (this.hideTimeOfDate) {
      this.confirm();
    }
  }

  getFormatedTime(): string {
    return this.dateParser.format(this.date) || '-.-';
  }

  private isNullDate(date: NgbDateStruct): boolean {
    return date && date.year === 1970 && date.day === 1 && date.month === 1;
  }

  private setDefaultTime(): void {
    this.time = {
      hour: 0,
      minute: 0,
      second: 0,
    };
  }
}
