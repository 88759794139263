<ng-container *ngTemplateOutlet="shareVenturesRef"></ng-container>

<ng-template #shareVenturesRef>
  <ng-container *ngIf="showFilter">
    <div class="shared-venture-filter" (clickOutside)="onClickOutSide()">
      <div class="d-block d-sm-flex justify-content-between align-items-end">
        <div class="flex-grow-1">
          <div class="d-flex align-items-center mb-2">
            <input
              name="searchType"
              id="fullTextSearch"
              type="radio"
              class="organization__radio mr-2"
              [value]="true"
              [(ngModel)]="isFullTextSearch"
              (ngModelChange)="onSearchTypeChange($event)"
            />
            <label for="fullTextSearch" class="radio-info">
              <span class="radio-text font-weight-bold">
                Full Text Search
              </span>
            </label>
          </div>
          <app-search-input-wrapper
            [searchText]="sharedVenturesfilter.keyword"
            [editMode]="true"
            (resetSearchClick)="onResetSearchText()"
          >
            <form (submit)="searchVenture()" action="#">
              <input
                type="text"
                class="form-control mb-3 pl-5"
                placeholder="{{ 'Search' | translate }} {{
                  'Ventures' | translate
                }}"
                [(ngModel)]="sharedVenturesfilter.keyword"
                type="text"
                name="searchVentureText"
                id="searchVentureText"
                [disabled]="!isFullTextSearch"
              />
            </form>
          </app-search-input-wrapper>
        </div>

        <div class="mx-3 mb-4">or</div>

        <div class="flex-grow-1">
          <div class="d-flex align-items-center mb-2">
            <input
              name="searchType"
              id="filterByOrg"
              type="radio"
              class="organization__radio mr-2"
              [value]="false"
              [(ngModel)]="isFullTextSearch"
              (ngModelChange)="onSearchTypeChange($event)"
            />
            <label for="filterByOrg" class="radio-info">
              <span class="radio-text font-weight-bold">
                Filter by Organization/Call/Challenges
              </span>
            </label>
          </div>
          <div class="mb-3 position-relative">
            <span class="search__icon-wrapper">
              <i
                class="search__icon fas"
                [ngClass]="
                  isSearchingOrg ? 'fa-spinner fa-spin' : 'fas fa-search'
                "
              ></i>
            </span>
            <input
              #searchInput
              appRequireSession
              id="search"
              type="text"
              class="search__input form-control form-controls-sm"
              [(ngModel)]="searchText"
              (ngModelChange)="onChangeSearchText()"
              placeholder="{{ 'Search' | translate }} {{
                'Organization' | translate
              }}/{{ 'Challenge' | translate }}
              "
              (focus)="onFocusSearchInput()"
              (blur)="onBlurSearchInput()"
              (click)="onClickSearchInput()"
              autocomplete="off"
              [disabled]="isFullTextSearch"
            />

            <virtual-scroller
              #scroll
              *ngIf="isDropdownOpen && virtualScrollItems.length > 0"
              class="align-self-start dropdown-menu show"
              [bufferAmount]="3"
              [enableUnequalChildrenSizes]="true"
              [items]="virtualScrollItems"
              (vsEnd)="loadMore($event)"
              [style.height]="300"
            >
              <ng-container *ngFor="let result of scroll.viewPortItems">
                <button class="dropdown-item" (click)="onSelectOrg(result)">
                  <div class="search-result">
                    <div
                      class="search-result__logo"
                      [ngStyle]="{
                        'background-image':
                          'url(' + (result.logo?.url || result.logo) + ')'
                      }"
                    ></div>

                    <div class="search-result__name truncate-line-1">
                      <ngb-highlight
                        class="truncate-line-1"
                        [result]="result.name || result.orgName"
                        [term]="searchText"
                      ></ngb-highlight>
                    </div>
                  </div>
                </button>
              </ng-container>
              <div *ngIf="isSearchingOrg" class="w-100 text-center">
                <i class="fas fa-spinner fa-spin"></i>
              </div>
            </virtual-scroller>

            <span
              class="search__icon-wrapper search__icon-wrapper--last pointer-cursor"
            >
              <i
                *ngIf="this.searchText"
                appDebounceClick
                (debounceClick)="resetSearchText()"
                class="reset-search far fa-times"
                focusable="false"
              >
              </i>
            </span>
          </div>
        </div>
      </div>

      <div
        class="text-medium-gray mb-3"
        *ngIf="isFullTextSearch && showSearchResultText$ | async"
      >
        Results for "<span class="font-weight-bold">{{
          showSearchResultText$ | async
        }}</span
        >"
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isGridView; else tableView">
    <ng-container *ngIf="isSearching; else viewModalBody">
      <div class="spinner-loading">
        <em class="fas fa-spinner fa-spin"></em>
      </div>
    </ng-container>

    <ng-template #viewModalBody>
      <div *ngIf="isShowAsChallenge" class="mb-4">
        <app-venture-stages-menu-bar
          (filterChanged)="onVenturesFilterChanged($event)"
          [currentFilter]="sharedVenturesfilter?.submissionStatus"
          [stateCount]="ventureStateCount"
        ></app-venture-stages-menu-bar>
      </div>

      <ng-container *ngIf="items?.length > 0; else noVenturesMessage">
        <div class="shared-entity shared-ventures">
          <div *ngFor="let item of items" class="item card container">
            <ng-container *ngIf="shouldShowItem(item)">
              <app-shared-venture-item
                [item]="item"
                [showSubmitMenu]="showSubmitMenu"
                [allowUpdateSubmissionStatus]="allowUpdateSubmissionStatus"
                (ventureDeleted)="paginateItems()"
                (afterLeaved)="afterLeaveVenture($event)"
              >
              </app-shared-venture-item>
              <div class="card-footer">
                <ul
                  class="venture__actions list-inline d-flex justify-content-start"
                >
                  <!-- Followers -->
                  <li
                    class="venture__actions-item list-inline-item mr-2 mr-md-5 white-space--no-wrap"
                  >
                    <ng-container
                      *ngIf="isLoadingFollowingsCount; else followersBtn"
                    >
                      <div class="text-center">
                        <i class="fas fa-spinner fa-spin"></i>
                      </div>
                    </ng-container>
                    <ng-template #followersBtn>
                      <a
                        appDebounceClick
                        (debounceClick)="showEntityData(item)"
                        class="d-flex"
                      >
                        <app-internal-icon
                          [className]="'filter-gray'"
                          [name]="'user-follow'"
                        ></app-internal-icon>
                        <span
                          class="venture__actions-label font-weight-500 text-gray-600"
                        >
                          <span class="text-hide-small">{{
                            'Followers' | translate
                          }}</span>

                          <span
                            [innerHTML]="item.followerCount | parentheses"
                          ></span>
                        </span>
                      </a>
                    </ng-template>
                  </li>

                  <app-comment-entity
                    [entity]="item"
                    [entityName]="EntityName.Venture"
                    [isLoadingCommentsCount]="isLoadingCommentsCount"
                    (afterCommentModalClosed)="afterCommentModalClosed()"
                  ></app-comment-entity>

                  <!-- Submission status -->
                  <ng-container *ngIf="item.isSubmittedVenture">
                    <li class="venture__actions-item list-inline-item ml-auto">
                      <!-- View mode -->
                      <div
                        *ngIf="
                          !allowUpdateSubmissionStatus;
                          else submissionStatusEdit
                        "
                        class="d-flex align-items-center justify-content-center"
                      >
                        <label class="flag flag-submitted">
                          <span class="d-none d-md-inline-block"
                            >{{ 'Venture State' | translate }}:&nbsp;</span
                          >

                          <span>{{
                            item.currentSubmission?.submissionStatus?.value ||
                              VentureSubmissionStatus.Submitted | translate
                          }}</span>
                        </label>
                      </div>

                      <!-- Edit mode -->
                      <ng-template #submissionStatusEdit>
                        <div class="d-flex align-items-center">
                          <div
                            class="venture__label widget__label mb-0 mr-2 d-none d-md-inline-block"
                          >
                            {{ 'Venture State' | translate }}
                          </div>

                          <app-boostrap-dropdown
                            class="truncate-selection"
                            [items]="submissionStatuses"
                            [currentIndex]="
                              getSubmissionStatusIndex(
                                item.currentSubmission?.submissionStatus
                              )
                            "
                            (selectItem)="
                              selectSubmissionStatus(item.id, $event)
                            "
                          ></app-boostrap-dropdown>
                        </div>
                      </ng-template>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>

  <ng-template #tableView>
    <div *ngIf="isShowAsChallenge" class="mb-4">
      <app-venture-stages-menu-bar
        (filterChanged)="onVenturesFilterChanged($event)"
        [currentFilter]="sharedVenturesfilter?.submissionStatus"
        [stateCount]="ventureStateCount"
      ></app-venture-stages-menu-bar>
    </div>

    <ng-container *ngIf="items?.length > 0; else noVenturesMessage">
      <app-metadata-table-venture
        [lstAdditionalAttrDes]="
          allowUpdateSubmissionStatus ? [submissionStatusAttrDes] : []
        "
        [showSubmitMenu]="showSubmitMenu"
        [parentEntityId]="parentEntityId"
        [parentHttpService]="httpService"
        [isParentEntityOwner]="isParentEntityOwner"
        [entityDatas]="items"
        [isLoading]="isSearching"
        (appendSortParams)="onSort($event)"
        (reloadData)="paginateItems()"
      ></app-metadata-table-venture>
    </ng-container>
  </ng-template>

  <ng-template #noVenturesMessage>
    <span class="message-empty" translate>There are no related ventures</span>
  </ng-template>

  <app-bootstrap-pagination
    (changePage)="onPageChange($event)"
    (changePageSize)="onPageSizeChange($event)"
    (confirmSave)="onConfirmSave($event)"
    [allowPaginationWarning]="allowPaginationWarning"
    [collectionSize]="collectionSize"
    [pageSize]="sharedVenturesfilter.pageSize"
    [page]="sharedVenturesfilter.pageIndex"
    [shouldShowWarning]="areVentureStatesHaveModified"
    [showChangePageSizeCtrl]="true"
  ></app-bootstrap-pagination>

  <app-people-modal
    *ngIf="showPeopleModal"
    [entityId]="selectedEntityId"
    [entityTitle]="selectedEntity?.title"
    [modalTitle]="'Followers' | translate"
    [httpService]="ventureHttpService"
    (dismissModal)="showPeopleModal = false"
  ></app-people-modal>
</ng-template>

<ng-template #modalRef let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="btn-close close"
      aria-label="Close button"
      aria-describedby="modal-title"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngTemplateOutlet="shareVenturesRef"></ng-container>
  </div>
</ng-template>
