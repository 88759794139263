import { Injectable } from '@angular/core';
import { ChallengeHttpService } from '@core/http/challenge-http.service';
import { EventHttpService } from '@core/http/event-http.service';
import { NewsMessagesHttpService } from '@core/http/news-messages-http.service';
import { OrganizationHttpService } from '@core/http/organization-http.service';
import { PeopleHttpService } from '@core/http/people-http.service';
import { VentureHttpService } from '@core/http/venture.http.service';
import { GlobalFilterStoredService } from '@core/services/global-filter-stored.service';
import { LoadingService } from '@core/services/loading.service';
import { SessionService } from '@core/session.service';
import { defaultEntities } from '@shared/constants/entities.const';
import { EntityName } from '@shared/enums/entity-name.enum';
import { GlobalEventBus } from '@shared/enums/event-bus.enum';
import {
  ChannelFilterCriteria,
  EntityGlobalFilterCriteria,
  GlobalFilterCriteria,
  VentureGlobalFilterCriteria,
} from '@shared/interfaces/filters/global-filter.interface';
import { ApiGetResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { CrossSearchingEntity } from '@shared/models/cross-searching-entity.model';
import { Entity } from '@shared/models/entity.model';
import { PageSizeConfig } from '@shared/models/pagination.model';
import { EventBusService } from 'ngx-eventbus';
import { BehaviorSubject, Observable, pipe } from 'rxjs';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';

export type FilterCriteria =
  | EntityGlobalFilterCriteria
  | VentureGlobalFilterCriteria;

@Injectable({
  providedIn: 'root',
})
export class SearchEntitiesService {
  entities = defaultEntities;

  entities$ = new BehaviorSubject<CrossSearchingEntity[]>([]);

  currentFilter = EntityName.All;

  filterDTO: FilterCriteria = {
    pageIndex: 1,
    pageSize: 3,
    keyword: '',
    isMyInvolvement: false,
    isMyOrgs: false,
    organizationIds: [],
  };

  pageSizeMedium = PageSizeConfig.EightItemsFirstPage;

  isSearchLoading$ = new BehaviorSubject<boolean>(false);

  channelFilterDto: ChannelFilterCriteria;

  private requestQueue: true[] = [];

  private readonly loadingPipe = pipe(
    tap(() => {
      this.requestQueue.push(true);
    }),
    finalize(() => {
      if (this.requestQueue.shift() && !this.requestQueue.length) {
        this.setSearchLoading(false, 500);
        this.loadingService.setLoadingState(false, 500);
      }
    })
  );

  setSearchLoading(loading: boolean, delayTime?: number): void {
    setTimeout(() => {
      this.isSearchLoading$.next(loading);
    }, delayTime);
  }

  private readonly getEntitiesAPIs = {
    [EntityName.Challenge]: (dto) => this.challengesService.paginateX(dto),
    [EntityName.Venture]: (dto) => this.ventureService.paginateAsPost(dto),
    [EntityName.Person]: (dto) => this.peopleService.paginateX(dto),
    [EntityName.Event]: (dto) => this.eventService.paginateX(dto),
    [EntityName.Organization]: (dto) => {
      return this.orgService.paginateAsPost(
        dto,
        null,
        dto.organizationId && {
          organizationId: dto.organizationId,
        }
      );
    },
    [EntityName.News]: (dto) => this.newsMessagesService.paginateX(dto),
  };

  constructor(
    private readonly sessionService: SessionService,
    private readonly challengesService: ChallengeHttpService,
    private readonly orgService: OrganizationHttpService,
    private readonly ventureService: VentureHttpService,
    private readonly peopleService: PeopleHttpService,
    private readonly eventService: EventHttpService,
    private readonly newsMessagesService: NewsMessagesHttpService,
    private readonly filterStoredService: GlobalFilterStoredService,
    private readonly loadingService: LoadingService,
    private readonly eventBusService: EventBusService
  ) {
    // change filter
    this.eventBusService.addEventListener({
      name: GlobalEventBus.GlobalFilterEvent,
      callback: ({
        filterCriteria: payload,
        filterPaneOnly,
      }: {
        filterCriteria: GlobalFilterCriteria;
        filterPaneOnly?: boolean;
      }) => {
        if (filterPaneOnly && this.currentFilter !== EntityName.Venture) return;

        this.filterDTO = {
          ...this.filterDTO,
          ...payload,
        };

        this.getEntitiesByFilter();
      },
    });
  }

  async generateSearchResults(keepPersistedFilter = true): Promise<void> {
    this.loadingService.setLoadingState(true);

    this.setSearchLoading(true);
    const sessionReady$ = this.sessionService.apiReady$.pipe(filter(Boolean));
    const filterCriteria = keepPersistedFilter
      ? await this.filterStoredService.getFilterCriteria()
      : {};

    this.filterDTO = {
      ...this.filterDTO,
      ...filterCriteria,
      ...(this.channelFilterDto ? this.channelFilterDto : {}),
    };

    this.entities.forEach((item: CrossSearchingEntity) => {
      item.content = sessionReady$.pipe(
        switchMap(() =>
          this.getEntitiesAPIs[item.key](this.filterDTO).pipe(this.loadingPipe)
        )
      ) as Observable<ApiGetResponse<Entity>>;
    });

    this.entities$.next([...this.entities]);
  }

  getSpecificEntity(entity: EntityName): void {
    const filteredEntity: CrossSearchingEntity = {
      ...this.entities.find(({ key }) => key === entity),
    };

    this.filterDTO.pageSize = this.pageSizeMedium;

    this.entities$.next([filteredEntity]);
  }

  getAllEntities(): void {
    this.loadingService.setLoadingState(true);
    const entities = this.entities.map((entity) => ({
      ...entity,
    }));
    this.filterDTO.pageSize = PageSizeConfig.ThreeItemsFirstPage;
    this.entities$.next([...entities]);
  }

  changePaging(pageIndex: number): void {
    this.filterDTO.pageIndex = pageIndex;
    this.setSearchLoading(true);
    this.getSpecificEntity(this.currentFilter);
  }

  refreshList(): void {
    this.getSpecificEntity(this.currentFilter);
  }

  private getEntitiesByFilter(): void {
    if (this.currentFilter === EntityName.All) {
      this.getAllEntities();
    } else {
      this.setSearchLoading(true);
      this.getSpecificEntity(this.currentFilter);
    }
  }

  setChannelFilter(dto: ChannelFilterCriteria) {
    this.channelFilterDto = dto;
  }
}
