<ng-container
  [appScrollToFragment]="data$ | async"
  *ngIf="data$ | async as response; else skeleton"
>
  <ng-container *ngIf="response.items?.length; else emptyContent">
    <div
      class="list-tiles"
      [class.two-columns]="columns === '2'"
      [class.widget__card--list--background]="!bg_color"
      [style.background-color]="bg_color"
      *ngIf="!isLoading; else skeleton"
    >
      <div
        (click)="goToListPage()"
        *ngFor="let news of response.items"
        class="list-tiles__item"
      >
        <app-news-tiles-item
          (syncCommentStateCount)="syncCommentStateCount($event)"
          [news]="news"
          [userId]="(user$ | async)?.id"
        ></app-news-tiles-item>
      </div>
    </div>

    <app-bootstrap-pagination
      *ngIf="show_paginator"
      (changePage)="org_id ? changePaging($event) : searchEntitiesService.changePaging($event)"
      [page]="org_id ? pageIndex : searchEntitiesService.filterDTO.pageIndex"
      [pageSize]="org_id ? +page_size : searchEntitiesService.filterDTO.pageSize"
      [collectionSize]="response.rowCount"
    ></app-bootstrap-pagination>

    <div class="text-right" *ngIf="show_creation_btn">
      <button
        class="btn btn-primary widget__btn-create my-3"
        [callBackLoginPage]="entityCreationPath"
        appRequireLogin
        translate
      >
        <i class="fas fa-plus fa-lg"></i>
      </button>
    </div>
  </ng-container>
</ng-container>

<ng-template #emptyContent>
  <app-empty-content></app-empty-content>
</ng-template>

<ng-template #skeleton>
  <div class="loading-skeleton">
    <div class="row">
      <div
        *ngFor="let item of skeletonCount"
        class="col-12"
        [ngClass]="'col-sm-' + 12 / skeletonCount.length"
      >
        <div class="card">
          <div class="d-flex align-items-center m-2">
            <div>
              <div class="skeleton-item avatar-skeleton round-image"></div>
            </div>
            <div class="flex-grow-1 ml-2">
              <h6>Card title</h6>
              <h6>Card title</h6>
            </div>
          </div>
          <div class="skeleton-item img-skeleton"></div>
          <div class="card-body">
            <h6>Card title</h6>
            <h6>Card title</h6>
            <h6>Card title</h6>
            <h6 class="w-50 mt-4 mb-2">Card title</h6>
            <hr />
            <div class="d-flex justify-content-between mt-2">
              <h6 class="w-25">Card title</h6>
              <h6 class="w-25">Card title</h6>
              <h6 class="w-25">Card title</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
