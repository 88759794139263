import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { SessionService } from '@core/session.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EntityName } from '@shared/enums/entity-name.enum';
import { EventCalendarDialogComponent } from '@src/app/components/dialogs/event-calendar-dialog/event-calendar-dialog.component';
import { PageHeaderService } from '@src/app/components/master-layout/page-header/services/page-header.service';
import { CentralConfigService } from '@src/app/core/services/central-config.service';
import { GlobalFilterStoredService } from '@src/app/core/services/global-filter-stored.service';
// app
import { GlobalEventBus } from '@src/app/shared/enums/event-bus.enum';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { WINDOW } from '@src/app/shared/helpers/window.token';
import { MediaBreakpoint } from '@src/app/shared/models/ui.model';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { environment } from '@src/environments/environment';
import { EventBusService } from 'ngx-eventbus';
import { Observable } from 'rxjs';

export enum EventViewMode {
  TileView,
  CalendarView,
}
@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styles: [],
})
export class PageHeaderComponent implements OnInit {
  @Input() title: string;

  @Input() helpText: string;

  helpTextPlacement = '';
  createBtnText = 'Create';

  isShownActionBtn = true;

  EntityName = EntityName;

  isHomePage = false;

  isEventPage = false;

  eventViewModes = [
    {
      iconClass: 'fas fa-th-large',
      title: 'Tile List View',
      value: EventViewMode.TileView,
    },
    {
      iconClass: 'fas fa-calendar-day',
      title: 'Calendar View',
      value: EventViewMode.CalendarView,
    },
  ];
  eventViewMode: Observable<EventViewMode>;

  constructor(
    @Inject(WINDOW) private window: Window,
    private readonly eventBus: EventBusService,
    private readonly sessionService: SessionService,
    private readonly modalService: NgbModal,
    private filteredStoreService: GlobalFilterStoredService,
    private centralConfigService: CentralConfigService,
    private pageHeaderService: PageHeaderService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    const { innerWidth } = event?.target;
    this.setHelpTextPlacement(innerWidth);
  }

  ngOnInit(): void {
    this.setHelpTextPlacement(window.innerWidth);
    this.createBtnText = this.getPageHeaderCreateBtnText();
    this.checkConditionToShowBtn();
    this.isEventPage = this.isEvents();
    this.eventViewMode = this.pageHeaderService.getEventViewMode();
  }

  onBtnClick(event: Event): void {
    this.eventBus.triggerEvent(GlobalEventBus.EntityCreatePathUpdate, event);
  }

  onCreatePostClick(event: Event): void {
    this.eventBus.triggerEvent(GlobalEventBus.CreatePost, event);
  }

  private setHelpTextPlacement(width: number): void {
    this.helpTextPlacement = width < MediaBreakpoint.sm ? 'left' : 'right';
  }

  private getPageHeaderCreateBtnText(): string {
    if (location.pathname?.includes(environment.jipUrl.people)) {
      return 'UI.Button.InvitePeople';
    }
    return 'Create';
  }

  private checkConditionToShowBtn(): void {
    if (
      location.pathname?.includes(environment.jipUrl.searchResults) ||
      location.pathname?.includes(environment.jipUrl.eventRegister)
    ) {
      this.isShownActionBtn = false;
    }

    this.isHomePage = this.sessionService.isOnHomePage;
  }

  async openEventCalendarDialog(): Promise<void> {
    const modalRef = this.modalService.open(EventCalendarDialogComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
      scrollable: true,
      windowClass: 'message-modal-custom event-calendar-dialog',
    });

    const { innovationSpaceId } = this.centralConfigService;

    const filterCriteria = await this.filteredStoreService.getFilterCriteria();

    if (innovationSpaceId) {
      filterCriteria.organizationIds = [innovationSpaceId];
    }
    modalRef.componentInstance.filterCriteria = filterCriteria;
  }

  isEvents(): boolean {
    const isEvents = this.window.location.pathname.includes(
      environment.jipUrl.event
    );

    return isEvents;
  }

  isMessages(): boolean {
    const isMessages = StringUtils.getParamFromUrl(UrlParam.TabNews);

    return !!isMessages;
  }

  onEventViewModeChange(value: EventViewMode) {
    this.pageHeaderService.setEventViewMode(value);
  }
}
