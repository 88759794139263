import { NewsMessagesFilterType } from '@shared/enums/filter.enum';
import { EntityGlobalFilterCriteria } from '../interfaces/filters/global-filter.interface';

export function applyFilterByWidgetConfig(
  filterConfig: string,
  filterCriteria: EntityGlobalFilterCriteria
): void {
  switch (filterConfig) {
    case 'admin_post':
      filterCriteria.isAdminPost = true;
      break;
    case 'post':
      filterCriteria.isAllPost = true;
      break;
    case 'news':
      filterCriteria.isAllPost = false;
      break;
    default:
      delete filterCriteria.isAdminPost;
      delete filterCriteria.isAllPost;
      break;
  }
}

export function applyFollowingOrLikeFilter(
  filterType: string,
  filterCriteria: EntityGlobalFilterCriteria
): void {
  if (
    filterType === NewsMessagesFilterType.News &&
    !filterCriteria.isMyFollowingOrLiked
  ) {
    filterCriteria.isMyFollowingOrLiked = false;
  }
}

export function applyFilterStreamByWidgetConfig(streams: string, filterCriteria: EntityGlobalFilterCriteria) {
  if(streams) {
    filterCriteria.streams = streams.split('-');
  }
}