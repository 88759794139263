<div class="modal-header">
  <h4 class="modal-title" translate>UI.Challenge.JuryInvitation</h4>
  <button
    type="button"
    class="btn-close close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body new-message-dialog-modal-body">
  <ng-container
    *ngIf="isLoadingAttributeDescriptions$ | async; else sendMessageTemplate"
  >
    <div class="d-flex justify-content-center align-items-center">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
  </ng-container>
  <ng-template #sendMessageTemplate>
    <form [formGroup]="form">
      <div class="search__container">
        <app-tag-field-expanded
          (itemsChange)="handleRecipientsChanged($event)"
          [(items)]="selectedRecipients"
          [placeholder]="('UI.Label.SearchName' | translate) + '....'"
          [searchData$]="searchData$"
          [isShowLeftColum]="false"
        ></app-tag-field-expanded>
      </div>

      <div class="d-flex flex-row">
        <div class="message-content-quill mb-3 ml-0 mw-100">
          <p class="mt-3 mb-2">{{ 'Message' | translate }}:</p>
          <app-form-field-custom
            [formInput]="form"
            [type]="AttributeType.RTE"
            [attributeDescription]="contentAttributeDescription"
            [shouldValidateOnTouch]="true"
            [options]="{
              placeholder: '',
              hideQuillToolbar: true
            }"
          >
          </app-form-field-custom>
        </div>
      </div>

      <div class="row">
        <div class="col-md-10 col-xs-12 offset-xs-0">
          <div class="form-group mt-2">
            <div class="form-check">
              <input
                class="form-check-input pointer-cursor"
                type="checkbox"
                id="assingingJuryCheck"
                formControlName="isAssigningJuror"
              />
              <label
                class="form-check-label checkbox-text-font-size pointer-cursor"
                for="assingingJuryCheck"
              >
                {{ 'UI.Challenge.AssigningJury' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <app-mail-forwarding-checkbox
        [alignLeft]="true"
        formControlName="isSendMail"
      ></app-mail-forwarding-checkbox>
    </form>
  </ng-template>
</div>
<div class="modal-footer message-command">
  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="close()"
  >
    {{ 'Cancel' | translate | uppercase }}
  </button>
  <button
    (click)="onSend()"
    [disabled]="form.invalid || isSubmitting"
    appRequireLogin
    class="btn btn-primary"
    ngbAutofocus
    type="button"
  >
    <ng-container *ngIf="isSubmitting">
      <i class="fas fa-spinner fa-spin btn-icon-right"></i>
    </ng-container>
    <span>{{'Send' | translate | uppercase}}</span>
  </button>
</div>
