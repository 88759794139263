import { Injectable, OnDestroy } from '@angular/core';
import { OrganizationHttpService } from '@src/app/core/http/organization-http.service';
import { SessionService } from '@src/app/core/session.service';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import {
  OrganizationFilter,
  OrganizationInterface,
} from '@src/app/shared/interfaces/organization.interface';
import {
  BehaviorSubject,
  Observable,
  Subject,
  filter,
  map,
  switchMap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommunitySelectorStoredService implements OnDestroy {
  private selectedOrg$ = new BehaviorSubject<OrganizationFilter>(null);
  private keyword$ = new BehaviorSubject<string>('');
  private popularOrgs$ = new BehaviorSubject([]);
  search$ = new Subject<void>();

  constructor(
    private sessionService: SessionService,
    private orgHttpService: OrganizationHttpService
  ) {}

  get selectedOrg() {
    return this.selectedOrg$.asObservable();
  }

  get keyword() {
    return this.keyword$.asObservable();
  }

  get popularOrgs() {
    return this.popularOrgs$.asObservable();
  }

  setSelectedOrg(org: OrganizationFilter): void {
    this.selectedOrg$.next(org);
  }

  setKeyword(keyword: string): void {
    this.keyword$.next(keyword);
  }

  search() {
    this.search$.next();
  }

  setPopularOrgs(orgs): void {
    this.popularOrgs$.next(orgs);
  }

  getPopularOrg(
    ignoreOrgIds: number[]
  ): Observable<(OrganizationInterface & OrganizationFilter)[]> {
    return this.sessionService.apiReady$.pipe(
      filter(Boolean),
      switchMap(() => {
        return this.orgHttpService
          .getPopularOrgs({
            IgnoreIds: ignoreOrgIds,
          })
          .pipe(
            untilDestroyed(this),
            map((orgs: (OrganizationInterface & OrganizationFilter)[]) =>
              orgs.map((org) => ({ ...org, isPopular: true }))
            )
          );
      })
    );
  }

  ngOnDestroy(): void {
    /** */
  }
}
