<app-people-cards
  (extraActionClicked)="promoteStakeholder($event)"
  (itemsChange)="emitItems($event)"
  (peopleAdded)="peopleAdded.emit()"
  (peopleRoleChanged)="updateDirectPeopleRole($event)"
  (removeItemClicked)="openConfirmRemoveItemDialog($event)"
  (peopleCardClicked)="onPeopleCardClicked()"
  [allowAddPeople]="allowAddPeople"
  [allowRemovePeople]="allowRemovePeople"
  [customUserRoles]="customUserRoles"
  [editable]="editable"
  [emptyMessage]="emptyMessage"
  [enableExtraAction]="enableExtraAction"
  [enablePagination]="enablePagination"
  [excludedPeopleIds]="excludedPeopleIds"
  [extraActions]="extraActions"
  [filteringKeyword]="filteringKeyword"
  [ignoreId]="ignoreId"
  [items]="items"
  [parentEntityName]="parentEntityName"
  [parentEntity]="parentEntityName"
  [removePeopleTitle]="removePeopleTitle"
  [globalOrgId]="globalOrgId"
  [hideProfileImage]="hideProfileImage"
  [showInteractionBar]="showInteractionBar"
></app-people-cards>
