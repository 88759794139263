import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { PeopleHttpService } from '@core/http/people-http.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap/typeahead/typeahead';
import { CardType } from '@shared/components/people-card-list/people-card-list.component';
import {
  PeopleAction,
  PeopleInterface,
} from '@shared/interfaces/people.interface';
import { UserInterface } from '@shared/interfaces/user.interface';
import { ArrayUtils } from '@shared/utils/array-utils';
import { StringUtils } from '@shared/utils/string-utils';
import { ConfirmationComponent } from '@src/app/components/confirmation/confirmation.component';
import { RemovePersonDialogComponent } from '@src/app/components/dialogs/remove-person-dialog/remove-person-dialog.component';
import { NotificationService } from '@src/app/core/notification.service';
import { ToastService } from '@src/app/core/toast.service';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { CustomAtrributeValue } from '@src/app/shared/interfaces/attribute-description.interface';
import { Observable } from 'rxjs/internal/Observable';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  switchMap,
} from 'rxjs/operators';

@Component({
  selector: 'app-people-cards',
  templateUrl: './people-cards.component.html',
})
export class PeopleCardsComponent implements OnChanges, OnDestroy {
  @Input() items: UserInterface[] = [];
  @Input() httpService: BaseHttpService<any>;
  @Input() showSearchButton = false;
  @Input() editable = false;
  @Input() enableExtraAction = false;
  @Input() ignoreId: number;
  @Input() excludedPeopleIds: number[] = [];
  @Input() enablePagination = true;
  @Input() shouldValidatePersonBeforeAdding: boolean;
  @Input() extraActions: PeopleAction[] = [];
  @Input() allowAddPeople: boolean;
  @Input() allowRemovePeople: boolean;
  @Input() customUserRoles: CustomAtrributeValue[] = [];
  @Input() entityId: number;
  @Input() globalOrgId = 0;

  @Input() parentEntityName: string;
  @Input() parentEntity: any;
  @Input() showAddButton = false;
  @Input() emptyMessage: string;
  @Input() removePeopleTitle: string;
  @Input() filteringKeyword: string;
  @Input() cardType: CardType;
  @Input() useOrgAdminTemplate = false;
  @Input() hideProfileImage = false;
  @Input() showInteractionBar = true;

  @Output() itemsChange = new EventEmitter();
  @Output() removeItemClicked = new EventEmitter();
  @Output() extraActionClicked = new EventEmitter<PeopleAction>();
  @Output() peopleRoleChanged = new EventEmitter<UserInterface>();
  @Output() peopleAdded = new EventEmitter();
  @Output() peopleRemoved = new EventEmitter<UserInterface>();
  @Output() peopleCardClicked = new EventEmitter<void>();

  displayItems: UserInterface[] = [];
  pagingDisplayItems: UserInterface[] = [];
  searchName: string;

  entitybase = '/people';

  selectedPeople;
  people: PeopleInterface[] = [];

  // Pagination
  page = 1;
  pageSize = 6;
  collectionSize = 0;

  // Search
  peopleSearching = false;
  peopleSearchFailed = false;
  peopleSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(async (term) => {
        if (this.allowAddPeople ?? this.editable) {
          try {
            this.people = await this.paginatePeople(term);
          } catch (err) {
            this.peopleSearchFailed = true;
            this.peopleSearching = false;
          }
        }

        let filteredPeople = [];
        if (this.people?.length > 0) {
          filteredPeople = this.people.filter((item) => {
            if (ArrayUtils.hasItem(this.items, item, 'id')) {
              return false;
            }

            if (this.ignoreId === item.id) {
              return false;
            }

            return true;
          });
        }
        return this.filterPeople(filteredPeople, term);
      })
    );
  peopleInputFormatter = (result: PeopleInterface) => result.name;

  constructor(
    protected peopleService: PeopleHttpService,
    protected modalService: NgbModal,
    protected notificationService: NotificationService,
    protected toastService: ToastService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items || changes.filteringKeyword) {
      this.setDisplayItems(this.filteringKeyword);
    }
  }

  onSelect(event: NgbTypeaheadSelectItemEvent, input): void {
    event.preventDefault();
    const { item } = event;
    this.selectedPeople = item;
    if (!this.selectedPeople) {
      return;
    }
    // trigger org selected if don't have Add button
    if (!this.showAddButton) {
      this.addPeople();
    }
    input.value = '';
  }

  addPeople(): void {
    if (!this.selectedPeople) {
      return;
    }

    if (!this.items) {
      this.items = [];
    }

    if (!this.shouldValidatePersonBeforeAdding) {
      this.handleAddPeople();
      return;
    }
    this.httpService
      .getByEntity(
        this.parentEntity?.id,
        `/validate-person/${this.selectedPeople?.id}`
      )
      .pipe(untilDestroyed(this))
      .subscribe((valid) => {
        if (valid) {
          this.handleAddPeople();
        } else {
          this.notificationService.show(
            'NOTIFICATION_User-must-be-in-organization-first--title',
            'NOTIFICATION_User-must-be-in-organization-first--content'
          );
          this.clearSearchInformation();
        }
      });
  }

  openConfirmDialog(
    person: UserInterface,
    componentClass,
    title = '',
    confirmText?
  ): void {
    const modalRef = this.modalService.open(componentClass, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      scrollable: false,
      windowClass: 'message-modal-custom',
    });

    if (title) {
      modalRef.componentInstance.personTitle = title;
    }

    if (confirmText) {
      modalRef.componentInstance.confirmText = confirmText;
    }

    modalRef.componentInstance.personImage = person.image;
    modalRef.componentInstance.personId = person.id;
    modalRef.componentInstance.personName =
      `${person.firstName} ${person.lastName}`.trim();
    modalRef.componentInstance.fromEntityName = this.parentEntityName;
    modalRef.componentInstance.fromEntity = this.parentEntity;

    modalRef.result
      .then((accepted) => {
        if (accepted) {
          this.confirmToRemoveItem(person);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  private handleAddPeople(): void {
    if (!ArrayUtils.hasItem(this.items, this.selectedPeople, 'id')) {
      this.items.push(this.selectedPeople);
      this.peopleAdded.emit();
      this.emitItems();
    }
    this.clearSearchInformation();
  }

  private clearSearchInformation(): void {
    this.selectedPeople = null;
    this.setDisplayItems();
  }

  emitItems(items = null): void {
    this.itemsChange.emit(items || this.items);
  }

  setDisplayItems(text = null): void {
    this.searchName = text;

    if (
      (!this.showSearchButton && !this.filteringKeyword) ||
      !this.searchName
    ) {
      this.displayItems = Array.isArray(this.items) ? [...this.items] : [];
    } else {
      this.displayItems = this.filterPeople(this.items, this.searchName);
    }

    this.collectionSize = this.displayItems.length;
    this.onPaging();
  }

  private filterPeople(items = [], text = ''): any[] {
    if (!Array.isArray(items) || items?.length === 0) {
      return items;
    }

    const trimmedText = text.trim();

    return items.filter((item) => {
      const name = `${item.firstName} ${item.lastName} (${item.email})`;
      return StringUtils.includes(name, trimmedText);
    });
  }

  private paginatePeople(searchKey: string): Promise<PeopleInterface[]> {
    if (!searchKey || searchKey.length === 0) {
      return Promise.resolve([]);
    }

    const params = {
      pageIndex: '1',
      pageSize: this.pageSize,
      name: searchKey,
      keyword: searchKey,
      ignoreIds: this.excludedPeopleIds,
    };

    this.peopleSearching = true;
    return this.peopleService
      .paginateX(params, 'search')
      .pipe(
        finalize(() => {
          this.peopleSearching = false;
        }),
        map((res) => res.items)
      )
      .toPromise();
  }

  onPaging(): void {
    this.pagingDisplayItems = this.displayItems.slice(
      (this.page - 1) * this.pageSize,
      this.page * this.pageSize
    );
  }

  openConfirmRemoveItemDialog(person: UserInterface, confirmText?): void {
    this.openConfirmDialog(
      person,
      RemovePersonDialogComponent,
      this.removePeopleTitle,
      confirmText
    );
  }

  confirmToRemoveItem(person: UserInterface): void {
    this.items = ArrayUtils.removeItem(this.items, person, 'id');
    this.peopleRemoved.emit(person);
    this.emitItems();
    this.setDisplayItems();
  }

  confirmToIgnoreValidateRole(person: UserInterface): void {
    // implement in inherit
  }

  refuseToIgnoreValidateRole(person: UserInterface): void {
    this.rollbackCustomRole(person);
  }

  openConfirmIgnoreValidateRoleDialog(
    person: UserInterface,
    message: string
  ): void {
    const modalRef = this.modalService.open(ConfirmationComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = 'Confirmation';
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.confirmLabel = 'Yes';

    modalRef.result
      .then((accepted) => {
        if (accepted) {
          this.confirmToIgnoreValidateRole(person);
        } else {
          this.refuseToIgnoreValidateRole(person);
        }
      })
      .catch((error) => {
        this.refuseToIgnoreValidateRole(person);
        console.log(error);
      });
  }

  rollbackCustomRole(person: UserInterface): void {
    person.roleIndex = person.previousRoleIndex;
    person.role = { ...person.previousRole };
  }

  onPeopleCardClicked(): void {
    console.log('emit peopleCardClicked');
    this.peopleCardClicked.emit();
  }

  ngOnDestroy(): void {
    /**/
  }
}
