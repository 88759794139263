<!-- <div class="modal-header">
  <h4 class="modal-title" translate>UI.Calendar.EventCalendar</h4>
  <button
    type="button"
    class="btn-close close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div> -->
<!-- <div class="modal-body">
</div> -->
<div *ngIf="!isLoading; else loading">
  <full-calendar [options]="calendarOptions"></full-calendar>
</div>

<ng-template #loading>
  <div class="text-center loading-calendar-indicator mt-2">
    <i class="fas fa-spinner fa-spin"></i>
  </div>
</ng-template>

<ng-template #popoverTmpl let-event="event">
  <div class="event-popup">
    <div
      class="event-popup__heading d-flex justify-content-between align-items-center"
    >
      <div class="d-flex align-items-center">
        <span class="mr-2">
          <app-shared-icon [name]="'event'"></app-shared-icon>
        </span>
        <span translate>Event</span>
      </div>
      <div class="event-popup__heading--close-btn">
        <span
          class="btn fas fa-times"
          (onClick)="onCloseEventPopup(event)"
        ></span>
      </div>
    </div>
    <div class="event-popup__content" (mousedown)="handleMouseDown($event)">
      <div class="event-popup__content--datetime mt-1">
        {{ event.event.extendedProps.eventDate }} |
        {{ event.event.extendedProps.time }}
      </div>
      <div class="event-popup__content--event-name mt-1 truncate-line-2">
        {{ event.event.title }}
      </div>
      <div class="event-popup__content--detail-btn mt-2">
        <a
          [href]="event.event.extendedProps.eventUrl"
          target="_blank"
          class="btn btn-primary"
          translate
          >UI.Calendar.Detail</a
        >
      </div>
    </div>
  </div>
</ng-template>
