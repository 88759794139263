<div class="mb-2">
  <div
    class="news-messages-filter menu-tabs-bar d-flex align-items-center mt-3 m-0"
    *ngIf="newsAndMessagesTab === FilterType.All"
  >
    <div
      (ngModelChange)="onFilterChange($event)"
      [(ngModel)]="mainFilter"
      class="btn-group-toggle d-flex flex-wrap"
      ngbRadioGroup
      role="group"
    >
      <label
        *ngFor="let item of mainFilters"
        class="btn-outline-primary px-2 py-1 btn-search-filter"
        ngbButtonLabel
      >
        <input [value]="item.key" ngbButton type="radio" />
        <span class="font-size-sm font-weight-600" translate>{{
          item.label
        }}</span>
      </label>
    </div>
  </div>

  <div
    *ngIf="mainFilter === FilterType.News"
    (ngModelChange)="onSubFilterChange($event)"
    [(ngModel)]="subFilter"
    ngbRadioGroup
    role="group"
  >
    <ng-container *ngFor="let item of subFilters">
      <div *ngIf="!item.hidden()" class="radio mt-3" ngbButtonLabel>
        <input [id]="item.value" [value]="item.value" ngbButton type="radio" />

        <label
          *ngIf="item.value !== 'organizationIds'"
          [for]="item.value"
          [innerHTML]="
            item.label | translate : { name: (selectedOrg$ | async)?.name }
          "
          class="radio-label truncate-line-2 pointer-cursor"
        ></label>

        <ng-container *ngIf="item.value === 'organizationIds'">
          <label
            *ngIf="selectedOrg$ | async as selectedOrg"
            [for]="item.value"
            [innerHTML]="
              item.label
                | translate : { name: selectedOrg.name ?? selectedOrg.orgName }
            "
            class="radio-label truncate-line-2 pointer-cursor"
          ></label>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
