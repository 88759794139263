import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { EntityName } from '@shared/enums/entity-name.enum';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { LeadCompany } from '@shared/interfaces/lead-company.interface';
import { VentureInterface } from '@shared/interfaces/venture.interface';
import { CustomFormService } from '@src/app/core/form/custom-form.service';
import { VentureHttpService } from '@src/app/core/http/venture.http.service';
import { MetaInformation } from '@src/app/shared/interfaces/attribute-description.interface';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-venture-list',
  templateUrl: './venture-list.component.html',
  providers: [CustomFormService],
})
export class VentureListComponent implements OnDestroy, OnInit {
  @Input() items: VentureInterface[];
  @Input() isParentEntityOwner = false;
  @Input() checkNDAFn: (venture: VentureInterface) => Observable<boolean>;

  @Output() submittedVentureToChallenge = new EventEmitter();
  @Output() commentModalClosed = new EventEmitter();
  @Output() openedFollower = new EventEmitter<VentureInterface>();
  @Output() venturesChanged = new EventEmitter();

  isLoadingFollowingsCount: any;
  isLoadingCommentsCount: any;
  EntityName = EntityName;

  constructor(
    private readonly ventureHttpService: VentureHttpService,
    private readonly customFormService: CustomFormService
  ) {}

  ngOnInit(): void {
    this.ventureHttpService
      .getAttributeDescription()
      .pipe(untilDestroyed(this))
      .subscribe((descriptions: MetaInformation) => {
        this.customFormService.setControlConfig(descriptions);
      });
  }

  ngOnDestroy(): void {
    /**/
  }

  showFollower(item: VentureInterface): void {
    this.openedFollower.emit(item);
  }

  afterCommentModalClosed(): void {
    this.commentModalClosed.emit(true);
  }

  submitVenture(venture: VentureInterface): void {
    this.submittedVentureToChallenge.emit({ ventureId: venture.id });
  }

  onSubmitClicked(
    isSubmittedInput: HTMLInputElement,
    item: VentureInterface
  ): void {
    if (this.checkNDAFn) {
      this.checkNDAFn(item)
        .pipe(filter(Boolean), untilDestroyed(this))
        .subscribe(() => {
          this.submitVenture(item);
          isSubmittedInput.checked = true; // Disable submit button
        });
    } else {
      this.submitVenture(item);
      isSubmittedInput.checked = true; // Disable submit button
    }
  }

  refreshVentures(): void {
    this.venturesChanged.emit();
  }

  trackByFn(_index: number, item: LeadCompany): number {
    return item.id;
  }
}
