import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AttributeDescription } from '@src/app/shared/interfaces/attribute-description.interface';

export interface StreamValue {
  streamNumber: string;
  streamName: string;
  streamId: number;
}
@Component({
  selector: 'app-stream-settings',
  templateUrl: './stream-settings.component.html',
})
export class StreamSettingsComponent implements OnChanges {
  @Input() editable: boolean;
  @Input() showLabel: boolean = true;
  @Input() attributeDescription: AttributeDescription;
  @Input() entity: any;

  streamForm: FormGroup;
  isViewMoreStreams = false;

  constructor(private fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.attributeDescription) {
      this.generateForm(this.attributeDescription);
    }

    if (changes.entity) {
      if (this.entity) {
        this.attributeDescription.relatedEntityDefaultValues.forEach((item) => {
          const stream = this.getStreamValue(item);
          this.streamForm.patchValue({
            [item.streamNumber]: {
              streamId: stream.streamKey - 1,
              streamName: stream.name,
            },
          });
        });
      }
    }
  }

  generateForm(attributeDescription: AttributeDescription): void {
    const formControls = this.getFormControls(attributeDescription);
    this.streamForm = this.fb.group(formControls);
    this.streamForm.get('Default Stream')?.disable();
  }

  getFormControls(attributeDescription: AttributeDescription) {
    const formControls = {};

    attributeDescription.relatedEntityDefaultValues.forEach(
      (attr: StreamValue) => {
        formControls[attr.streamNumber] = this.fb.group({
          streamId: [attr.streamId],
          streamName: [attr.streamName],
        });
      }
    );

    return formControls;
  }

  getStreamValue(stream) {
    const postStream = this.entity.postStream;
    return postStream.find((item) => item.streamNumber === stream.streamNumber);
  }

  toggleViewMoreStream(): void {
    this.isViewMoreStreams = !this.isViewMoreStreams;
  }

  getStreamFormValue() {
    let postStream = [];
    const formValue = this.streamForm.getRawValue();
    for (let key in formValue) {
      postStream.push(formValue[key]);
    }
    return postStream;
  }
}
