import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ChallengeHttpService } from '@core/http/challenge-http.service';
import { PeopleHttpService } from '@core/http/people-http.service';
import { IPageInfo } from '@iharbeck/ngx-virtual-scroller';
import { Key } from '@shared/enums/key.enum';
import {
  OrgsSearchMode,
  OrgsSearchTagFieldTypeEnum,
} from '@shared/enums/org-search-mode.enum';
import { LeadCompany } from '@shared/interfaces/lead-company.interface';
import { AutocompleteRequestInterface } from '@shared/interfaces/request/autocomplete-request.interface';
import { OrganizationHttpService } from '@src/app/core/http/organization-http.service';
import { ApiGetResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
} from 'rxjs/operators';
import { EntityName } from '../../enums/entity-name.enum';
import { OrganizationType } from '../../enums/organization.enum';
import { untilDestroyed } from '../../functions/until-destroyed';
import { ChallengeInterface } from '../../interfaces/challenge.interface';
import {
  OrgSearchCustomDropdownItemInterface,
  OrganizationInterface,
} from '../../interfaces/organization.interface';
import { UserInterface } from '../../interfaces/user.interface';

@Component({
  selector: 'app-organisation-search',
  templateUrl: './organisation-search.component.html',
})
export class OrganisationSearchComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() userId: number;
  @Input() searchMode: OrgsSearchMode = OrgsSearchMode.ForLead;
  @Input() ignoreCompany: LeadCompany = {};
  @Input() excludedOrganisationIds: number[] = [];
  @Input() searchPlaceholder: string;
  @Input() enableCustomOnDropdown = false;
  @Input() resultContainer = '';
  @Input() organizationType: OrganizationType = OrganizationType.All;
  @Input() entityId: number;
  @Input() entityName: EntityName;
  @Input() enableDynamicHeight = true;
  @Input() height = 400;
  @Input() autoOpenDropdown = false;
  @Input() closeDropdownOnSelect = true;
  @Input() updateListOnSelect = false;

  @Output() selectOrganisation = new EventEmitter<any>();
  @Output() afterResetSearchText = new EventEmitter();
  
  @ViewChild('searchInput') searchInputEl: ElementRef;

  isSearching = false;
  selectedOrg: LeadCompany;
  orgsSearchTagFieldTypeEnum = OrgsSearchTagFieldTypeEnum;

  // #region AUTOCOMPLETE VIRTUAL SCROLL VARIABLES
  term$ = new BehaviorSubject<string>('');
  virtualScrollItems: (OrgSearchCustomDropdownItemInterface | LeadCompany)[] =
    [];
  totalScrollItem = 0;
  currentPageIndex = 1;
  searchText = '';
  isLoadMore = true;
  isSearchInputFocused = false;
  isFirstTimeSearchOnClick = false;
  isDropdownOpen = false;
  // #endregion

  styleOnResultItem: OrgSearchCustomDropdownItemInterface[] = [
    {
      tagFieldType: OrgsSearchTagFieldTypeEnum.FillOrg,
      style: 'button',
      name: 'buttonToFillOrg',
    },
    {
      tagFieldType: OrgsSearchTagFieldTypeEnum.Header,
      style: 'header',
      name: 'suggestion',
    },
  ];

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === Key.ESC || event.key === Key.ENTER) {
      this.closeDropdown();
      this.searchInputEl.nativeElement.blur();
    }
  }

  constructor(
    private organizationHttpService: OrganizationHttpService,
    private challengeHttpService: ChallengeHttpService,
    private peopleHttpService: PeopleHttpService
  ) {}

  ngOnInit(): void {
    // register autocomlete
    this.term$
      .pipe((term) => {
        return this.autocomplete(term);
      }, untilDestroyed(this))
      .subscribe((items) => {
        this.virtualScrollItems = this.initVirtualScrollItems();
        this.virtualScrollItems = this.virtualScrollItems.concat(items);
      });
  }

  ngAfterViewInit(): void {
    if (this.autoOpenDropdown) {
      setTimeout(() => {
        this.isFirstTimeSearchOnClick = true;
        this.searchInputEl?.nativeElement.focus();
        this.searchInputEl?.nativeElement.click();
      }, 0);
    }
  }

  getOrg(): Observable<LeadCompany[]> {
    this.isSearching = true;
    let $apiRequest: Observable<unknown>;
    const params: AutocompleteRequestInterface = {
      pageIndex: this.currentPageIndex,
      pageSize: 20,
      keyword: this.searchText,
      ignoreIds: this.excludedOrganisationIds,
      OrgId: this.ignoreCompany?.id ?? ''
    };

    switch (this.organizationType) {
      case OrganizationType.Organization:
        params.showAsChallenge = false;
        break;
      case OrganizationType.Challenge:
        params.showAsChallenge = true;
        break;
      case OrganizationType.All:
        break;
    }

    switch (this.searchMode) {
      case OrgsSearchMode.ForLeadIncludeChallenge:
        params.includeChallenges = true;
        $apiRequest = this.organizationHttpService.paginateX(
          params,
          OrgsSearchMode.ForLead
        );
        break;
      case OrgsSearchMode.ForLead:
      case OrgsSearchMode.ForLeadPerson:
        $apiRequest = this.organizationHttpService.paginateX(
          params,
          this.searchMode
        );
        break;
      case OrgsSearchMode.ForSubmitting:
      case OrgsSearchMode.ForSubmittingAll:
        $apiRequest = this.challengeHttpService.paginateX(
          params,
          this.searchMode
        );
        break;
      case OrgsSearchMode.PresonAfferableOrgs:
        $apiRequest = this.peopleHttpService.getAfferableOrgs(
          this.userId,
          params
        );
        break;
      case OrgsSearchMode.ForFilterVenture:
        if (this.entityId && this.entityName) {
          params.entityId = this.entityId;
          params.entityName = this.entityName;
          $apiRequest = this.organizationHttpService.paginateX(
            params,
            this.searchMode
          );
        }
    }
    if (!$apiRequest) {
      return of([]);
    }

    return $apiRequest.pipe(
      finalize(() => (this.isSearching = false)),
      map((res: ApiGetResponse<OrganizationInterface | ChallengeInterface>) => {
        const items = res.items || [];
        this.totalScrollItem = res.rowCount;

        const result: LeadCompany[] = items
          .filter((item: OrganizationInterface | ChallengeInterface) => {
            return item.id !== this.ignoreCompany?.id;
          })
          .map((item) => ({
            ...item,
            isMyOrganisation: item.initiators?.some(
              (initiator: UserInterface) => initiator.id === this.userId
            ),
          }));
        return result;
      })
    );
  }

  // #region AUTOCOMPLETE VIRTUAL SCROLL HANDLE
  autocomplete(text$: Observable<string>): Observable<LeadCompany[]> {
    return text$.pipe(
      filter(() => this.isSearchInputFocused),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(() => {
        this.isLoadMore = true;
        this.currentPageIndex = 1;
        return this.getOrg();
      })
    );
  }

  loadMore(event: IPageInfo): void {
    if (
      event.endIndex !== this.virtualScrollItems.length - 1 ||
      this.isSearching ||
      !this.isLoadMore
    ) {
      return;
    }

    if (
      (this.enableCustomOnDropdown &&
        this.totalScrollItem ===
          this.virtualScrollItems.length - this.styleOnResultItem.length) ||
      (!this.enableCustomOnDropdown &&
        this.totalScrollItem === this.virtualScrollItems.length)
    ) {
      this.isLoadMore = false;
      return;
    }

    this.currentPageIndex++;
    this.getOrg()
      .pipe(untilDestroyed(this))
      .subscribe((items: LeadCompany[]) => {
        if (items?.length === 0) {
          this.isLoadMore = false;
          return;
        }
        this.virtualScrollItems = this.virtualScrollItems.concat(items);
      });
  }

  initVirtualScrollItems(): (
    | OrgSearchCustomDropdownItemInterface
    | LeadCompany
  )[] {
    return this.enableCustomOnDropdown ? this.styleOnResultItem : [];
  }

  onChangeSearchText(): void {
    this.term$.next(this.searchText);
  }

  resetSearchText(): void {
    this.searchText = '';
    this.afterResetSearchText.emit();
    this.closeDropdown();
  }

  onSelectOrg(item: OrgSearchCustomDropdownItemInterface | LeadCompany): void {
    this.selectedOrg = item;
    // Default hide ventures
    if (this.selectedOrg.showVentures === undefined) {
      this.selectedOrg.showVentures = false;
    }
    this.selectOrganisation.emit(this.selectedOrg);

    if (this.closeDropdownOnSelect) {
      this.closeDropdown();
    }

    if (this.updateListOnSelect) {
      this.virtualScrollItems = this.virtualScrollItems.filter(
        (org) => org.id !== item.id
      );
    }
  }

  onClickSearchInput(): void {
    if (
      this.isSearchInputFocused &&
      this.isFirstTimeSearchOnClick &&
      !this.isSearching
    ) {
      this.isLoadMore = true;
      this.currentPageIndex = 1;

      const obs = this.getOrg();
      if (obs) {
        obs.pipe(untilDestroyed(this)).subscribe((items: LeadCompany[]) => {
          this.virtualScrollItems = this.initVirtualScrollItems();
          this.virtualScrollItems = this.virtualScrollItems.concat(items);
          this.isFirstTimeSearchOnClick = false;
        });
      }
    }
  }

  onFocusSearchInput(): void {
    this.openDropdown();
    this.isSearchInputFocused = true;
    this.isFirstTimeSearchOnClick = true;
  }

  onBlurSearchInput(): void {
    this.isSearchInputFocused = false;
    this.isFirstTimeSearchOnClick = false;
  }
  // virtual-scroller not support dynamic height
  getDynamicHeight(): string {
    const loadingHeight = 32;
    const numberOfScrollItems = this.virtualScrollItems.length;
    const itemHeight = 53;
    // update folow styleOnResultItem
    const customDropdownHeight = 122;
    const maxNumberOfScrollItemsViewPort = this.enableCustomOnDropdown
      ? 5 + this.styleOnResultItem.length
      : 5;
    let heightResult = this.enableCustomOnDropdown
      ? customDropdownHeight - this.styleOnResultItem.length * itemHeight
      : 0;

    if (numberOfScrollItems > maxNumberOfScrollItemsViewPort) {
      heightResult += itemHeight * maxNumberOfScrollItemsViewPort;
    } else {
      heightResult += itemHeight * numberOfScrollItems;
    }
    if (this.isSearching) {
      heightResult += loadingHeight;
    }
    return `${heightResult}px`;
  }

  onClickOutSide(): void {
    this.onBlurSearchInput();
    this.closeDropdown();
  }

  private openDropdown(): void {
    this.isDropdownOpen = true;
  }

  private closeDropdown(): void {
    this.isDropdownOpen = false;
    this.virtualScrollItems = [];
  }
  // #endregion

  ngOnDestroy(): void {
    /**/
  }
}
