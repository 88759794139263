<div class="modal-body modal-body-confirmation">
  <div
    class="d-flex justify-content-center px-2 pt-2 font-weight-bold font-size-xl"
  >
    Oops !
  </div>
  <div class="text-center mt-2">
    <p class="mb-0" translate>{{ message }}</p>
  </div>
</div>
<div class="modal-footer">
  <div class="d-flex flex-column w-100">
    <div class="d-flex flex-row justify-content-center">
      <button
        type="button"
        class="btn btn-primary ml-4"
        (click)="confirm()"
      >
        {{ 'Ok' | translate | uppercase }}
      </button>
    </div>
  </div>
</div>
