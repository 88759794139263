<app-people-modal
  *ngIf="showPeopleModal"
  modalTitle="Followers List"
  [entityId]="selectedEntityId"
  [httpService]="httpService"
  (dismissModal)="showPeopleModal = false"
></app-people-modal>

<app-ventures-modal
  *ngIf="showVenturesModal"
  [entityId]="selectedEntityId"
  [modalTitle]="'Ventures' | translate"
  [httpService]="httpService"
  (dismissModal)="showVenturesModal = false"
  [globalOrgId]="globalOrgId"
></app-ventures-modal>

<ul
  *ngIf="displayItems?.length > 0; else itemEmtpyMessage"
  class="widget__container people-cards"
>
  <li
    *ngFor="let person of displayItems; index as listIndex; trackBy: trackItem"
    class="people__item"
  >
    <div class="people__wrapper">
      <span
        *ngIf="person | call : shouldShowRemoveBtn"
        (click)="peopleRemoved.emit(person)"
        class="people-cards__remove"
      >
        <em class="far fa-times fa-sm"></em>
      </span>

      <div
        class="people__body px-3 widget__card--background"
        [class.pointer-none]="person.isUnregisteredUser"
      >
        <app-people-info-small
          [person]="person"
          [customUserRoles]="customUserRoles"
          [editable]="editable"
          [fromPendingPeople]="fromPendingPeople"
          [hideProfileImage]="hideProfileImage"
          (peopleCardClicked)="onPeopleCardClicked()"
        >
          <ng-container *ngIf="customUserRoles.length === 0">
            <ng-container
              *ngTemplateOutlet="extraActionsTemplate"
            ></ng-container>
          </ng-container>
        </app-people-info-small>

        <ng-container *ngIf="editable && customUserRoles.length > 0">
          <div class="d-flex flex-column">
            <div class="d-flex align-items-center">
              <label class="mr-2 word-wrap--normal">
                {{ 'UI.Profile.Role' | translate }}
              </label>
              <app-boostrap-dropdown
                class="dropdown--full-width truncate-selection"
                [items]="customUserRoles"
                [(currentIndex)]="person.roleIndex"
                [defaultLabel]="'UI.Common.SelectRole' | translate"
                (selectItem)="selectRole($event, person)"
              >
              </app-boostrap-dropdown>
              <ng-container *ngIf="person.pendingMessage && fromPendingPeople">
                <div
                  class="people__pending-message"
                  (click)="openModalPendingMessage(person.pendingMessage)"
                >
                  <i class="fas fa-comment"></i>
                </div>
              </ng-container>
            </div>

            <div class="d-flex justify-content-end py-2">
              <ng-container
                *ngTemplateOutlet="extraActionsTemplate"
              ></ng-container>
            </div>
          </div>
        </ng-container>

        <ng-template #extraActionsTemplate>
          <ng-container
            *ngIf="
              enableExtraAction &&
              ((cardType && sessionService.currentUser?.id === person?.id) ||
                (!cardType && sessionService.currentUser?.id !== person?.id))
            "
          >
            <button
              *ngFor="let action of extraActions"
              type="button"
              class="btn btn-outline-primary jip-btn-small mt-1"
              [ngClass]="action.class"
              appRequireLogin
              appDebounceClick
              [ngbTooltip]="action.tooltip | translate"
              (debounceClick)="doExtraAction(action, person)"
            >
              <i *ngIf="action.faIcon" [class]="action.faIcon"></i>
              {{ action.label | translate }}
            </button>
          </ng-container>
        </ng-template>
      </div>

      <div *ngIf="showInteractionBar" class="people__footer px-3 mt-1">
        <ul class="list-inline people__actions justify-content-between row">
          <!-- Followers -->
          <li
            [class.disabled-action]="!enableInteractionBar"
            class="list-inline-item people__actions-item"
          >
            <ng-container *ngIf="isLoadingFollowingsCount; else followersBtn">
              <div class="text-center">
                <i class="fas fa-spinner fa-spin"></i>
              </div>
            </ng-container>
            <ng-template #followersBtn>
              <a
                appDebounceClick
                (debounceClick)="showEntityData(person); showPeopleModal = true"
                class="d-flex text-gray-600"
              >
                <app-internal-icon
                  [className]="'filter-gray'"
                  [name]="'user-follow'"
                ></app-internal-icon>
                <span class="people__actions-label font-weight-500">
                  <span
                    [innerHTML]="person?.followerCount | parentheses"
                  ></span>
                </span>
              </a>
            </ng-template>
          </li>

          <li
            [class.disabled-action]="!enableInteractionBar"
            class="list-inline-item people__actions-item"
          >
            <ng-container *ngIf="isLoadingVenturesCount; else venturesBtn">
              <div class="text-center">
                <i class="fas fa-spinner fa-spin"></i>
              </div>
            </ng-container>
            <ng-template #venturesBtn>
              <a
                class="d-flex text-gray-600"
                appDebounceClick
                (debounceClick)="
                  showEntityData(person); showVenturesModal = true
                "
              >
                <app-internal-icon
                  [height]="20"
                  [className]="'filter-gray'"
                  [name]="'suitcase'"
                ></app-internal-icon>
                <span class="people__actions-label font-weight-500">
                  <span
                    [innerHTML]="person?.venturesCount | parentheses"
                  ></span>
                </span>
              </a>
            </ng-template>
          </li>

          <li
            *ngIf="
              sessionService.currentUser?.id !== person?.id &&
              enableInteractionBar
            "
            class="list-inline-item people__actions-item"
            appRequireLogin
            appDebounceClick
            (debounceClick)="
              openSendMessage(
                person?.id,
                person?.firstName + ' ' + person?.lastName
              )
            "
          >
            <app-internal-icon
              [className]="'filter-gray'"
              [name]="'comments'"
            ></app-internal-icon>
          </li>
        </ul>
      </div>
    </div>
  </li>
</ul>
<ng-template #itemEmtpyMessage>
  <div class="people-cards-emtpy m-0" translate>
    {{ emptyMessage }}
  </div>
</ng-template>
