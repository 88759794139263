<div class="px-4 pt-3">
  <h4 class="m-0 font-weight-bold something-wrong-modal-title" translate>
    {{ title | translate }}
  </h4>
</div>
<div class="modal-body white-space--pre-wrap">
  <div *ngIf="error" class="d-flex align-items-center justify-content-center">
    <img
      width="260"
      [src]="error?.error?.detail | base64Image"
      appDefaultPlaceholder
      alt="error-qr-code"
    />
  </div>
  <p class="m-0" [innerHtml]="message | translate"></p>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary mx-auto w-100"
    (click)="activeModal.close(false)"
    translate
  >
    Try Again
  </button>
</div>
