import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment/moment';
import Base = moment.unitOfTime.Base;

export class TimeUtils {
  public static localToUTC(localTime: Date, offsetSeconds = 0): number {
    if (localTime instanceof Date) {
      return (
        Date.UTC(
          localTime.getUTCFullYear(),
          localTime.getUTCMonth(),
          localTime.getUTCDate(),
          localTime.getUTCHours(),
          localTime.getUTCMinutes(),
          localTime.getUTCSeconds(),
          localTime.getUTCMinutes()
        ) +
        offsetSeconds * 1000
      );
    }

    return null;
  }

  public static getDateWithOffset(
    date: Date,
    yearOffset = 0,
    monthOffset = 0,
    dayOffset = 0
  ): Date | null {
    if (date instanceof Date) {
      return new Date(
        date.getFullYear() + yearOffset,
        date.getMonth() + monthOffset,
        date.getDate() + dayOffset
      );
    }

    return null;
  }

  public static getBoostrapDate(date: Date | string): NgbDateStruct {
    const dateObj = new Date(date);

    return {
      year: dateObj.getFullYear(),
      month: dateObj.getMonth() + 1,
      day: dateObj.getDate(),
    };
  }

  public static getBoostrapTime(date: Date | string): NgbTimeStruct {
    const dateObj = new Date(date);

    return {
      hour: dateObj.getHours(),
      minute: dateObj.getMinutes(),
      second: dateObj.getSeconds(),
    };
  }

  public static addHoursToDate(date: Date, hours: number): Date {
    return moment(date).add(hours, 'hours').toDate();
  }

  public static isValidDate(d: any): boolean {
    return d instanceof Date && d.getTime && !isNaN(d.getTime());
  }

  public static addDays(date: Date, days: number): Date {
    const newDate = new Date(date);
    if (days !== null && !isNaN(days)) {
      newDate.setDate(newDate.getDate() + days);
    }
    return newDate;
  }

  public static getMidnightDate(date?: Date): Date {
    const checkDate = date ? date : new Date();
    return new Date(checkDate.setHours(0, 0, 0, 0));
  }

  public static getEndDate(date?: Date): Date {
    const checkDate = date ? date : new Date();
    return new Date(checkDate.setHours(23, 59, 59, 0));
  }

  // Get time zone of target date to handle Daylight Saving Time
  public static getTargetDateTimezone(date: string): number {
    return date ? new Date(date).getTimezoneOffset() / -60 : null;
  }

  public static getMaxDate(): Date {
    return new Date(9999, 11, 31);
  }

  public static getMinDate(): Date {
    return new Date(null);
  }

  public static getLastDateRange(
    amount: number,
    unit: Base = 'days'
  ): [Date, Date] {
    const currentDate = moment();
    const fromDate = currentDate.clone().add(-amount, unit);

    return [fromDate.toDate(), currentDate.toDate()];
  }

  public static monthsBetweenDates(
    startDate: Date,
    endDate: Date,
    format: string = 'MM YYYY'
  ): string[] {
    const months = [];
    const currentMonth = moment(startDate).clone();

    while (currentMonth.isSameOrBefore(moment(endDate))) {
      months.push(currentMonth.format(format));
      currentMonth.add(1, 'month');
    }

    return months;
  }

  public static convertUtcToLocalTimeInSeconds(
    utcTime: string | Date,
    offsetSeconds = 0
  ): number {
    const localTime = moment.utc(utcTime).local();

    return (localTime.valueOf() + offsetSeconds * 1000) / 1000;
  }
}
