import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PeopleInterface } from '@shared/interfaces/people.interface';
import { ApplicationService } from '@src/app/core/services/application.service';
import { SessionService } from '@src/app/core/session.service';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { CustomAtrributeValue } from '@src/app/shared/interfaces/attribute-description.interface';
import { environment } from '@src/environments/environment';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-people-info-small',
  templateUrl: './people-info-small.component.html',
})
export class PeopleInfoSmallComponent implements OnChanges, OnDestroy {
  peopleDetailUrl = '';

  @Input() person: PeopleInterface;
  @Input() editable: boolean;
  @Input() customUserRoles: CustomAtrributeValue[] = [];
  @Input() fromPendingPeople = false;
  @Input() hideProfileImage = false;

  @Output() peopleCardClicked = new EventEmitter<void>();

  isCurrentUser: boolean;

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly sessionService: SessionService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.person && changes.person.currentValue) {
      this.isCurrentUser =
        changes.person.currentValue.id === this.sessionService.currentUser?.id;
      this.handlePeopleDetailUrl();
    }
  }

  getCompanyName(person: PeopleInterface): string {
    if (person) {
      if (typeof person.company === 'string') {
        return person.company;
      } else if (typeof person.company?.name === 'string') {
        return person.company.name;
      }
    }
    return '';
  }

  onPeopleCardClicked(person: PeopleInterface): void {
    this.peopleCardClicked.emit();
  }

  private handlePeopleDetailUrl(): void {
    const personId = this.person?.id;
    const url = `${environment.jipUrl.people}/${personId}`;
    this.peopleDetailUrl = url;

    if (
      this.fromPendingPeople &&
      this.person?.isPrivate &&
      !this.isCurrentUser
    ) {
      this.applicationService
        .encryptInfo(`${EntityName.Person}-${this.person?.id}`)
        .pipe(
          untilDestroyed(this),
          catchError(() => of(''))
        )
        .subscribe((encryptedCode: string) => {
          this.peopleDetailUrl = encryptedCode
            ? `${url}?${UrlParam.Code}=${encryptedCode}`
            : url;
        });
    }
  }

  ngOnDestroy(): void {
    /**/
  }
}
