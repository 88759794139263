<app-unsaved-form-check
  [unsavedFormCheckService]="unsavedFormCheckService"
></app-unsaved-form-check>

<ng-container
  *ngIf="sessionService.tenant.enableCommunication; else serviceNotAvailable"
>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'UI.Ventures.GetInTouch' | translate }}</h4>
    <button
      type="button"
      class="btn-close close"
      aria-label="Close button"
      aria-describedby="modal-title"
      (click)="activeModal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container
      *ngIf="isLoadingAttributeDescriptions$ | async; else sendMessageTemplate"
    >
      <div class="d-flex justify-content-center align-items-center">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
    </ng-container>
    <ng-template #sendMessageTemplate>
      <form [formGroup]="form">
        <div class="message-send-box">
          <div class="sender-profile d-flex justify-content-center">
            <div class="message-to-profile">
              <img [src]="profileImage" appDefaultAvatar appDynamicImgUrl />
            </div>
          </div>
          <div class="message-content-quill">
            <app-form-field-custom
              [formInput]="form"
              [type]="AttributeType.RTE"
              [attributeDescription]="contentAttributeDescription"
              [shouldValidateOnTouch]="true"
              [options]="{
                placeholder: 'UI.SendMessage.Placeholder' | translate
              }"
            >
            </app-form-field-custom>
          </div>
        </div>

        <app-mail-forwarding-checkbox
          [alignLeft]="!!venture"
          formControlName="isSendMail"
        ></app-mail-forwarding-checkbox>

        <div *ngIf="venture" class="get-in-touch-venture-card">
          <app-venture-card [data]="venture"></app-venture-card>
        </div>
      </form>
    </ng-template>
  </div>
  <div class="modal-footer message-command">
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="close()"
    >
      {{ 'Cancel' | translate | uppercase }}
    </button>
    <button
      appRequireLogin
      [disabled]="form.invalid || isSubmitting"
      type="button"
      ngbAutofocus
      class="btn btn-primary"
      (click)="send()"
    >
      <ng-container *ngIf="isSubmitting">
        <i class="fas fa-spinner fa-spin btn-icon-right"></i>
      </ng-container>
      <span>{{'Send' | translate | uppercase }}</span>
    </button>
  </div>
</ng-container>

<ng-template #serviceNotAvailable>
  <app-service-not-available-dialog
    [message]="'UI.DirectMessageNotAvailable'"
  >
  </app-service-not-available-dialog>
</ng-template>
