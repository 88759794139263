import {
  Component,
  ElementRef,
  Injector,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { SendMessageAdapterService } from '@core/http/send-message-adapter.service';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { StringReplacementPipe } from '@shared/pipes/string-replacement.pipe';
import { MessageUtils } from '@shared/utils/message-utils';
import { SessionService } from '@src/app/core/session.service';
import { EntityViewModalComponent } from '@src/app/shared/components/entity-view-modal/entity-view-modal.component';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { NewsMessageInterface } from '@src/app/shared/interfaces/news-message.interface';
import { PeopleInterface } from '@src/app/shared/interfaces/people.interface';
import { Entity } from '@src/app/shared/models/entity.model';
import {
  LinkWrapperPipe,
  SpanWrapperPipe,
} from '@src/app/shared/pipes/link-wrapper.pipe';
import { YouTransformPipe } from '@src/app/shared/pipes/you-transform.pipe';
import { StringUtils } from '@src/app/shared/utils/string-utils';

@Component({
  selector: 'app-group-message-action',
  templateUrl: './group-message-action.component.html',
  providers: [SendMessageAdapterService],
})
export class GroupMessageActionComponent implements OnChanges {
  @Input() message: NewsMessageInterface;
  @Input() modalTitle = '';
  @Input() currentPersonId?: number;

  peopleURL = environment.jipUrl.people;
  maxNumOfMemberToDisplay = 2;

  showPeopleModal = false;
  selectedEntityId: number;
  messageHtml = '';
  botFlagHtml = `
    <div class="d-inline-block">
      <label class="bot-flag">
        <i class="fas fa-robot mr-1"></i>
        <span class="ml-1" translate>Bot</span>
      </label>
    </div>
  `;

  //#region  SERVICES
  private elementRef: ElementRef;
  private spanWrapperPipe: SpanWrapperPipe;
  private youTransformPipe: YouTransformPipe;
  private linkWrapperPipe: LinkWrapperPipe;
  private stringReplacementPipe: StringReplacementPipe;
  private modalService: NgbModal;

  //#endregion End Services

  constructor(
    public httpService: SendMessageAdapterService,
    private translateService: TranslateService,
    private sessionService: SessionService,
    protected injector: Injector
  ) {
    this.elementRef = injector.get<ElementRef>(ElementRef);
    this.spanWrapperPipe = injector.get<SpanWrapperPipe>(SpanWrapperPipe);
    this.youTransformPipe = injector.get<YouTransformPipe>(YouTransformPipe);
    this.linkWrapperPipe = injector.get<LinkWrapperPipe>(LinkWrapperPipe);
    this.stringReplacementPipe = injector.get<StringReplacementPipe>(
      StringReplacementPipe
    );
    this.modalService = injector.get<NgbModal>(NgbModal);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.message) {
      const messageTitle = this.getMessageTitle();
      const titleReplacementOptions = this.getFormatTitle();
      this.messageHtml = this.updateMessageHtml(
        messageTitle,
        titleReplacementOptions
      );
    }

    setTimeout(() => {
      this.registerEventListener();
    }, 0);

    if (changes.message && this.isGroupMessage()) {
      this.httpService.setRecipientList(
        MessageUtils.getRecipients(this.message, this.currentPersonId)
      );
    }
  }

  showRecipients(): void {
    this.showPeopleModal = true;
    this.selectedEntityId = null;
    setTimeout(() => {
      this.selectedEntityId = this.message?.id;
    });
  }

  getMessageTitle(): string {
    const sender = MessageUtils.getLastReplier(this.message);
    const hasOneReceiver = this.message.group?.toItems?.length === 1;
    const isSender = this.currentPersonId === sender.id;
    const onlyYouIsReceiver = !isSender && hasOneReceiver;

    if (this.isConversationReplied() && this.isGroupMessage()) {
      if (onlyYouIsReceiver) {
        return 'UI.New-Message.Replied-to-you';
      } else if (isSender) {
        return 'UI.New-Message.Replied-to';
      } else {
        return 'UI.New-Message.Replied-to-you-and-other';
      }
    }

    if (this.message?.group?.title) {
      return this.message?.group?.title;
    }

    if (onlyYouIsReceiver) {
      return 'UI.New-Message.Send-message-to-you';
    } else if (isSender) {
      return 'UI.New-Message.Send-message-to';
    } else {
      return 'UI.New-Message.Send-message-to-you-and-other';
    }
  }

  getFormatTitle(): object {
    const keys = [
      'UI.New-Message.Everybody',
      'UI.New-Message.Members',

      'UI.New-Message.Participants',
      'UI.New-Message.ToParticipants',

      'UI.New-Message.Following',
      'UI.New-Message.Followers',

      'UI.New-Message.Venture',
      'UI.New-Message.Ventures',

      'UI.New-Message.Organization',
      'UI.New-Message.Organizations',

      'UI.New-Message.Event',
      'UI.New-Message.Events',

      'UI.New-Message.Organization/Call/Challenge',
    ];

    const haveVerb =
      MessageUtils.getLastReplier(this.message)?.id === this.currentPersonId
        ? this.translateService.instant('have')
        : this.translateService.instant('has');

    const result = {
      '{you}': this.translateService.instant('UI.Common.you'),
      '{sender}': this.getSender(),
      '{have}': haveVerb,
      '{Dir}': this.translateService.instant('UI.New-Message.ToYou'),
    };

    keys.forEach((key) => {
      const text: string = this.translateService.instant(key);
      let resultString = '';
      switch (key) {
        case 'UI.New-Message.Organization/Call/Challenge':
          const toItem: any = this.message?.group?.toItems[0];
          let entityLink = '';
          const orgName = toItem?.orgName;

          entityLink = this.getEntityLink(
            orgName,
            toItem?.showAsChallenge
              ? EntityName.Challenge
              : EntityName.Organization
          );

          resultString = entityLink;
          break;

        case 'UI.New-Message.Organizations':
          resultString = this.getListEntityLink(text, EntityName.Organization);
          break;

        case 'UI.New-Message.Venture':
          resultString = this.getEntityLink(
            this.translateService.instant('Venture'),
            EntityName.Venture
          );
          break;

        case 'UI.New-Message.Ventures':
          resultString = this.getListEntityLink(
            this.translateService.instant('Ventures'),
            EntityName.Venture
          );
          break;

        case 'UI.New-Message.Event':
          resultString = this.getEntityLink(text, EntityName.Event);
          break;

        case 'UI.New-Message.Events':
          resultString = this.getListEntityLink(text, EntityName.Event);
          break;

        default:
          resultString = this.getRecipients(text);
          break;
      }
      result[`{${text}}`] = resultString;
    });

    return result;
  }

  updateMessageHtml(
    messageTitle: string,
    titleReplacementOptions: any
  ): string {
    return this.stringReplacementPipe.transform(
      this.translateService.instant(messageTitle),
      titleReplacementOptions
    );
  }

  getSender(): string {
    if (!this.message) return;

    if (this.message.from) {
      const sender = MessageUtils.getLastReplier(this.message);
      const peopleUrl = this.sessionService.appendLanguagePath(
        this.peopleURL + '/' + sender.id
      );
      return (
        this.linkWrapperPipe.transform(
          peopleUrl,
          this.youTransformPipe.transform(sender, this.currentPersonId)
        ) + this.getBotFlag(sender)
      );
    } else {
      const deactivateUser = this.translateService.instant(
        'UI.UnsubscribedUser'
      );
      return this.spanWrapperPipe.transform(deactivateUser, 'deactive-label');
    }
  }

  getRecipients(key: string): string {
    let members: string;
    const group = this.message?.group;

    if (group?.name || group?.entityName !== EntityName.Person) {
      members = key;
    } else {
      const sender = MessageUtils.getLastReplier(this.message);
      const tempArr = [];
      const toItems = group?.toItems;

      if (toItems?.length === 1) {
        const recipient = MessageUtils.getRecipients(this.message, -1)[0];

        const peopleUrl = this.sessionService.appendLanguagePath(
          this.peopleURL + '/' + recipient.id
        );

        return (
          (sender.id === this.currentPersonId
            ? this.linkWrapperPipe.transform(
                peopleUrl,
                this.youTransformPipe.transform(recipient, -1),
                'appUnsavedFormCheck',
                'font-weight-normal text-decoration-underline'
              )
            : this.translateService.instant('UI.Common.you')) +
          this.getBotFlag(recipient)
        );
      } else if (toItems?.length > 1) {
        const recipients = MessageUtils.getRecipients(
          this.message,
          this.currentPersonId
        );

        for (let i = 0; i < recipients.length; i++) {
          if (tempArr.length < this.maxNumOfMemberToDisplay) {
            const item = recipients[i];
            tempArr.push(
              `${item?.firstName} ${item?.lastName}` +
                `${item.isBot ? ' ' : ''}` +
                this.getBotFlag(item).trim()
            );
          } else {
            tempArr.push('...');
            break;
          }
        }
        members = tempArr.join(', ');
      } else {
        members = this.translateService.instant('UI.UnsubscribedUser');
        return this.spanWrapperPipe.transform(members, 'deactive-label');
      }
    }

    return this.spanWrapperPipe.transform(
      members,
      'all-recipients btn-link several-entities'
    );
  }

  getEntityLink(translateText: string, entityName: string): string {
    const entityUrl = StringUtils.getEntityUrlByEntityName(entityName);
    if (!entityUrl) {
      return '';
    }
    const url = this.sessionService.appendLanguagePath(
      `${entityUrl}/${this.message?.group?.toItems[0]?.id}`
    );

    return this.linkWrapperPipe.transform(
      url,
      translateText,
      'appUnsavedFormCheck',
      'underline'
    );
  }

  getListEntityLink(translateText: string, entityName: EntityName): string {
    const entityClass = this.getEntityClass(entityName, this.message?.id);
    return this.spanWrapperPipe.transform(
      translateText,
      `${entityClass} btn-link several-entities`
    );
  }

  isConversationReplied(): boolean {
    return (
      this.message.replyCount &&
      this.message.replies?.some(
        ({ from: replier }) =>
          replier.id !== this.currentPersonId ||
          replier.id !== this.message.from.id
      ) &&
      !this.message.replies?.every(
        ({ from: replier }) => replier.id === this.message.from.id
      )
    );
  }

  isGroupMessage(): boolean {
    return (
      this.message.group.entityName === EntityName.Person &&
      !this.message.group?.name
    );
  }

  private registerEventListener(): void {
    const recipientsAnchors =
      this.elementRef.nativeElement.querySelectorAll('.all-recipients') || [];
    recipientsAnchors.forEach((anchor: HTMLAnchorElement) => {
      anchor.addEventListener('click', this.showRecipients.bind(this));
    });

    this.registerEventListenerForEntity(this.message);
  }

  private registerEventListenerForEntity(message: NewsMessageInterface): void {
    const supportedEntityName = [
      EntityName.Event,
      EntityName.Venture,
      EntityName.Organization,
    ];

    if (
      message &&
      message.id &&
      supportedEntityName.includes(message.group?.entityName)
    ) {
      const entityName = message.group?.entityName as EntityName;
      const targetElements: HTMLCollectionOf<Element> =
        document.getElementsByClassName(
          `${this.getEntityClass(entityName, message.id)}`
        );
      if (targetElements?.length > 0) {
        const anchor = targetElements[0];
        anchor.addEventListener('click', () => {
          this.openEntityModel(entityName, message.group.toItems as Entity[]);
        });
      }
    }
  }

  private getEntityClass(entityName: EntityName, messageId: number): string {
    return `all-${entityName}-entities-${messageId}`;
  }

  private openEntityModel(
    entityName: EntityName,
    entityItems: Entity[] = []
  ): void {
    const modalRef = this.modalService.open(EntityViewModalComponent, {
      centered: true,
      backdrop: 'static',
      scrollable: true,
      windowClass: 'ventures-modal',
      size: 'xl',
    });

    const entityIds = entityItems.map((item) => item.id);

    modalRef.componentInstance.entityName = entityName;
    modalRef.componentInstance.modalTitle =
      this.translateService.instant(entityName);
    modalRef.componentInstance.entityIds = entityIds;
  }

  getBotFlag(sender: PeopleInterface): string {
    return sender.isBot ? this.botFlagHtml : '';
  }
}
